import {
  UPDATE_HANDBOOKS,
  UPDATE_HANDBOOKS_SETTINGS_FIELD,
  GET_HANDBOOKS_SETTINGS_SUCCESS
} from '../actions/handbooks'

const initialState = {
  collection: [],
  settings: {
    kinds: [],
    handbook_kind: {},
    handbook_url: '',
    uploaded: {}
  }
}

export default function handbooks(state = initialState, action) {
  switch(action.type) {
  case UPDATE_HANDBOOKS:
    return { ...state, collection: action.collection }
  case GET_HANDBOOKS_SETTINGS_SUCCESS:
    return { ...state, settings: { ...state.settings, ...action.settings } }
  case UPDATE_HANDBOOKS_SETTINGS_FIELD:
    return {
      ...state,
      settings: {
        ...state.settings,
        [action.name]: action.value
      }
    }
  default:
    return state
  }
}
