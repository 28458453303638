import { createAction } from '../lib/utils'

export const GET_USER_ATTRIBUTES_REQUEST = 'GET_USER_ATTRIBUTES_REQUEST'
export const GET_USER_ATTRIBUTES_SUCCESS = 'GET_USER_ATTRIBUTES_SUCCESS'
export const ADD_USER_ATTRIBUTE_REQUEST = 'ADD_USER_ATTRIBUTE_REQUEST'
export const ADD_USER_ATTRIBUTE_SUCCESS = 'ADD_USER_ATTRIBUTE_SUCCESS'
export const DELETE_USER_ATTRIBUTE_REQUEST = 'DELETE_USER_ATTRIBUTE_REQUEST'
export const DELETE_USER_ATTRIBUTE_SUCCESS = 'DELETE_USER_ATTRIBUTE_SUCCESS'
export const UPDATE_USER_ATTRIBUTE_REQUEST = 'UPDATE_USER_ATTRIBUTE_REQUEST'
export const UPDATE_USER_ATTRIBUTE_SUCCESS = 'UPDATE_USER_ATTRIBUTE_SUCCESS'

export const getUserAttributesRequest = createAction(GET_USER_ATTRIBUTES_REQUEST)
export const getUserAttributesSuccess = createAction(GET_USER_ATTRIBUTES_SUCCESS, 'collection')
export const addUserAttributeRequest = createAction(ADD_USER_ATTRIBUTE_REQUEST, 'field', 'onSuccess')
export const addUserAttributeSuccess = createAction(ADD_USER_ATTRIBUTE_SUCCESS, 'item')
export const deleteUserAttributeRequest = createAction(DELETE_USER_ATTRIBUTE_REQUEST, 'field')
export const deleteUserAttributeSuccess = createAction(DELETE_USER_ATTRIBUTE_SUCCESS, 'item')
export const updateUserAttributeRequest = createAction(UPDATE_USER_ATTRIBUTE_REQUEST, 'field', 'onSuccess')
export const updateUserAttributeSuccess = createAction(UPDATE_USER_ATTRIBUTE_SUCCESS, 'item')
