import { lens, multi } from 'lorgnette'
import { removeByIndex } from '../lib/utils'
import {
  APPEND_DEMANDS_DATA,
  APPEND_DEMANDS_ITEM,
  PREPEND_DEMANDS_ITEM,
  REPLACE_DEMANDS_DATA,
  ACTIVATE_ITEM,
  UPDATE_DEMANDS_API_URL,
  UPDATE_DEMANDS_ITEM,
  DELETE_DEMANDS_ITEM,
  NEXT_USERS_PAGE,
  UPDATE_DEMANDS_SUBTOTAL
} from '../actions/demands'

const initialState = {
  from_date: null,
  collection: [],
  filters: {},
  export: {},
  next_page: 1,
  activeId: null,
  apiUrl: '',
  totals: '',
  schedule: {
    users: [],
    employees: {},
    users_page: 1
  },
}

const demandsLens = multi(
  lens.prop('filters'),
  lens.prop('next_page'),
  lens.prop('collection'),
  lens.prop('schedule'),
  lens.prop('export'),
  lens.prop('from_date')
)
const demandsCollectionLens = multi(
  lens.prop('collection')
)
const demandByIdLens = id => lens.prop('collection').find(demand => demand.id === id)
const usersPageLens = lens.prop('schedule').prop('users_page')

export default function demands(state = initialState, action) {
  switch(action.type) {
  case APPEND_DEMANDS_DATA:
    let { data } = action
    return demandsLens.update(
      state,
      () => data.filters,
      () => data.next_page,
      oldCollection => data.collection ? oldCollection.concat(data.collection) : oldCollection,
      () => data.schedule,
      () => data.export,
      () => data.from_date
    )
  case APPEND_DEMANDS_ITEM:
    return demandsCollectionLens.update(
      state,
      coll => [...coll, action.data],
      count => count + 1
    )
  case PREPEND_DEMANDS_ITEM:
    return demandsCollectionLens.update(
      state,
      coll => [action.data, ...coll],
      count => count + 1
    )
  case REPLACE_DEMANDS_DATA:
    return usersPageLens.update({ ...state, ...action.data }, () => state.schedule.users_page)
  case UPDATE_DEMANDS_API_URL:
    return { ...state, apiUrl: action.url, apiTotalsUrl: action.totalsUrl }
  case UPDATE_DEMANDS_ITEM:
    return demandByIdLens(action.id).update(state, item => ({ ...item, ...action.data }))
  case DELETE_DEMANDS_ITEM:
    const demand = action.data
    const demandIndex = state.collection.findIndex(a => a.id === demand.id)
    return demandsCollectionLens.update(
      state,
      coll => removeByIndex(coll, demandIndex),
      count => count - 1
    )
  case ACTIVATE_ITEM:
    return {
      ...state,
      activeId: action.id
    }
  case NEXT_USERS_PAGE:
    return usersPageLens.update(state, page => page + 1)
  case UPDATE_DEMANDS_SUBTOTAL:
    return lens.prop('totals').update(state, () => action.totals)
  default:
    return state
  }
}
