import React, { Component } from 'react'
import Loading from '../../shared/svg/Loading'

class SetupAccount extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Please wait, we are setting
            <br />
            up your account
          </h2>
        </div>

        <div className='b-center'>
          <Loading />
        </div>
      </div>
    )
  }
}

export default SetupAccount
