import { createAction } from '../lib/utils'

export const ADD_POINT_CATEGORY_REQUEST = 'ADD_POINT_CATEGORY_REQUEST'
export const ADD_POINT_CATEGORY_SUCCESS = 'ADD_POINT_CATEGORY_SUCCESS'
export const ADD_POINT_REQUEST = 'ADD_POINT_REQUEST'
export const ADD_POINT_SUCCESS = 'ADD_POINT_SUCCESS'
export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST'
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS'
export const DELETE_POINT_CATEGORY_REQUEST = 'DELETE_POINT_CATEGORY_REQUEST'
export const DELETE_POINT_CATEGORY_SUCCESS = 'DELETE_POINT_CATEGORY_SUCCESS'
export const DELETE_POINT_REQUEST = 'DELETE_POINT_REQUEST'
export const DELETE_POINT_SUCCESS = 'DELETE_POINT_SUCCESS'
export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST'
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS'
export const GET_ACTIVITIES_SETTINGS_REQUEST = 'GET_ACTIVITIES_SETTINGS_REQUEST'
export const GET_ACTIVITIES_SETTINGS_SUCCESS = 'GET_ACTIVITIES_SETTINGS_SUCCESS'
export const UPDATE_POINT_CATEGORY_REQUEST = 'UPDATE_POINT_CATEGORY_REQUEST'
export const UPDATE_POINT_CATEGORY_SUCCESS = 'UPDATE_POINT_CATEGORY_SUCCESS'
export const UPDATE_POINT_REQUEST = 'UPDATE_POINT_REQUEST'
export const UPDATE_POINT_SUCCESS = 'UPDATE_POINT_SUCCESS'
export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPLOAD_ACTIVITIES_REQUEST = 'UPLOAD_ACTIVITIES_REQUEST'
export const UPLOAD_POINTS_REQUEST = 'UPLOAD_POINTS_REQUEST'
export const UPLOAD_CLIENTS_REQUEST = 'UPLOAD_CLIENTS_REQUEST'
export const UPDATE_BULK_UPLOADED = 'UPDATE_BULK_UPLOADED'
export const CONFIRM_ACTIVITIES_REQUEST = 'CONFIRM_ACTIVITIES_REQUEST'
export const CONFIRM_POINTS_REQUEST = 'CONFIRM_POINTS_REQUEST'
export const CONFIRM_CLIENTS_REQUEST = 'CONFIRM_CLIENTS_REQUEST'

export const addPointCategoryRequest = createAction(ADD_POINT_CATEGORY_REQUEST, 'category')
export const addPointCategorySuccess = createAction(ADD_POINT_CATEGORY_SUCCESS, 'item')
export const addPointRequest = createAction(ADD_POINT_REQUEST, 'point')
export const addPointSuccess = createAction(ADD_POINT_SUCCESS, 'item')
export const addClientRequest = createAction(ADD_CLIENT_REQUEST, 'client', 'after')
export const addClientSuccess = createAction(ADD_CLIENT_SUCCESS, 'item')
export const deletePointCategoryRequest = createAction(DELETE_POINT_CATEGORY_REQUEST, 'category')
export const deletePointCategorySuccess = createAction(DELETE_POINT_CATEGORY_SUCCESS, 'item')
export const deletePointRequest = createAction(DELETE_POINT_REQUEST, 'point')
export const deletePointSuccess = createAction(DELETE_POINT_SUCCESS, 'item')
export const deleteClientRequest = createAction(DELETE_CLIENT_REQUEST, 'client')
export const deleteClientSuccess = createAction(DELETE_CLIENT_SUCCESS, 'item')
export const getActivitiesSettingsRequest = createAction(GET_ACTIVITIES_SETTINGS_REQUEST)
export const getActivitiesSettingsSuccess = createAction(GET_ACTIVITIES_SETTINGS_SUCCESS, 'settings')
export const updatePointCategoryRequest = createAction(UPDATE_POINT_CATEGORY_REQUEST, 'category')
export const updatePointCategorySuccess = createAction(UPDATE_POINT_CATEGORY_SUCCESS, 'item')
export const updatePointRequest = createAction(UPDATE_POINT_REQUEST, 'point')
export const updatePointSuccess = createAction(UPDATE_POINT_SUCCESS, 'item')
export const updateClientRequest = createAction(UPDATE_CLIENT_REQUEST, 'client', 'after')
export const updateClientSuccess = createAction(UPDATE_CLIENT_SUCCESS, 'item')
export const uploadActivitiesRequest = createAction(UPLOAD_ACTIVITIES_REQUEST, 'file')
export const uploadPointsRequest = createAction(UPLOAD_POINTS_REQUEST, 'file')
export const uploadClientsRequest = createAction(UPLOAD_CLIENTS_REQUEST, 'file')
export const updateBulkUploaded = createAction(UPDATE_BULK_UPLOADED, 'data')
export const confirmActivitiesRequest = createAction(CONFIRM_ACTIVITIES_REQUEST, 'data', 'next')
export const confirmPointsRequest = createAction(CONFIRM_POINTS_REQUEST, 'data', 'next')
export const confirmClientsRequest = createAction(CONFIRM_CLIENTS_REQUEST, 'data', 'next')
