import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-formal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateErrors, signInRequest } from '../../../actions/user'
import { updatePage } from '../../../actions/page'
import { otpSchema } from '../../../schemas/employee'
import { Columns } from '../../shared/Block'
import SubmitButton from '../../shared/SubmitButton'

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    updatePage,
    updateErrors,
    signInRequest
  }, dispatch)
)

class OtpForm extends Component {
  static propTypes = {
    updateErrors: PropTypes.func.isRequired,
    signInRequest: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  render() {
    const {
      user,
      updateErrors,
      signInRequest
    } = this.props

    return (
      <Form
        className='b-form'
        defaultValue={user}
        errors={user.errors}
        onError={updateErrors}
        onSubmit={signInRequest}
        schema={otpSchema}
      >
        <div className='b-form__input-group'>
          <Columns modifiers='b-columns_with-larger-margin'>
            <Form.Message errorClass='b-form__validation-error' for={['otp_attempt']}/>
            <Form.Field
              type='text'
              maxLength='6'
              className='b-form__input'
              errorClass='b-form__input_validation-error'
              id='otp_attempt'
              name='otp_attempt'
              placeholder='6-digit code'
              value={user.otp_attempt}
            />
          </Columns>
          <SubmitButton className='b-button b-button_primary b-button_full-width'>
            Verify
          </SubmitButton>
        </div>
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpForm)
