import { createAction } from '../lib/utils'

export const UPDATE_DEMANDS_REQUEST = 'UPDATE_DEMANDS_REQUEST'
export const UPDATE_DEMANDS_SUBTOTAL = 'UPDATE_DEMANDS_SUBTOTAL'
export const UPDATE_DEMANDS_SUBTOTAL_REQUEST = 'UPDATE_DEMANDS_SUBTOTAL_REQUEST'
export const APPEND_DEMANDS_DATA = 'APPEND_DEMANDS_DATA'
export const APPEND_DEMANDS_ITEM = 'APPEND_DEMANDS_ITEM'
export const PREPEND_DEMANDS_ITEM = 'PREPEND_DEMANDS_ITEM'
export const REPLACE_DEMANDS_DATA = 'REPLACE_DEMANDS_DATA'
export const ACTIVATE_ITEM = 'ACTIVATE_ITEM'
export const UPDATE_DEMANDS_API_URL = 'UPDATE_DEMANDS_API_URL'
export const UPDATE_DEMANDS_ITEM = 'UPDATE_DEMANDS_ITEM'
export const DELETE_DEMANDS_ITEM = 'DELETE_DEMANDS_ITEM'
export const NEXT_USERS_PAGE = 'NEXT_USERS_PAGE'
export const EXPORT_EMAIL_DEMANDS_REQUEST = 'EXPORT_EMAIL_DEMANDS_REQUEST'
export const EXPORT_DEMANDS_REQUEST = 'EXPORT_DEMANDS_REQUEST'

export const updateDemandsRequest = createAction(UPDATE_DEMANDS_REQUEST)
export const updateDemandsSubtotal = createAction(UPDATE_DEMANDS_SUBTOTAL, 'totals')
export const updateDemandsSubtotalRequest = createAction(UPDATE_DEMANDS_SUBTOTAL_REQUEST)
export const appendDemandsData = createAction(APPEND_DEMANDS_DATA, 'data')
export const appendDemandsItem = createAction(APPEND_DEMANDS_ITEM, 'data')
export const prependDemandsItem = createAction(PREPEND_DEMANDS_ITEM, 'data')
export const replaceDemandsData = createAction(REPLACE_DEMANDS_DATA, 'data')
export const activateItem = createAction(ACTIVATE_ITEM, 'id')
export const updateDemandsApiUrl = createAction(UPDATE_DEMANDS_API_URL, 'url', 'totalsUrl')
export const updateDemandsItem = createAction(UPDATE_DEMANDS_ITEM, 'id', 'data')
export const deleteDemandsItem = createAction(DELETE_DEMANDS_ITEM, 'data')
export const nextUsersPage = createAction(NEXT_USERS_PAGE)
export const exportEmailDemandsRequest = createAction(EXPORT_EMAIL_DEMANDS_REQUEST, 'url')
export const exportDemandsRequest = createAction(EXPORT_DEMANDS_REQUEST, 'url')
