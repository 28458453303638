import { createAction } from '../lib/utils'

export const GET_CUSTOM_FIELDS_REQUEST = 'GET_CUSTOM_FIELDS_REQUEST'
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS'
export const ADD_CUSTOM_FIELD_REQUEST = 'ADD_CUSTOM_FIELD_REQUEST'
export const ADD_CUSTOM_FIELD_SUCCESS = 'ADD_CUSTOM_FIELD_SUCCESS'
export const DELETE_CUSTOM_FIELD_REQUEST = 'DELETE_CUSTOM_FIELD_REQUEST'
export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS'
export const UPDATE_CUSTOM_FIELD_REQUEST = 'UPDATE_CUSTOM_FIELD_REQUEST'
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'UPDATE_CUSTOM_FIELD_SUCCESS'

export const getCustomFieldsRequest = createAction(GET_CUSTOM_FIELDS_REQUEST, 'demand_kind')
export const getCustomFieldsSuccess = createAction(GET_CUSTOM_FIELDS_SUCCESS, 'collection')
export const addCustomFieldRequest = createAction(ADD_CUSTOM_FIELD_REQUEST, 'field')
export const addCustomFieldSuccess = createAction(ADD_CUSTOM_FIELD_SUCCESS, 'item')
export const deleteCustomFieldRequest = createAction(DELETE_CUSTOM_FIELD_REQUEST, 'field')
export const deleteCustomFieldSuccess = createAction(DELETE_CUSTOM_FIELD_SUCCESS, 'item')
export const updateCustomFieldRequest = createAction(UPDATE_CUSTOM_FIELD_REQUEST, 'field')
export const updateCustomFieldSuccess = createAction(UPDATE_CUSTOM_FIELD_SUCCESS, 'item')
