import { lens } from 'lorgnette'
import { removeByIndex } from '../lib/utils'
import {
  APPEND_TEAMS_DATA,
  UPDATE_SINGLE_TEAM,
  DELETE_TEAM,
  REPLACE_TEAMS_DATA
} from '../actions/teams'

const initialState = {
  collection: []
}

const teamLens = team => lens.prop('collection').firstOf(t => t.id === team.id)
const firstTeamLens = lens.prop('collection').first()

export default function teams(state = initialState, action) {
  switch (action.type) {
  case APPEND_TEAMS_DATA:
    return {
      ...state,
      collection: action.data.collection
    }

  case UPDATE_SINGLE_TEAM:
    const teamItem = teamLens(action.data).get(state)

    if (teamItem.isJust()) {
      return teamLens(action.data).update(state, item => ({ ...item, ...action.data }))
    }

    return firstTeamLens.set(state, action.data)

  case DELETE_TEAM:
    const teamIndex = state.collection.findIndex(t => t.id === action.id)
    return {
      ...state,
      collection: removeByIndex(state.collection, teamIndex)
    }

  case REPLACE_TEAMS_DATA:
    return { ...state, ...action.data }

  default:
    return state
  }
}
