import { createAction } from '../lib/utils'

export const BREADCRUMBS_PUSH = 'BREADCRUMBS_PUSH'
export const BREADCRUMBS_POP = 'BREADCRUMBS_POP'
export const BREADCRUMBS_UPGRADE = 'BREADCRUMBS_UPGRADE'
export const BREADCRUMBS_DOWNGRADE = 'BREADCRUMBS_DOWNGRADE'
export const BREADCRUMBS_RESET = 'BREADCRUMBS_RESET'
export const BREADCRUMBS_WILL_UPDATE = 'BREADCRUMBS_WILL_UPDATE'

export const breadcrumbsPush = createAction(BREADCRUMBS_PUSH, 'breadcrumb')
export const breadcrumbsPop = createAction(BREADCRUMBS_POP)
export const breadcrumbsUpgrade = createAction(BREADCRUMBS_UPGRADE, 'link')
export const breadcrumbsDowngrade = createAction(BREADCRUMBS_DOWNGRADE)
export const breadcrumbsReset = createAction(BREADCRUMBS_RESET)
