import moment from 'moment'

export const daysToTimeOffDates = days => (
  days.map(day => ({ date: moment(day.date), duration: day.count }))
)

export const daysDuration = days => (
  days.reduce((acc, day) => acc + day.duration, 0)
)

export const isHolidayGapExceeded = (days, gap) => (
  days
    .map(day => moment(day.date))
    .sort((date1, date2) => date1.isAfter(date2) ? 1 : -1)
    .map((date, i, dates) => date.diff(dates[i && (i - 1)], 'days'))
    .some(g => g >= gap)
)

export const availableDays = balance => {
  const {
    balance_days, taken_non_charged_days,
    balancing_days: { approved, pending }
  } = balance

  return balance_days - pending - approved - taken_non_charged_days
}

export const isBalanceExceeded = balance => (
  availableDays(balance) < 0
)
