import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signInRequest } from '../../../actions/user'
import { updatePage, PAGES } from '../../../actions/page'
import StaticUserForm from '../../shared/StaticUserForm'
import UserForm from '../../shared/UserForm'
import SubmitButton from '../../shared/SubmitButton'
import { Column, Link } from '../../shared/Block'

const isChromeIOS = global.navigator && global.navigator.userAgent.match('CriOS')

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ updatePage, signInRequest }, dispatch)
)

class SignInForm extends Component {
  static propTypes = {
    signInRequest: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired
  }

  render() {
    const { updatePage, signInRequest } = this.props
    const Form = isChromeIOS ? StaticUserForm : UserForm

    return (
      <Form onSubmit={signInRequest} showRememberMe={true}>
        <Column modifiers='authentication__additional-links authentication__margin-top'>
          <div className='authentication__additional-link'>
            <Link
              modifiers='b-link_smaller'
              onClick={() => updatePage(PAGES.PASSWORD)}
            >
              Forgot password?
            </Link>
          </div>

          <div className='authentication__additional-link'>
            <Link
              modifiers='b-link_smaller'
              onClick={() => updatePage(PAGES.SITE_NAME)}
            >
              Create account
            </Link>
          </div>
        </Column>

        <Column modifiers='authentication__margin-top authentication__submit'>
          {!isChromeIOS &&
           <SubmitButton
             className='b-button b-button_primary'
           >Sign In</SubmitButton>}
        </Column>
      </Form>
    )
  }
}

export default connect(null, mapDispatchToProps)(SignInForm)
