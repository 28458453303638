/* eslint camelcase: 0 */

import { takeEvery } from 'redux-saga'
import { put, call, select } from 'redux-saga/effects'
import { getSearchString } from '../lib/utils'
import {
  ACTIVATE_FILTER,
  DEACTIVATE_FILTER,
  FILTERS_FROM_QUERY,
  resetFilter
} from '../actions/filters'
import { initialState } from '../reducers/filters'
import { filtersToParams, paramsToFilters } from '../lib/filtersUtils'
import { getFilters, updateFilterCollection } from './utils'

const IGNORING_FILTERS_ON_RESET = ['page', 'show']

export function *resetPageFilter({ name }) {
  if (!IGNORING_FILTERS_ON_RESET.includes(name)) {
    yield put(resetFilter('page'))
  }
}

export function *updateCurrentUrl({ router }) {
  const filters = yield select(getFilters)
  const filterParams = yield call(filtersToParams, filters, {
    ignore: ['page'],
    replaceEmpty: 'filtering'
  })
  yield call([router, router.replaceWith], `?${filterParams}`)
}

export function *applyFiltersFromQuery() {
  const params = yield call(getSearchString)

  if (params) {
    const filters = yield call(paramsToFilters, params, initialState, { replaceEmpty: 'filtering' })
    yield call(updateFilterCollection, filters)
  }
}

export function *watchFiltersSources(context) {
  yield [
    takeEvery(ACTIVATE_FILTER, resetPageFilter),
    takeEvery(ACTIVATE_FILTER, updateCurrentUrl, context),
    takeEvery(DEACTIVATE_FILTER, updateCurrentUrl, context),
    takeEvery(DEACTIVATE_FILTER, resetPageFilter),
    takeEvery(FILTERS_FROM_QUERY, applyFiltersFromQuery)
  ]
}
