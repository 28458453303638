import { takeEvery } from 'redux-saga'
import { call, fork, put, select } from 'redux-saga/effects'
import {
  GET_USER_ATTRIBUTES_REQUEST,
  UPDATE_USER_ATTRIBUTE_REQUEST,
  ADD_USER_ATTRIBUTE_REQUEST,
  DELETE_USER_ATTRIBUTE_REQUEST,
  getUserAttributesSuccess,
  addUserAttributeSuccess,
  updateUserAttributeSuccess,
  deleteUserAttributeSuccess
} from '../actions/userAttributesSettings'
import routes from '../api/routes'
import { get, makeUrl, post, del, put as apiPut } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import { customFieldMapping } from '../api/mappings'

export function* getFields() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.userAttributesSettings)
  const { collection } = yield call(get, url)
  yield put(getUserAttributesSuccess(collection))
}

export function* updateField({ field }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.userAttributeSettings, { id: field.id })
  const data = customFieldMapping(field, 'user')
  const { errors, item } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Field updated')
    yield put(updateUserAttributeSuccess(item))
  }
}

export function* addField({ field }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.userAttributesSettings)
  const data = customFieldMapping(field, 'user')
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Field added')
    yield put(addUserAttributeSuccess(item))
  }
}

export function* deleteField({ field }) {
  const deleteConfirmed = yield call(proceed)
  if (deleteConfirmed) {
    const host = yield select(getHost)
    const url = yield call(makeUrl, host, routes.userAttributeSettings, { id: field.id })
    yield call(del, url)
    yield put(deleteUserAttributeSuccess(field))
    yield fork(showFlash, 'success', 'Field deleted')
  }
}

export function* watchUserAttributesSettingsUpdates() {
  yield [
    takeEvery(GET_USER_ATTRIBUTES_REQUEST, getFields),
    takeEvery(ADD_USER_ATTRIBUTE_REQUEST, addField),
    takeEvery(DELETE_USER_ATTRIBUTE_REQUEST, deleteField),
    takeEvery(UPDATE_USER_ATTRIBUTE_REQUEST, updateField)
  ]
}
