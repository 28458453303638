import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AccountForm from './Form'

class SiteName extends Component {
  static propTypes = {
    promoCode: PropTypes.string
  }

  render() {
    const { promoCode } = this.props

    return (
      <div>
        <div className='b-title_center'>
          <h2 className='b-title__main-title'>
            Set up your account
          </h2>
          <div className='b-title__subtitle b-preline'>
            {`Choose the website address for your Turbine
account. For example, mycompany.turbinehq.com.`}
          </div>
        </div>

        <AccountForm promoCode={promoCode} />
      </div>
    )
  }
}

export default SiteName
