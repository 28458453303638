/* eslint camelcase: 0 */

import {
  DELETE_EMPLOYEE_ATTACHMENT_REQUEST,
  DELETE_EMPLOYEE_PHOTO_REQUEST,
  SWITCH_EMPLOYEE_PAGE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_EMAIL_SETTINGS,
  UPDATE_EMPLOYEE_FIELD,
  TWO_FACTOR_AUTH,
  LOCK_EMPLOYEE_SWITCHES,
  UPDATE_EMPLOYEE_ERRORS
} from '../actions/employee'
import { lens } from 'lorgnette'

export const initialState = {
  address: {
    address1: null,
    address2: null,
    city: null,
    state: null,
    postal_code: null,
    country_code: null
  },
  attachments: [],
  blackout_configs: [],
  config_id: null,
  connections: [],
  date_of_birth: null,
  delegated_permissions: [],
  email: null,
  email_settings: [],
  first_name: null,
  id: null,
  job_title: null,
  joined_at: null,
  last_name: null,
  left_at: null,
  managers: [],
  notes: null,
  office_location: null,
  personal_email_address: null,
  phone_number: null,
  photo_attributes: null,
  photo_id: null,
  photo_url: null,
  profilePage: null,
  public_notes: null,
  roles: {},
  salary: null,
  side_services: [],
  statistics: {
    time_off_days: {},
    time_off_overview: {},
    periods: {},
    unlimited: false,
    time_off_history: []
  },
  state: { name: '', caption: '' },
  teams: [],
  time_configs: [],
  values_attributes: [],
  zapier_token: '',
  actions_enabled: false,
  email_digest: false,
  otp_required_for_login: false,
  isSwitchesLocked: false
}

const emailSettingsLens = key => lens.prop('email_settings').prop(key)
const attachmentsLens = lens.prop('attachments')

export default function employee(state = initialState, action) {
  switch(action.type) {
  case UPDATE_EMPLOYEE:
    return { ...initialState, ...action.data }
  case UPDATE_EMPLOYEE_ERRORS:
    return { ...state, errors: action.errors }
  case DELETE_EMPLOYEE_ATTACHMENT_REQUEST:
    return attachmentsLens.update(state, items => items.filter(i => i.id !== action.id))
  case UPDATE_EMPLOYEE_EMAIL_SETTINGS:
    return emailSettingsLens(action.key).update(state, item => ({ ...item, enabled: action.value }))
  case UPDATE_EMPLOYEE_FIELD:
    return { ...state, [action.field]: action.data }
  case DELETE_EMPLOYEE_PHOTO_REQUEST:
    return { ...state, photo_id: null }
  case SWITCH_EMPLOYEE_PAGE:
    return { ...state, profilePage: action.page }
  case TWO_FACTOR_AUTH:
    return { ...state, otp_required_for_login: action.value }
  case LOCK_EMPLOYEE_SWITCHES:
    return { ...state, isSwitchesLocked: action.value }
  default:
    return state
  }
}
