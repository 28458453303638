import user from './user'
import endpoints from './endpoints'
import page from './page'
import host from './host'
import base_host from './baseHost'
import modal from './modal'
import company from './company'
import features from './features'
import demands from './demands'
import demand from './demand'
import filters from './filters'
import loader from './loader'
import flash from './flash'
import popovers from './popovers'
import breadcrumbs from './breadcrumbs'
import user_menu from './userMenu'
import settings_menu from './settingsMenu'
import supplier from './supplier'
import timeoffWarning from './timeoffWarning'
import schedule from './schedule'
import teams from './teams'
import team from './team'
import handbooks from './handbooks'
import units from './units'
import employee from './employee'
import employees from './employees'
import timeoffsSettings from './timeoffsSettings'
import outlaysSettings from './outlaysSettings'
import activitiesSettings from './activitiesSettings'
import rolesSettings from './rolesSettings'
import demandStates from './demandStates'
import customFields from './customFields'
import plansSettings from './plansSettings'
import userAttributesSettings from './userAttributesSettings'
import reports from './reports'
import tutorial from './tutorial'

const reducers = {
  user,
  user_menu,
  settings_menu,
  endpoints,
  host,
  base_host,
  company,
  page,
  features,
  modal,
  demands,
  demand,
  filters,
  loader,
  flash,
  popovers,
  breadcrumbs,
  supplier,
  timeoffWarning,
  schedule,
  teams,
  team,
  units,
  handbooks,
  employee,
  employees,
  timeoffsSettings,
  outlaysSettings,
  activitiesSettings,
  demandStates,
  customFields,
  rolesSettings,
  plansSettings,
  userAttributesSettings,
  reports,
  tutorial
}

export default reducers
