import {
  GET_ROLES_SUCCESS
} from '../actions/rolesSettings'

const initialState = {
  roles: [],
  groups: [],
  users: [],
  errors: {}
}

export default function (state = initialState, action) {
  switch(action.type) {
  case GET_ROLES_SUCCESS:
    return { ...state, ...action.settings }
  default:
    return state
  }
}
