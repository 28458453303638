import {
  UPDATE_UNITS_SETTINGS_SUCCESS,
  CHANGE_UNITS_SETTINGS_REQUEST,
  LOCK_UNITS_SWITCHES
} from '../actions/units'

const initialState = { isSwitchesLocked: false }

export default function units(state = initialState, action) {
  switch(action.type) {
  case CHANGE_UNITS_SETTINGS_REQUEST:
  case UPDATE_UNITS_SETTINGS_SUCCESS:
    return {
      ...state,
      ...action.units
    }
  case LOCK_UNITS_SWITCHES:
    return {
      ...state,
      isSwitchesLocked: action.value
    }
  default:
    return state
  }
}
