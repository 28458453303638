import { createAction } from '../lib/utils'

export const RESET_FILTER = 'RESET_FILTER'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const ACTIVATE_FILTER = 'ACTIVATE_FILTER'
export const DEACTIVATE_FILTER = 'DEACTIVATE_FILTER'
export const REQUEST_WITH_FILTERS = 'REQUEST_WITH_FILTERS'
export const FILTERS_FROM_QUERY = 'FILTERS_FROM_QUERY'

export const resetFilter = createAction(RESET_FILTER, 'name')
export const updateFilter = createAction(UPDATE_FILTER, 'name', 'data')
export const activateFilter = createAction(ACTIVATE_FILTER, 'name')
export const deactivateFilter = createAction(DEACTIVATE_FILTER, 'name')
export const requestWithFilters = createAction(REQUEST_WITH_FILTERS, 'url')
export const filtersFromQuery = createAction(FILTERS_FROM_QUERY)
