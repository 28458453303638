import { createAction } from '../lib/utils'

export const UPDATE_PAGE = 'UPDATE_PAGE'
export const PAGES = {
  INVITATION_EDIT: 'invitation_edit',
  SIGN_IN: 'sign_in',
  SIGN_IN_WITH_OTP: 'sign_in_with_otp',
  SIGN_UP: 'sign_up',
  SITE_NAME: 'site_name',
  SETUP_ACCOUNT: 'setup_account',
  PASSWORD: 'password',
  PASSWORD_SUCCESS: 'password_success',
  PASSWORD_EDIT: 'password_edit',
  CONTINUE_SESSION: 'continue_session',

  DASHBOARD: 'dashboard',
  EXPENSES: 'expenses',
  ACTIVITIES: 'activities',
  PURCHASES: 'purchases',
  TIME_OFF: 'time off',
  SCHEDULE: 'schedule',
  EMPLOYEES: 'employees',
  TEAMS: 'teams',
  HANDBOOK: 'handbook',
  EMPLOYEES_STATS: 'Statistics',
  EMPLOYEES_EMAIL: 'Email Settings',
  EMPLOYEES_ACCOUNTS: 'Additional Accounts',
  REPORTS: 'reports'
}

export const updatePage = createAction(UPDATE_PAGE, 'page')
