import { LOADER_SHOW, LOADER_HIDE } from '../actions/loader'

const initialState = {
}

export default (state = initialState, action) => {
  switch (action.type) {
  case LOADER_SHOW:
    return {
      ...state,
      [action.name]: true
    }
  case LOADER_HIDE:
    return {
      ...state,
      [action.name]: false
    }
  default:
    return state
  }
}
