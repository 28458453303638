import { moneyFormat } from './moneyFormat'
import moment from 'moment'
import pluralize from 'pluralize'

moment.updateLocale('en', { week: { dow: 0 } })

const MAIN_GRAPH_HEIGHT = 100
const CHILD_GRAPH_HEIGHT = 50
const byValue = (a, b) => b.value - a.value
const unique = (v, i, arr) => !i || arr[i - 1] !== v ? v : null
const fixed = value => value % 1 === 0 ? value : +value.toFixed(2)
const DATE_FORMAT = 'YYYY-MM-DD'
const RANGE_FORMAT = 'DD/MM/YYYY'

const titleFormatByMode = {
  month: 'MMMM',
  week: 'MMM D',
  year: 'YYYY',
  quarter: 'MMMM',
  big: 'YYYY'
}
const stepByMode = {
  month: 'day',
  week: 'day',
  year: 'month',
  quarter: 'week',
  big: 'year'
}

export class Report {
  constructor({ collection, feature, filters, period, custom_period, currency, from_date }) {
    this.collection = collection
    this.feature = feature
    this.fromDate = from_date
    this.filters = filters
    this.period = period
    this.custom_period = custom_period
    this.currency = currency

    this.usersTotals = {}
    this.datesTotals = {}
    this.stats = {}
  }

  calculate() {
    return new Promise(resolve => {
      this.weekOffset = 0
      this.isTO = this.feature === 'TimeOffs'
      this.mode = this.getModeByPeriod()
      this.step = stepByMode[this.mode]
      this.titleFormat = titleFormatByMode[this.mode]
      this.range = this.periodRange[this.period](this.custom_period)
      this.generateCalendarRange()
      const graphs = this.calculateGraphs()
      resolve(graphs)
    })
  }

  sortedTotals(obj) {
    return Object.keys(obj)
      .map(name => ({ name, value: +obj[name].total.toFixed(2) }))
      .sort(byValue)
      .map(({ name, value }, i) => ({
        name,
        value,
        color: this.nextRainbowColor(i)
      }))
  }

  nextRainbowColor(i, offset = 5, k = 0.6, amp = 100) {
    const step = i + offset
    const r = ~~(Math.sin(k * step) * amp + amp)
    const g = ~~(Math.sin(k * step + 2) * amp + amp)
    const b = ~~(Math.sin(k * step + 4) * amp + amp)

    return `rgba(${r},${g},${b},0.5)`
  }

  getModeByPeriod() {
    if (['all_periods', 'custom_period'].includes(this.period)) {
      const diff = moment(this.custom_period.to, RANGE_FORMAT)
        .diff(moment(this.custom_period.from, RANGE_FORMAT), 'day')

      if (diff < 14) {
        return 'week'

      } else if (diff < 130) {
        return 'month'

      } else if (diff < 370) {
        return 'year'

      } else if (diff < 600) {
        return 'quarter'
      }
      return 'big'
    }

    return {
      this_week: 'week',
      last_week: 'week',
      this_month: 'month',
      last_month: 'month',
      last_30_days: 'month',
      this_quarter: 'quarter',
      last_quarter: 'quarter',
      this_year: 'year',
      last_year: 'year'
    }[this.period]
  }

  readableRange() {
    const { from, to } = this.range
    const period = this.period

    if (['this_month', 'last_month'].includes(period)) {
      return from.format('MMM, YYYY')

    } else if (['this_year', 'last_year'].includes(period)) {
      return from.format('YYYY')

    } else if (['this_quarter', 'last_quarter'].includes(period)) {
      return from.format('MMM') + ' - ' + to.format('MMM, YYYY')
    }
    const days = to.diff(from, 'day') > 0
    const month = days && from.month() !== to.month()
    const year = days && from.year() !== to.year()

    return from.format(`MMM D${year ? ', YYYY' : ''}`) + ' - ' + to.format(`${month ? 'MMM ' : ''}D, YYYY`)
  }

  generateGraph(items) {
    return this.calendarRange.map(date => ({ date, value: items[date] || 0 }))
  }

  generateTitles() {
    return this.calendarRange
      .map(date => moment(date, DATE_FORMAT).format(this.titleFormat))
      .map(unique)
  }

  generateProgress(items) {
    const total = items.reduce((a, c) => a + c.value, 0)

    return items.map(item => ({
      ...item,
      value: this.formatValue(item.value),
      percentage: item.value / total * 100
    }))
  }

  getSelected(filterName) {
    const source = this.filters[filterName]
    if (!source || source.value.length < 1) {
      return null
    }

    const reducer = (acc, { value, title }) => ({ ...acc, [value]: title })
    const mapping = source.values.reduce(reducer, {})

    return source.value.map(t => mapping[t])
  }

  calculateGraphs() {
    const items = this.collection
    this.selectedTeams = this.getSelected('teams_multi')
    this.stats.team = {}
    this.usersTotals.team = {}
    const datesTotals = {}
    this.datesTotals.team = {}
    let total = 0

    items.forEach((item) => {
      const { dates } = item
      const demand = {}
      let demandsTotal = 0

      dates.split(', ').forEach(e => {
        const [day, val] = e.split(' ')
        const date = moment(day, day.includes('/') ? RANGE_FORMAT : DATE_FORMAT).startOf(this.step).format(DATE_FORMAT)
        const value = parseFloat(val)

        demandsTotal += value
        demand[date] = (demand[date] || 0) + value
        datesTotals[date] = (datesTotals[date] || 0) + value

        this.calculateDateTotals(e, item, date, value)
      })

      total += demandsTotal

      this.calculateUsersTotals(item, demand, demandsTotal)
    })

    const mainGraph = this.generateGraph(datesTotals)
    const max = Math.max(...Object.values(datesTotals))
    const heightStep = MAIN_GRAPH_HEIGHT / (max || 1)

    const teams = this.sortedTotals(this.stats.team)
    const topTeams = this.generateProgress(teams)

    const byTeam = teams.map(({ name, value }, i) => {
      const items = this.datesTotals.team[name]
      const users = this.stats.team[name].users
      const totals = Object.keys(users)
        .map(name => ({ name, value: this.usersTotals.team[name] }))
        .sort(byValue)
      const max = Math.max(...Object.values(items))

      return {
        name,
        value: this.formatValue(value),
        color: this.nextRainbowColor(i),
        graph: this.generateGraph(items),
        topValues: this.generateProgress(totals),
        heightStep: CHILD_GRAPH_HEIGHT / (max || 1)
      }
    })

    return {
      feature: this.feature,
      fromDate: this.fromDate,
      readableRange: this.readableRange(),
      titles: this.generateTitles(),
      heightStep,
      weekOffset: this.weekOffset / 7 * 100,
      selectable: this.step !== 'day',
      step: this.step,
      solidLine: ['week', 'big'].includes(this.mode),
      grouped: {
        teams: byTeam
      },
      top: {
        teams: topTeams
      },
      csvs: {
        teams: this.generateCSV(byTeam)
      },
      excelSheets: {
        teams: this.generateExcelData(byTeam)
      },
      main: {
        color: 'rgba(47,73,109,0.5)',
        subtotals: this.formatValue(total),
        title: this.subtotalTitle(total),
        graph: mainGraph,
        hint: this.step !== 'day' ? `Select ${this.step} for more info` : null
      },
      calendarRange: this.calendarRange
    }
  }

  subtotalTitle = () => 'Subtotal'

  formatValue = v => moneyFormat(v, this.currency).replace(/\s/g, '')

  calculateDateTotals(e, item, date, value) {
    const { teams } = item
    const approvedTeams = this.selectedTeams ? teams.filter(t => this.selectedTeams.includes(t)) : teams

    this.calculateDateTotalsFor(approvedTeams, 'team', date, value)
  }

  calculateDateTotalsFor(source, kind, date, value) {
    source.forEach(param => {
      if (!this.datesTotals[kind][param]) {
        this.datesTotals[kind][param] = {}
      }
      this.datesTotals[kind][param][date] = (this.datesTotals[kind][param][date] || 0) + value
    })
  }

  calculateUsersTotals(item, demand, demandsTotal) {
    const { teams, user, type } = item
    const approvedTeams = this.selectedTeams ? teams.filter(t => this.selectedTeams.includes(t)) : teams

    this.usersTotals.team[user] = (this.usersTotals.team[user] || 0) + demandsTotal
    approvedTeams.forEach(teamName => {
      this.addUserDates(this.stats.team, teamName, user, demand)
      this.stats.team[teamName].total += demandsTotal
    })
  }

  calculateUsersTotalsFor(param, kind, user, demand, demandsTotal) {
    this.addUserDates(this.stats[kind], param, user, demand)
    this.stats[kind][param].total += demandsTotal
    if (!this.usersTotals[kind][param]) {
      this.usersTotals[kind][param] = {}
    }
    this.usersTotals[kind][param][user] = (this.usersTotals[kind][param][user] || 0) + demandsTotal
  }

  grouped(source, kind) {
    return source.map(({ name, value }, i) => {
      const items = this.datesTotals[kind][name]
      const users = this.stats[kind][name].users
      const totals = Object.keys(users)
        .map(user => ({ name: user, value: this.usersTotals[kind][name][user] }))
        .sort(byValue)
      const max = Math.max(...Object.values(items))

      return {
        name,
        value: this.formatValue(value),
        color: this.nextRainbowColor(i),
        graph: this.generateGraph(items),
        topValues: this.generateProgress(totals),
        heightStep: CHILD_GRAPH_HEIGHT / (max || 1)
      }
    })
  }

  generateExcelData(grouped) {
    const data = []

    grouped.forEach(({ name, graph }) => {
      const obj = { name }
      graph.forEach(({ date, value }) => obj[date] = fixed(value))
      data.push(obj)
    })
    return data
  }

  generateCSV(grouped) {
    return grouped.length > 0 ?
           [
             ['name', ...this.calendarRange].join(','),
             ...grouped.map(({ name, graph }) =>
               [
                 name.replace(/,/g, ' '),
                 ...graph.map(({ value }) => fixed(value))
               ].join(','))
           ].join('\n') : null
  }

  generateCalendarRange() {
    const currDate = this.range.from.clone()
    let steps = this.range.to.endOf(this.step).diff(currDate, this.step)
    this.calendarRange = []

    while (steps-- >= 0) {
      const date = currDate.startOf(this.step).format(DATE_FORMAT)
      this.calendarRange.push(date)
      currDate.add(1, this.step)
    }

    if (this.step === 'week') {
      this.weekOffset = this.calendarRange[0] ? this.range.from.diff(this.calendarRange[0], 'days') : 0
      if (this.weekOffset > 0) {
        this.calendarRange.splice(0, 1)
      }
    }
  }

  addUserDates(stats, field, userName, demands) {
    if (!stats[field]) {
      stats[field] = { users: {}, total: 0 }
    }
    if (!stats[field].users[userName]) {
      stats[field].users[userName] = {}
    }
    const datesRef = stats[field].users[userName]
    Object.keys(demands).forEach(key =>
      datesRef[key] = (datesRef[key] || 0) + demands[key])
  }

  rangeThis = step => ({
    from: moment().startOf(step),
    to: moment().endOf(step)
  })

  rangeLast = step => ({
    from: moment().subtract(1, step).startOf(step),
    to: moment().subtract(1, step).endOf(step)
  })

  periodRange = {
    all_periods: ({ from }) => ({
      from: moment(from, RANGE_FORMAT).startOf(this.step),
      to: moment()
    }),
    custom_period: ({ from, to }) => ({
      from: moment(from, RANGE_FORMAT),
      to: moment(to, RANGE_FORMAT)
    }),
    this_week: () => this.rangeThis('week'),
    last_week: () => this.rangeLast('week'),
    this_month: () => this.rangeThis('month'),
    last_month: () => this.rangeLast('month'),
    last_30_days: () => ({ from: moment().subtract(30, 'day'), to: moment() }),
    this_quarter: () => this.rangeThis('quarter'),
    last_quarter: () => this.rangeLast('quarter'),
    this_year: () => this.rangeThis('year'),
    last_year: () => this.rangeLast('year')
  }
}
