/* eslint camelcase: 0 */

import { takeEvery, takeLatest } from 'redux-saga'
import { call, fork, put, select } from 'redux-saga/effects'
import { del, get, put as apiPut, post, upload } from '../api/utils'
import {
  ADD_EMPLOYEE_ATTACHMENT_REQUEST,
  ADD_EMPLOYEE_DAYS_REQUEST,
  ADD_EMPLOYEE_TO_TEAM_REQUEST,
  CONFIRM_EMPLOYEES_REQUEST,
  CONNECT_EMPLOYEE_ACCOUNT_REQUEST,
  CREATE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_ATTACHMENT_REQUEST,
  DELETE_EMPLOYEE_PHOTO_REQUEST,
  DISCHARGE_EMPLOYEE_REQUEST,
  DISCONNECT_EMPLOYEE_ACCOUNT_REQUEST,
  DISCONNECT_SERVICE_REQUEST,
  DISABLE_TWO_FACTOR_AUTH_REQUEST,
  VERIFY_QRCODE_REQUEST,
  GET_EMPLOYEE_REQUEST,
  INVITE_EMPLOYEE_REQUEST,
  REHIRE_EMPLOYEE_REQUEST,
  REMOVE_EMPLOYEE_FROM_TEAM_REQUEST,
  RESET_EMPLOYEE_PASSWORD_REQUEST,
  SWITCH_EMPLOYEE_PAGE,
  UPDATE_EMPLOYEE_BLACKOUT_CONFIG_REQUEST,
  UPDATE_EMPLOYEE_CONFIG_REQUEST,
  UPDATE_EMPLOYEE_EMAIL_CONFIG_REQUEST,
  UPDATE_EMPLOYEE_EMAIL_DIGEST_CONFIG_REQUEST,
  UPDATE_EMPLOYEE_REQUEST,
  UPLOAD_EMPLOYEES_REQUEST,
  UPLOAD_EMPLOYEE_PHOTO_REQUEST,
  DELETE_EMPLOYEE_ACCOUNT_REQUEST,
  lockEmployeeSwitches,
  updateEmployee,
  updateEmployeeField,
  getEmployeeRequest,
  twoFactorAuth,
  updateErrors
} from '../actions/employee'
import routes from '../api/routes'
import { proceed, showFlash, getDateTimeFormat } from './utils'
import {
  bulkEmployeeDateMapping,
  employeeEmailConfigMapping,
  employeeEmailDigestConfigMapping,
  employeeMapping,
  employeesBulkMapping
} from '../api/mappings'
import { modalHide } from '../actions/modal'
import { loaderHide, loaderShow } from '../actions/loader'
import {
  activateItem,
  updateUninvited,
  lockSubmit,
  updateUploadedEmployees,
  removeEmployeeItem
} from '../actions/employees'
import { activateFilter, deactivateFilter, updateFilter } from '../actions/filters'
import { convertRubyDateFormat } from '../lib/dateUtils'

function* fetchEmployee({ id }) {
  const { item } = yield call(get, `${routes.employees}/${id}`)

  yield put(updateEmployee(item))
  yield put(activateItem(item.id))
}

function* saveEmployee({ router }, { employee }) {
  yield put(lockSubmit(true))
  const mapped = employeeMapping(employee)
  const { success, item, errors } = yield call(apiPut, `${routes.employees}/${employee.id}`, mapped)

  if (success) {
    yield put(updateEmployee(item))
    yield fork(showFlash, 'success', 'Profile saved')
    yield call([router, router.transitionTo], `/employees/${item.id}`)
  } else {
    yield fork(showFlash, 'error', 'Profile saving error')
    yield put(updateEmployeeField('errors', errors))
  }
  yield put(lockSubmit(false))
}

function* createEmployee({ router }, { employee, invite }) {
  yield put(lockSubmit(true))
  const mapped = { ...employeeMapping(employee), invite }
  delete mapped.user.delegated_permissions
  delete mapped.user.config_id
  const { success, id, flash, errors } = yield call(post, `${routes.employees}`, mapped)
  if (success) {
    yield fork(showFlash, 'success', flash)
    yield call([router, router.transitionTo], `/employees/${id}`)
  } else {
    yield fork(showFlash, 'error', flash || 'Profile saving error')
    yield put(updateEmployeeField('errors', errors))
  }
  yield put(lockSubmit(false))
}

function* updateBlackoutConfig({ id, blackoutConfigId }) {
  const { blackout_config_id, statistics } = yield call(
    apiPut, `${routes.employees}/${id}/employee_blackout_configs/${blackoutConfigId}`
  )
  yield put(updateEmployeeField('blackout_config_id', blackout_config_id))
  yield put(updateEmployeeField('statistics', statistics))
}

function* updateConfig({ id, configId }) {
  const { config_id, statistics } = yield call(
    apiPut, `${routes.employees}/${id}/employee_user_configs/${configId}`
  )
  yield put(updateEmployeeField('config_id', config_id))
  yield put(updateEmployeeField('statistics', statistics))
}

function* updateEmailConfig() {
  const employee = yield select(s => s.employee)
  yield put(lockEmployeeSwitches(true))
  const config = employeeEmailConfigMapping(employee.email_settings)
  const { success, item } = yield call(apiPut, `${routes.employees}/${employee.id}`, config)

  if(success) {
    yield put(updateEmployee(item))
    yield fork(showFlash, 'success', 'Email settings updated')
  } else {
    yield fork(showFlash, 'error', 'Email settings saving error')
  }
  yield put(lockEmployeeSwitches(false))
}

export function* updateEmailDigestConfig({ employee }) {
  const config = employeeEmailDigestConfigMapping(employee)
  const { success, item } = yield call(apiPut, `${routes.employees}/${employee.id}`, config)

  if(success) {
    yield put(updateEmployee(item))
    yield fork(showFlash, 'success', 'Profile saved')
  } else {
    yield fork(showFlash, 'error', 'Profile saving error')
  }
}

function* addRemoveDays({ id, data }) {
  const response = yield call(
    post,
    `${routes.employees}/${id}/holiday_entries`,
    { holiday_entry: data }
  )
  yield put(updateEmployeeField('statistics', response.statistics))
}

function* resetPassword({ id }) {
  const { flash } = yield call(post, `${routes.employees}/${id}/reset_password_instruction`)
  yield fork(showFlash, 'success', flash)
}

function* uploadPhoto({ id, data }) {
  yield put(loaderShow('employeePhoto'))
  const { success, photo_url, photo_id } = yield call(
    upload,
    {
      path: `${routes.employees}/${id}/user_photos`,
      file: data
    }
  )
  if (success) {
    yield put(updateEmployeeField('photo_url', photo_url))
    yield put(updateEmployeeField('photo_id', photo_id))
  } else {
    yield fork(showFlash, 'error', 'Photo upload error')
  }
  yield put(loaderHide('employeePhoto'))
}

export function* uploadEmployees({ file }) {
  yield put(loaderShow('bulkEmployees'))
  const { success, flash, data } = yield call(
    upload,
    {
      path: routes.bulkEmployees,
      file,
      name: 'csv_file'
    }
  )

  if (success) {
    const rubyDateFormat = yield select(getDateTimeFormat)
    const dateFormat = convertRubyDateFormat(rubyDateFormat)
    const formatted = bulkEmployeeDateMapping(data, dateFormat)

    yield put(updateUploadedEmployees(formatted, data))
    yield fork(showFlash, 'success', flash)
  } else {
    yield fork(showFlash, 'error', flash)
  }
  yield put(loaderHide('bulkEmployees'))
}

function* confirmEmployees({ data, next }) {
  yield put(loaderShow('confirmBulkUpload'))
  const { success, flash } = yield call(
    post,
    routes.bulkEmployees,
    {
      users_parameters_confirmation: 1,
      users_parameters: employeesBulkMapping(data)
    }
  )
  yield put(updateUploadedEmployees({}))
  yield put(loaderHide('confirmBulkUpload'))
  if (success) {
    yield fork(showFlash, 'success', flash)
  } else {
    yield fork(showFlash, 'error', flash)
  }
  next && next()
}

function* deletePhoto({ id }) {
  yield call(del, `${routes.userPhotos}/${id}`)
}

function* dischargeEmployee({ router }, { id }) {
  const confirmed = yield call(proceed,
    ` If you archive this user’s account, we will keep
  all their information and request but they won’t be
  able to log in or create new requests and they won’t
  count against your total number of users for billing
  purposes.`
  )

  if (confirmed) {
    const { success, flash } = yield call(del, `${routes.employees}/${id}/discharge`)
    if (success) {
      yield fork(showFlash, 'success', flash)
      yield call([router, router.transitionTo], `/employees/${id}`)
      yield put(updateEmployeeField(
        'state',
        { name: 'discharged', color: 'discharged', caption: 'archived' })
      )
    } else {
      yield fork(showFlash, 'error', 'Discharge error')
    }
  }
}

function* deleteAccount({ router }, { id }) {
  let confirmed = yield call(proceed,
    `  If you delete this user’s personal information,
  it’s gone for good. We can’t recover it for you.
  Their requests, comments and approvals will stay in
  the system but they will be anonymised and attributed
  to ‘Deleted user’. Consider Archiving the user instead.
  Please be very sure before clicking the delete button.`
  )

  if (!confirmed) {
    return
  }

  confirmed = yield call(proceed)

  if (confirmed) {
    const { item } = yield call(del, `${routes.employees}/${id}/clear`)
    yield put(activateItem(null))
    yield put(removeEmployeeItem(id))
    yield fork(showFlash, 'success', 'User was deleted')
    yield call([router, router.transitionTo], '/employees')
  }
}

function* rehireEmployee({ router }, { id }) {
  const confirmed = yield call(proceed)

  if (confirmed) {
    const { success, flash } = yield call(apiPut, `${routes.employees}/${id}/discharge`)
    if (success) {
      yield fork(showFlash, 'success', flash)
      yield call([router, router.transitionTo], `/employees/${id}`)
      yield put(updateEmployeeField(
        'state',
        { name: 'active', color: 'active', caption: 'active' })
      )
    } else {
      yield fork(showFlash, 'error', flash)
    }
  }
}

export function* addAttachmentProcess(id, attach) {
  yield put(loaderShow('demandAttachments'))
  const { success, attachments } = yield call(
    upload,
    {
      path: `${routes.employees}/${id}/user_attachments`,
      file: attach
    }
  )
  if (success) {
    yield put(updateEmployeeField('attachments', attachments))
  }
  yield put(loaderHide('demandAttachments'))
}

export function* addAttachmentsProcess({ id, data }) {
  yield data.map(attach => fork(addAttachmentProcess, id, attach))
}

function* deleteAttachment({ id }) {
  yield call(del, `${routes.userAttachments}/${id}`)
}

function* addToTeam({ employee, team, role }) {
  yield call(post,
    `${routes.teams}/${team.id}/${role}`,
    { user_ids: employee.id })
  yield put(getEmployeeRequest(employee.id))
}

function* removeFromTeam({ employee, team, role }) {
  yield call(del, `${routes.teams}/${team.id}/${role}/${employee.id}`)
  yield put(getEmployeeRequest(employee.id))
}

function* inviteEmployee({ id }) {
  const { flash, uninvited_users } = yield call(post, `${routes.employees}/${id}/invitation`)

  yield put(updateUninvited(uninvited_users))
  yield fork(showFlash, 'success', flash)
}

function* connectAccount({ id, data }) {
  const { success, flash, connections } = yield call(
    post,
    `${routes.employees}/${id}/connected_users`,
    {
      connected_user: {
        user_id: id,
        login: data.email,
        password: data.password
      }
    }
  )
  if (success) {
    yield put(updateEmployeeField('connections', connections))
  } else {
    yield fork(showFlash, 'error', flash)
  }
}

function* disconnectAccount({ id, connectedId }) {
  const { connections } = yield call(
    del,
    `${routes.employees}/${id}/connected_users/${connectedId}`
  )
  yield put(updateEmployeeField('connections', connections))
}

function* disconnectService({ employee, command }) {
  const { side_services } = yield call(
    del,
    `${routes.employees}/${employee.id}/${command}`
  )
  yield put(updateEmployeeField('side_services', side_services))
}

export function* verifyQrcode({ otp_attempt }) {
  const result = yield call(post, routes.twoFactorAuthentications, { otp_attempt })
  if (result.success) {
    yield fork(showFlash, 'success', 'Two factor authentication is enabled')
    yield put(twoFactorAuth(true))
    yield put(modalHide())
  } else {
    yield fork(showFlash, 'error', result.errors.otp_attempt[0])
    yield put(updateErrors(result.errors))
  }
}

export function* disableTwoFactorAuth() {
  const result = yield call(del, routes.twoFactorAuthentications)
  if (result.success) {
    yield fork(showFlash, 'success', 'Two factor authentication is disabled')
    yield put(twoFactorAuth(false))
  } else {
    yield fork(showFlash, 'error', 'Two factor authentication is not disabled for some reason')
    yield put(updateErrors(result.errors))
  }
}

function* switchPage({ page }) {
  yield put(updateFilter('show', { value: page }))
  if (page) {
    yield put(activateFilter('show'))
  } else {
    yield put(deactivateFilter('show'))
  }
}

export function* watchEmployeeUpdates(context) {
  yield [
    takeEvery(ADD_EMPLOYEE_ATTACHMENT_REQUEST, addAttachmentsProcess),
    takeEvery(ADD_EMPLOYEE_DAYS_REQUEST, addRemoveDays),
    takeEvery(ADD_EMPLOYEE_TO_TEAM_REQUEST, addToTeam),
    takeEvery(CONFIRM_EMPLOYEES_REQUEST, confirmEmployees),
    takeEvery(CONNECT_EMPLOYEE_ACCOUNT_REQUEST, connectAccount),
    takeEvery(CREATE_EMPLOYEE_REQUEST, createEmployee, context),
    takeEvery(DELETE_EMPLOYEE_ATTACHMENT_REQUEST, deleteAttachment),
    takeEvery(DELETE_EMPLOYEE_PHOTO_REQUEST, deletePhoto),
    takeEvery(DISCHARGE_EMPLOYEE_REQUEST, dischargeEmployee, context),
    takeEvery(DISCONNECT_EMPLOYEE_ACCOUNT_REQUEST, disconnectAccount),
    takeEvery(DISCONNECT_SERVICE_REQUEST, disconnectService),
    takeEvery(VERIFY_QRCODE_REQUEST, verifyQrcode),
    takeEvery(DISABLE_TWO_FACTOR_AUTH_REQUEST, disableTwoFactorAuth),
    takeEvery(GET_EMPLOYEE_REQUEST, fetchEmployee),
    takeEvery(INVITE_EMPLOYEE_REQUEST, inviteEmployee),
    takeEvery(REHIRE_EMPLOYEE_REQUEST, rehireEmployee, context),
    takeEvery(REMOVE_EMPLOYEE_FROM_TEAM_REQUEST, removeFromTeam),
    takeEvery(RESET_EMPLOYEE_PASSWORD_REQUEST, resetPassword),
    takeEvery(SWITCH_EMPLOYEE_PAGE, switchPage),
    takeEvery(UPDATE_EMPLOYEE_CONFIG_REQUEST, updateConfig),
    takeEvery(UPDATE_EMPLOYEE_BLACKOUT_CONFIG_REQUEST, updateBlackoutConfig),
    takeEvery(UPDATE_EMPLOYEE_EMAIL_CONFIG_REQUEST, updateEmailConfig),
    takeEvery(UPDATE_EMPLOYEE_EMAIL_DIGEST_CONFIG_REQUEST, updateEmailDigestConfig),
    takeEvery(UPDATE_EMPLOYEE_REQUEST, saveEmployee, context),
    takeEvery(UPLOAD_EMPLOYEES_REQUEST, uploadEmployees),
    takeEvery(UPLOAD_EMPLOYEE_PHOTO_REQUEST, uploadPhoto),
    takeLatest(DELETE_EMPLOYEE_ACCOUNT_REQUEST, deleteAccount, context)
  ]
}
