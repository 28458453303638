import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getParams } from '../../lib/utils'
import { children } from '../../propTypes'
import { allErrors } from '../../sagas/utils'
import SocialLinks from './SocialLinks'
import { Label, Columns, Column } from './Block'

const mapStateToProps = (state) => ({
  endpoints: state.endpoints,
  user: state.user,
  page: state.page
})

class StaticUserForm extends Component {
  static propTypes = {
    children: children,
    page: PropTypes.string.isRequired,
    endpoints: PropTypes.object.isRequired,
    showRememberMe: PropTypes.bool,
    user: PropTypes.object.isRequired
  }

  componentWillMount() {
    const tag = document.querySelector('[name=csrf-token]')
    this.csrf = tag ? tag.content : null
  }

  render() {
    const {
      children,
      showRememberMe,
      user,
      endpoints,
      page
    } = this.props
    const errors = allErrors(getParams()).replace(/\+/g, ' ')

    return (
      <form
        className='b-form'
        method='post'
        action='/users/sign_in'
      >
        <Columns modifiers='authentication__columns b-columns_justify-start b-columns_wrap'>
          <Column modifiers='authentication__column authentication__column_left'>

            <input name='authenticity_token'
              value={this.csrf}
              type='hidden'
            />

            <div className='b-form__input-group'>

              <input
                className='b-form__input'
                id='email'
                name='email'
                placeholder='Your email address'
                type='email'
              />
            </div>

            <div className='b-form__input-group'>
              <input
                className='b-form__input'
                id='password'
                name='password'
                placeholder='Password'
                type='password'
              />
            </div>

            <div className='b-text b-text_red'>
              {errors}
            </div>

            {
              showRememberMe ?
              <div className='b-form__input-group'>
                <input
                  checked={user.remember_me}
                  className='b-form__input_checkbox'
                  id='remember_me'
                  name='remember_me'
                  type='checkbox'
                />

                <Label
                  htmlFor='remember_me'
                  modifiers='b-form__label_checkbox'
                >Remember Me</Label>
              </div> : null
            }

            <div className='b-form__input-group b-form__input-group_centered'>
              <input type='submit' value='Sign In' className='b-button b-button_primary' />
            </div>

          </Column>

          <Column modifiers='authentication__column authentication__column_right'>
            <SocialLinks endpoints={endpoints.providers} page={page} />
          </Column>

          {children}
        </Columns>
      </form>
    )
  }
}

export default connect(mapStateToProps)(StaticUserForm)
