import React, { Component } from 'react'

export class ViewportSensor extends Component {
  render() {
    return (
      <div className='viewport-sensor'>
        <div className='viewport-sensor__desktop' />
        <div className='viewport-sensor__tablet' />
        <div className='viewport-sensor__mobile' />
        <div className='viewport-sensor__mobile-narrow' />
      </div>
    )
  }
}

export default ViewportSensor
