import { createAction } from '../lib/utils'

export const CONFIRM_INVITATION_REQUEST = 'CONFIRM_INVITATION_REQUEST'
export const UPDATE_USER_FIELDS = 'UPDATE_USER_FIELDS'
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD'
export const UPDATE_USER_ERRORS = 'UPDATE_USER_ERRORS'
export const UPDATE_USER_FROM_SERVER = 'UPDATE_USER_FROM_SERVER'
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST'
export const STATUS_REQUEST = 'STATUS_REQUEST'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'

export const confirmInvitationRequest = createAction(CONFIRM_INVITATION_REQUEST, 'user')
export const updateFields = createAction(UPDATE_USER_FIELDS, 'user')
export const updateUserField = createAction(UPDATE_USER_FIELD, 'field', 'data')
export const updateErrors = createAction(UPDATE_USER_ERRORS, 'errors')
export const updateFromServer = createAction(UPDATE_USER_FROM_SERVER, 'user')
export const signInRequest = createAction(SIGN_IN_REQUEST, 'user')
export const signUpRequest = createAction(SIGN_UP_REQUEST, 'user')
export const signOutRequest = createAction(SIGN_OUT_REQUEST)
export const statusRequest = createAction(STATUS_REQUEST)
export const resetPasswordRequest = createAction(RESET_PASSWORD_REQUEST, 'user')
export const updatePasswordRequest = createAction(UPDATE_PASSWORD_REQUEST, 'user')
