import { createAction } from '../lib/utils'

export const ADD_COST_CENTER_REQUEST = 'ADD_COST_CENTER_REQUEST'
export const ADD_COST_CENTER_SUCCESS = 'ADD_COST_CENTER_SUCCESS'
export const ADD_RANGE_REQUEST = 'ADD_RANGE_REQUEST'
export const ADD_SUPPLIER_REQUEST = 'ADD_SUPPLIER_REQUEST'
export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS'
export const DELETE_COST_CENTER_REQUEST = 'DELETE_COST_CENTER_REQUEST'
export const DELETE_COST_CENTER_SUCCESS = 'DELETE_COST_CENTER_SUCCESS'
export const DELETE_RANGE_REQUEST = 'DELETE_RANGE_REQUEST'
export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST'
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS'
export const GET_OUTLAYS_SETTINGS_REQUEST = 'GET_OUTLAYS_SETTINGS_REQUEST'
export const GET_OUTLAYS_SETTINGS_SUCCESS = 'GET_OUTLAYS_SETTINGS_SUCCESS'
export const UPDATE_COMPANY_CONFIG_REQUEST = 'UPDATE_COMPANY_CONFIG_REQUEST'
export const UPDATE_COMPANY_CONFIG_SUCCESS = 'UPDATE_COMPANY_CONFIG_SUCCESS'
export const UPDATE_COST_CENTER_REQUEST = 'UPDATE_COST_CENTER_REQUEST'
export const UPDATE_COST_CENTER_SUCCESS = 'UPDATE_COST_CENTER_SUCCESS'
export const UPDATE_DEMAND_PREFIX_REQUEST = 'UPDATE_DEMAND_PREFIX_REQUEST'
export const UPDATE_LOCALES_REQUEST = 'UPDATE_LOCALES_REQUEST'
export const UPDATE_PREDEFINED_FIELDS_REQUEST = 'UPDATE_PREDEFINED_FIELDS_REQUEST'
export const UPDATE_RANGE_REQUEST = 'UPDATE_RANGE_REQUEST'
export const UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST'
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS'
export const UPLOAD_COST_CENTERS_REQUEST = 'UPLOAD_COST_CENTERS_REQUEST'
export const UPLOAD_SUPPLIERS_REQUEST = 'UPLOAD_SUPPLIERS_REQUEST'
export const UPDATE_BULK_UPLOADED = 'UPDATE_BULK_UPLOADED'
export const CONFIRM_COST_CENTERS_REQUEST = 'CONFIRM_COST_CENTERS_REQUEST'
export const CONFIRM_SUPPLIERS_REQUEST = 'CONFIRM_SUPPLIERS_REQUEST'

export const addCostCenterRequest = createAction(ADD_COST_CENTER_REQUEST, 'costCenter')
export const addCostCenterSuccess = createAction(ADD_COST_CENTER_SUCCESS, 'item')
export const addRangeRequest = createAction(ADD_RANGE_REQUEST, 'range')
export const addSupplierRequest = createAction(ADD_SUPPLIER_REQUEST, 'supplier', 'after')
export const addSupplierSuccess = createAction(ADD_SUPPLIER_SUCCESS, 'item')
export const deleteCostCenterRequest = createAction(DELETE_COST_CENTER_REQUEST, 'costCenter')
export const deleteCostCenterSuccess = createAction(DELETE_COST_CENTER_SUCCESS, 'item')
export const deleteRangeRequest = createAction(DELETE_RANGE_REQUEST, 'range')
export const deleteSupplierRequest = createAction(DELETE_SUPPLIER_REQUEST, 'supplier')
export const deleteSupplierSuccess = createAction(DELETE_SUPPLIER_SUCCESS, 'item')
export const getOutlaysSettingsRequest = createAction(GET_OUTLAYS_SETTINGS_REQUEST)
export const getOutlaysSettingsSuccess = createAction(GET_OUTLAYS_SETTINGS_SUCCESS, 'settings')
export const updateCompanyConfigRequest = createAction(UPDATE_COMPANY_CONFIG_REQUEST, 'config')
export const updateCompanyConfigSuccess = createAction(UPDATE_COMPANY_CONFIG_SUCCESS, 'config')
export const updateCostCenterRequest = createAction(UPDATE_COST_CENTER_REQUEST, 'costCenter')
export const updateCostCenterSuccess = createAction(UPDATE_COST_CENTER_SUCCESS, 'item')
export const updateDemandPrefixRequest = createAction(UPDATE_DEMAND_PREFIX_REQUEST, 'kind', 'name')
export const updateLocalesRequest = createAction(UPDATE_LOCALES_REQUEST, 'locales')
export const updatePredefinedFieldsRequest = createAction(UPDATE_PREDEFINED_FIELDS_REQUEST, 'config', 'modelType')
export const updateRangeRequest = createAction(UPDATE_RANGE_REQUEST, 'range')
export const updateSupplierRequest = createAction(UPDATE_SUPPLIER_REQUEST, 'supplier', 'after')
export const updateSupplierSuccess = createAction(UPDATE_SUPPLIER_SUCCESS, 'item')
export const uploadCostCentersRequest = createAction(UPLOAD_COST_CENTERS_REQUEST, 'file')
export const uploadSuppliersRequest = createAction(UPLOAD_SUPPLIERS_REQUEST, 'file')
export const updateBulkUploaded = createAction(UPDATE_BULK_UPLOADED, 'data')
export const confirmCostCentersRequest = createAction(CONFIRM_COST_CENTERS_REQUEST, 'data', 'next')
export const confirmSuppliersRequest = createAction(CONFIRM_SUPPLIERS_REQUEST, 'data', 'next')
