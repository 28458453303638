import React, { Component } from 'react'
import SignInForm from './Form'

class SignIn extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Sign In
          </h2>
          <div className='b-title__subtitle'>
            Log in to your account to continue
          </div>
        </div>

        <SignInForm />
      </div>
    )
  }
}

export default SignIn
