import { createAction } from '../lib/utils'

export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'
export const CREATE_SUPPLIER_REQUEST = 'CREATE_SUPPLIER_REQUEST'
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS'
export const NEW_SUPPLIER_REQUEST = 'NEW_SUPPLIER_REQUEST'

export const updateSupplier = createAction(UPDATE_SUPPLIER, 'value')
export const newSupplierRequest = createAction(NEW_SUPPLIER_REQUEST)
export const createSupplierRequest = createAction(CREATE_SUPPLIER_REQUEST, 'value', 'afterCreate')
export const createSupplierSuccess = createAction(CREATE_SUPPLIER_SUCCESS, 'supplier')
