import { Lens } from 'lorgnette/dist/lens'
import { just, nothing } from 'lorgnette'

export class ArrayFindLens extends Lens {
  constructor(criteria, defaultValue = null) {
    super()
    this.criteria = criteria
    this.defaultValue = defaultValue
  }

  get(obj) {
    if (obj instanceof Array) {
      const value = obj.find(this.criteria)

      if (typeof value !== 'undefined') {
        return just(value)
      }

      if (this.defaultValue) {
        return just(this.defaultValue)
      }

      return nothing
    }

    return nothing
  }

  update(obj, func) {
    let updated = obj

    if (this.criteria !== null && obj instanceof Array) {
      let index = obj.findIndex(this.criteria)
      let oldVal = obj[index]
      let newVal = func(oldVal)

      if (oldVal !== newVal) {
        updated = obj.slice()
        updated[index] = newVal
      }
    }

    return updated
  }
}
