import React, { Component } from 'react'
import BaseApp from './BaseApp'
import { Panel } from '../components/shared/Block'
import Authentication from '../components/Authentication/Index'

class AuthenticationApp extends Component {
  render() {
    return (
      <BaseApp {...this.props}>
        <Panel>
          <Authentication {...this.props} />
        </Panel>
      </BaseApp>
    )
  }
}

export default AuthenticationApp
