/* eslint camelcase: 0 */

import { takeEvery } from 'redux-saga'
import { call, put, select, fork } from 'redux-saga/effects'
import {
  ADD_COST_CENTER_REQUEST,
  ADD_RANGE_REQUEST,
  ADD_SUPPLIER_REQUEST,
  CONFIRM_COST_CENTERS_REQUEST,
  CONFIRM_SUPPLIERS_REQUEST,
  DELETE_COST_CENTER_REQUEST,
  DELETE_RANGE_REQUEST,
  DELETE_SUPPLIER_REQUEST,
  GET_OUTLAYS_SETTINGS_REQUEST,
  UPDATE_COMPANY_CONFIG_REQUEST,
  UPDATE_COST_CENTER_REQUEST,
  UPDATE_DEMAND_PREFIX_REQUEST,
  UPDATE_LOCALES_REQUEST,
  UPDATE_PREDEFINED_FIELDS_REQUEST,
  UPDATE_RANGE_REQUEST,
  UPDATE_SUPPLIER_REQUEST,
  UPLOAD_COST_CENTERS_REQUEST,
  UPLOAD_SUPPLIERS_REQUEST,
  addCostCenterSuccess,
  addSupplierSuccess,
  deleteCostCenterSuccess,
  deleteSupplierSuccess,
  getOutlaysSettingsSuccess,
  updateBulkUploaded,
  updateCompanyConfigSuccess,
  updateCostCenterSuccess,
  updateSupplierSuccess
} from '../actions/outlaysSettings'
import routes from '../api/routes'
import { makeUrl, get, del, put as apiPut, post, upload } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import {
  costCentersBulkMapping,
  costCentersMapping,
  localesMapping,
  outlayPrintableMapping, suppliersBulkMapping,
  suppliersMapping,
  virtualsMapping
} from '../api/mappings'
import { loaderHide, loaderShow } from '../actions/loader'

const rangeType = range => range.type.replace(/^(.+)Type$/, '/$1_demand_types').toLowerCase()
const rangeLoader = range => `${range.type}_ranges`

export function* getSettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.outlaysSettings)
  const { settings } = yield call(get, url)

  if (settings) {
    yield put(getOutlaysSettingsSuccess(settings))
  }
}

export function* updateConfig({ config }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.outlayPrintable)
  const data = outlayPrintableMapping(config)
  const { item } = yield call(apiPut, url, data)
  if (item) {
    yield fork(showFlash, 'success', 'Settings saved')
    yield put(updateCompanyConfigSuccess(config))
  }
}

export function* updatePrefix({ kind, name }) {
  const host = yield select(getHost)
  const url = yield call(
    makeUrl,
    host,
    routes.outlayPrefix,
    { type: kind.replace('_identifier', '') }
  )
  const data = { company_config: { [kind]: name } }
  const { config, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Prefix updated')
    yield put(updateCompanyConfigSuccess(config))
  }
}

export function* addRange({ range }) {
  yield put(loaderShow(rangeLoader(range)))
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.adminSettings)
  const type = rangeType(range)
  const data = { demand_type: range }
  const { settings, errors } = yield call(post, url + type, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Range added')
    yield put(getOutlaysSettingsSuccess(settings))
  }
  yield put(loaderHide(rangeLoader(range)))
}

export function* deleteRange({ range }) {
  const deleteConfirmed = yield call(proceed)
  if (deleteConfirmed) {
    yield put(loaderShow(rangeLoader(range)))
    const host = yield select(getHost)
    const url = yield call(makeUrl, host, routes.adminSettings)
    const type = rangeType(range)
    const { settings, errors } = yield call(del, `${url}${type}/${range.id}`)
    if (errors) {
      yield fork(showFlash, 'error', allErrors(errors))
    } else {
      yield fork(showFlash, 'success', 'Range deleted')
      yield put(getOutlaysSettingsSuccess(settings))
    }
    yield put(loaderHide(rangeLoader(range)))
  }
}

export function* updateRange({ range }) {
  yield put(loaderShow(rangeLoader(range)))
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.adminSettings)
  const type = rangeType(range)
  const data = { demand_type: range }
  const { settings, errors } = yield call(apiPut, `${url}${type}/${range.id}`, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Range updated')
    yield put(getOutlaysSettingsSuccess(settings))
  }
  yield put(loaderHide(rangeLoader(range)))
}

export function* updateLocales({ locales }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.localesSettings)
  const data = localesMapping(locales)
  const { settings, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Locales updated')
    yield put(getOutlaysSettingsSuccess(settings))
  }
}

export function* updateVirtuals({ config, modelType }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.virtualsSettings, { id: config.id })
  const data = virtualsMapping(config, modelType)
  const { settings, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Pre-defined fields updated')
    yield put(getOutlaysSettingsSuccess(settings))
  }
}

export function* addCostCenter({ costCenter }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.costCentersSettings)
  const data = costCentersMapping(costCenter)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Cost Center added')
    yield put(addCostCenterSuccess(item))
  }
}

export function* deleteCostCenter({ costCenter }) {
  const deleteConfirmed = yield call(proceed)
  if (!deleteConfirmed) {
    return
  }
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.costCenterSettings, { id: costCenter.id })
  const { item, errors } = yield call(del, url)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Cost Center deleted')
    yield put(deleteCostCenterSuccess(item))
  }
}

export function* updateCostCenter({ costCenter }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.costCenterSettings, { id: costCenter.id })
  const data = costCentersMapping(costCenter)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Cost Center updated')
    yield put(updateCostCenterSuccess(item))
  }
}

function* uploadCostCenters({ file }) {
  yield put(loaderShow('costCentersUpload'))
  const { success, flash, data } = yield call(
    upload,
    { path: routes.bulkCostCenters, file, name: 'csv_file' }
  )
  yield put(updateBulkUploaded({ costCenters: data }))
  yield put(loaderHide('costCentersUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
}

function* confirmCostCenters({ data, next }) {
  yield put(loaderShow('confirmBulkUpload'))
  const { success, flash } = yield call(
    post,
    routes.bulkCostCenters,
    {
      cost_centers_parameters_confirmation: 1,
      cost_centers_parameters: costCentersBulkMapping(data)
    }
  )
  yield put(loaderHide('confirmBulkUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
  yield put(updateBulkUploaded({}))
  next && next()
}

export function* addSupplier({ supplier, after }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.suppliersSettings)
  const data = suppliersMapping(supplier)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Supplier added')
    yield put(after())
    yield put(addSupplierSuccess(item))
  }
}

export function* deleteSupplier({ supplier }) {
  const deleteConfirmed = yield call(proceed)
  if (!deleteConfirmed) {
    return
  }
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.supplierSettings, { id: supplier.id })
  const { item, errors } = yield call(del, url)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Supplier deleted')
    yield put(deleteSupplierSuccess(item))
  }
}

export function* updateSupplier({ supplier, after }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.supplierSettings, { id: supplier.id })
  const data = suppliersMapping(supplier)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Supplier updated')
    yield put(after())
    yield put(updateSupplierSuccess(item))
  }
}

function* uploadSuppliers({ file }) {
  yield put(loaderShow('suppliersUpload'))
  const { success, flash, data } = yield call(
    upload,
    { path: routes.bulkSuppliers, file, name: 'csv_file' }
  )
  yield put(updateBulkUploaded({ suppliers: data }))
  yield put(loaderHide('suppliersUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
}

function* confirmSuppliers({ data, next }) {
  yield put(loaderShow('confirmBulkUpload'))
  const { success, flash } = yield call(
    post,
    routes.bulkSuppliers,
    {
      suppliers_parameters_confirmation: 1,
      suppliers_parameters: suppliersBulkMapping(data)
    }
  )
  yield put(loaderHide('confirmBulkUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
  yield put(updateBulkUploaded({}))
  next && next()
}

export function* watchOutlaysSettingsUpdates() {
  yield [
    takeEvery(ADD_COST_CENTER_REQUEST, addCostCenter),
    takeEvery(ADD_RANGE_REQUEST, addRange),
    takeEvery(ADD_SUPPLIER_REQUEST, addSupplier),
    takeEvery(CONFIRM_COST_CENTERS_REQUEST, confirmCostCenters),
    takeEvery(CONFIRM_SUPPLIERS_REQUEST, confirmSuppliers),
    takeEvery(DELETE_COST_CENTER_REQUEST, deleteCostCenter),
    takeEvery(DELETE_RANGE_REQUEST, deleteRange),
    takeEvery(DELETE_SUPPLIER_REQUEST, deleteSupplier),
    takeEvery(GET_OUTLAYS_SETTINGS_REQUEST, getSettings),
    takeEvery(UPDATE_COMPANY_CONFIG_REQUEST, updateConfig),
    takeEvery(UPDATE_COST_CENTER_REQUEST, updateCostCenter),
    takeEvery(UPDATE_DEMAND_PREFIX_REQUEST, updatePrefix),
    takeEvery(UPDATE_LOCALES_REQUEST, updateLocales),
    takeEvery(UPDATE_PREDEFINED_FIELDS_REQUEST, updateVirtuals),
    takeEvery(UPDATE_RANGE_REQUEST, updateRange),
    takeEvery(UPDATE_SUPPLIER_REQUEST, updateSupplier),
    takeEvery(UPLOAD_COST_CENTERS_REQUEST, uploadCostCenters),
    takeEvery(UPLOAD_SUPPLIERS_REQUEST, uploadSuppliers)
  ]
}
