import { UPDATE_BASE_HOST } from '../actions/host'

const initialState = ''

export default function base_host(state = initialState, action) {
  switch(action.type) {
  case UPDATE_BASE_HOST:
    return action.base_host
  default:
    return state
  }
}
