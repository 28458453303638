import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Form from 'react-formal'
import { connect } from 'react-redux'
import { confirmInvitationRequest, updateFields, updateErrors } from '../../../actions/user'
import { editInvitationSchema } from '../../../schemas/user'
import SubmitButton from '../../shared/SubmitButton'
import { Columns, Column } from '../../shared/Block'

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  confirmInvitationRequest,
  updateFields,
  updateErrors
}

class EditInvitationForm extends Component {
  static propTypes = {
    confirmInvitationRequest: PropTypes.func.isRequired,
    updateFields: PropTypes.func.isRequired,
    updateErrors: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  render() {
    const { confirmInvitationRequest, user, updateErrors, updateFields } = this.props

    return (
      <Form
        className='b-form'
        defaultValue={user}
        errors={user.errors}
        onError={updateErrors}
        onChange={updateFields}
        onSubmit={confirmInvitationRequest}
        schema={editInvitationSchema}
      >
        <Columns modifiers='authentication__columns_wide b-columns_collapse-mobile'>
          <Column modifiers='b-columns__column_half-sized'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['first_name']}/>
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                id='first_name'
                name='first_name'
                placeholder='First name'
                value={user.first_name}
              />
            </div>
          </Column>

          <Column modifiers='b-columns__column_half-sized'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['last_name']}/>
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                id='last_name'
                name='last_name'
                placeholder='Last name'
                value={user.last_name}
              />
            </div>
          </Column>
        </Columns>

        <Columns modifiers='authentication__columns_wide b-columns_collapse-mobile'>
          <Column modifiers='b-columns__column_half-sized'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['password']}/>
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                id='password'
                name='password'
                placeholder='Password'
                type='password'
                value={user.password}
              />
            </div>
          </Column>

          <Column modifiers='b-columns__column_half-sized'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['password_confirmation']}/>
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                events={['onBlur']}
                id='password_confirmation'
                name='password_confirmation'
                placeholder='Password confirmation'
                type='password'
                value={user.password_confirmation}
              />
            </div>
          </Column>
        </Columns>

        <Columns modifiers='authentication__columns_wide b-columns_collapse-mobile'>
          <Column modifiers='b-columns__column_half-sized'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['email']}/>
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                events={['onBlur']}
                id='email'
                name='email'
                placeholder='Email'
                value={user.email}
              />
            </div>
          </Column>

          <Column modifiers='b-columns__column_half-sized'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['personal_email_address']}/>
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                events={['onBlur']}
                id='personal_email_address'
                name='personal_email_address'
                placeholder='Personal email'
                value={user.personal_email_address}
              />
            </div>
          </Column>
        </Columns>

        <div className='authentication__buttons'>
          <SubmitButton
            className='b-button b-button_primary'
          >Submit</SubmitButton>
        </div>
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInvitationForm)
