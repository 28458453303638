import React, { Component } from 'react'
import cn from 'classnames'
import Form from 'react-formal'

class SubmitButton extends Component {
  render() {
    return (
      <Form.Trigger group='@all'>
        {({ messages }) =>
          <Form.Button
            {...this.props}
            className={cn(this.props.className, { 'b-button_disabled': !!Object.keys(messages).length })}
            disabled={!!Object.keys(messages).length || !!this.props.disabled}
            type='submit'
          />
        }
      </Form.Trigger>
    )
  }
}

export default SubmitButton
