/* eslint camelcase: 0 */

import { lens } from 'lorgnette'
import {
  UPDATE_COMPANY,
  UPDATE_PLAN,
  UPDATE_COMPANY_ERRORS,
  UPDATE_COMPANY_SETTINGS_SUCCESS,
  UPDATE_COMPANY_SETTINGS_ERRORS,
  RESET_COMPANY_SETTINGS_ERRORS,
  CHANGE_COMPANY_SETTINGS
} from '../actions/company'

const initialState = {
  site_name: '',
  promo_code: null,
  trial: false,
  payment_warning: '',
  errors: {},
  settings: {
    all_currencies: [],
    logo_attributes: {},
    alt_logo_attributes: {},
    attachments_archives: []
  },
  user_options: [],
  add_users_faq_url: null
}

const settingsErrorsLens = lens.prop('settings').prop('errors', {})

export default function company(state = initialState, action) {
  switch(action.type) {
  case UPDATE_COMPANY:
    return {
      ...state,
      ...action.company,
      promo_code: action.company.promo_code && action.company.promo_code.toLowerCase()
    }
  case UPDATE_PLAN:
    return {
      ...state,
      plan: action.plan
    }
  case UPDATE_COMPANY_ERRORS:
    return {
      ...state,
      errors: action.errors
    }
  case UPDATE_COMPANY_SETTINGS_ERRORS:
    return settingsErrorsLens.set(state, action.errors)
  case RESET_COMPANY_SETTINGS_ERRORS:
    return settingsErrorsLens.set(state, {})
  case UPDATE_COMPANY_SETTINGS_SUCCESS:
  case CHANGE_COMPANY_SETTINGS:
    return {
      ...state,
      settings: action.data
    }
  default:
    return state
  }
}
