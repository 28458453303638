import { lens } from 'lorgnette'
import {
  // ADD_DEMAND_STATE_SUCCESS,
  GET_DEMAND_STATES_SUCCESS,
  // DELETE_DEMAND_STATE_SUCCESS,
  UPDATE_DEMAND_STATE_SUCCESS,
  UPDATE_DEMAND_STATE_ERRORS
} from '../actions/demandStates'

const initialState = {
  collection: [],
  errors: {}
}
const stateByIdLens = id => lens.prop('collection').find(s => s.id === id)

export default function (state = initialState, action) {
  switch(action.type) {
  case GET_DEMAND_STATES_SUCCESS:
    return {
      ...state,
      collection: action.collection
    }
  case UPDATE_DEMAND_STATE_ERRORS:
    return {
      ...state,
      errors: action.errors
    }
  // case ADD_DEMAND_STATE_SUCCESS:
  //   return {
  //     ...state,
  //     collection: [...state.collection, action.item]
  //   }
  // case DELETE_DEMAND_STATE_SUCCESS:
  //   return {
  //     ...state,
  //     collection: state.collection.filter(s => s.id !== action.item.id)
  //   }
  case UPDATE_DEMAND_STATE_SUCCESS:
    return stateByIdLens(action.item.id).update(state, item => ({ ...item, ...action.item }))
  default:
    return state
  }
}
