import { FLASH_SHOW, FLASH_HIDE } from '../actions/flash'

const initialState = {
  active: false,
  kind: '',
  text: ''
}

export default (state = initialState, action) => {
  switch(action.type) {
  case FLASH_SHOW:
    return {
      active: true,
      kind: action.kind,
      text: action.text
    }
  case FLASH_HIDE:
    return initialState
  default:
    return state
  }
}
