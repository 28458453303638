import React, { Component } from 'react'
import EditPasswordForm from './Form'

class PasswordEdit extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Change your password
          </h2>
        </div>

        <EditPasswordForm />
      </div>
    )
  }
}

export default PasswordEdit
