import { lens } from 'lorgnette'
import {
  BREADCRUMBS_PUSH,
  BREADCRUMBS_POP,
  BREADCRUMBS_UPGRADE,
  BREADCRUMBS_DOWNGRADE,
  BREADCRUMBS_RESET
} from '../actions/breadcrumbs'

const initialState = []

const lastCrumb = lens.at(-1)

export default (state = initialState, action) => {
  switch(action.type) {
  case BREADCRUMBS_PUSH:
    return [...state, action.breadcrumb]
  case BREADCRUMBS_POP:
    return state.slice(0, -1)
  case BREADCRUMBS_UPGRADE:
    return lastCrumb.update(state, v => ({...v, link: action.link}))
  case BREADCRUMBS_DOWNGRADE:
    return lastCrumb.update(state, v => ({title: v.title}))
  case BREADCRUMBS_RESET:
    return initialState
  default:
    return state
  }
}
