const routes = {
  attachment: '/api/v1/attachments/:id',
  attachments: '/api/v1/attachments',
  attachmentsArchive: '/api/v1/admin/attachments_archive',
  blackoutConfig: '/api/v1/admin/settings/blackout_configs/:id',
  blackoutConfigs: '/api/v1/admin/settings/blackout_configs',
  bulkEmployees: '/api/v1/admin/settings/bulk_employees',
  companySettings: '/api/v1/admin/settings/company',
  settingsCustomFields: '/api/v1/admin/settings/demand_options',
  settingsCustomField: '/api/v1/admin/settings/demand_options/:id',
  demandAttachment: '/api/v1/demands/:demand_id/attachments/:id',
  demandAttachmentsNew: '/api/v1/demands/:demand_id/attachments',
  demandComments: '/api/v1/demands/:id/comments',
  demandStates: '/api/v1/demands/:id/state_changes',
  activityUser: '/api/v1/activities/:activity_id/activity_user',
  adminSettings: '/api/v1/admin/settings',
  localesSettings: '/api/v1/admin/settings/localizes',
  editCompanySettings: '/api/v1/admin/settings/company/edit',
  editUnitsSettings: '/api/v1/admin/settings/modules/edit',
  employees: '/api/v1/employees',
  handbook: '/api/v1/wiki',
  handbooksSettings: '/api/v1/admin/settings/handbook/edit',
  handbooksSetting: '/api/v1/admin/settings/handbook',
  schedule: '/api/v1/schedule',
  settingsDemandState: '/api/v1/admin/settings/demand_states/:id',
  settingsDemandStates: '/api/v1/admin/settings/demand_states',
  staff: '/api/v1/teams/:teamId/:staffType',
  staffMember: '/api/v1/teams/:teamId/:staffType/:id',
  staffNew: '/api/v1/teams/:teamId/:staffType/new',
  status: '/api/v1/status',
  supplierNew: '/api/v1/user_suppliers/new',
  suppliers: '/api/v1/user_suppliers',
  teams: '/api/v1/teams',
  timeoffDemandTypes: '/api/v1/admin/settings/time_off_demand_types/:id',
  timeoffsDemandTypes: '/api/v1/admin/settings/time_off_demand_types',
  timeoffsSettings: '/api/v1/admin/settings/time_offs',
  outlaysSettings: '/api/v1/admin/settings/outlays',
  activitiesSettings: '/api/v1/admin/settings/activities',
  bulkActivities: '/api/v1/admin/settings/bulk_activities',
  rolesSettings: '/api/v1/admin/settings/roles',
  unitsSettings: '/api/v1/admin/settings/modules',
  userAttachments: '/api/v1/user_attachments',
  userConfig: '/api/v1/admin/user_configs/:id',
  outlayPrintable: '/api/v1/admin/settings/outlay_printable',
  outlayPrefix: '/api/v1/admin/settings/:type',
  userConfigs: '/api/v1/admin/user_configs',
  userPhotos: '/api/v1/user_photos',
  validateSiteName: '/api/v1/validate_site_name',
  removePromoCode: '/api/v1/remove_promo_code',
  applyPromoCode: '/api/v1/admin/settings/payment_profile/apply_promo_code',
  virtualsSettings: '/api/v1/admin/settings/virtuals/:id',
  costCentersSettings: '/api/v1/admin/settings/cost_centers',
  costCenterSettings: '/api/v1/admin/settings/cost_centers/:id',
  bulkCostCenters: '/api/v1/admin/settings/bulk_cost_centers',
  suppliersSettings: '/api/v1/admin/settings/suppliers',
  supplierSettings: '/api/v1/admin/settings/suppliers/:id',
  bulkSuppliers: '/api/v1/admin/settings/bulk_suppliers',
  categoriesSettings: '/api/v1/admin/settings/categories',
  categorySettings: '/api/v1/admin/settings/categories/:id',
  pointsSettings: '/api/v1/admin/settings/points',
  pointSettings: '/api/v1/admin/settings/points/:id',
  bulkPoints: '/api/v1/admin/settings/bulk_points',
  clientsSettings: '/api/v1/admin/settings/clients',
  clientSettings: '/api/v1/admin/settings/clients/:id',
  bulkClients: '/api/v1/admin/settings/bulk_clients',
  plansSettings: '/api/v1/admin/settings/plan/edit',
  planSettings: '/api/v1/admin/settings/plan',
  settingsAddCard: '/api/v1/admin/settings/payment_profile/stripe_callback',
  pendingPayment: '/api/v1/admin/settings/payment_profile/pending_payment',
  adminCompany: '/api/v1/admin/company',
  userAttributesSettings: '/api/v1/admin/settings/user_attributes',
  userAttributeSettings: '/api/v1/admin/settings/user_attributes/:id',
  reports: '/api/v1/reports',
  tutorials: '/api/v1/tutorials/:id',
  twoFactorAuthentications: '/api/v1/two_factor_authentications',
  featureNotificationViewers: '/api/v1/feature_notification_viewers'
}

export default routes
