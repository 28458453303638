/* eslint camelcase: 0 */

import { takeEvery } from 'redux-saga'
import { put, call, select } from 'redux-saga/effects'
import { post } from '../api/utils'
import routes from '../api/routes'
import { getUser } from './utils'
import { updateUserField } from '../actions/user'
import { REMOVE_FEATURE_NOTIFICATION } from '../actions/featureNotifications'

export function* removeFeatureNotification({ feature_notification_id }) {
  yield call(post, routes.featureNotificationViewers, { feature_notification_id })
  const user = yield select(getUser)
  const removeIndex = user.feature_notifications.findIndex(
    item => item.id === feature_notification_id
  )
  if (removeIndex !== -1) {
    user.feature_notifications.splice(removeIndex, 1)
    yield put(updateUserField('feature_notifications', user.feature_notifications))
  }
}

export function* watchFeatureNotifications() {
  yield takeEvery(REMOVE_FEATURE_NOTIFICATION, removeFeatureNotification)
}
