import moment from 'moment'
import { dayOfTheYear } from '../lib/dateUtils'
import {
  UPDATE_DAYS_SELECTION_RANGE
} from '../actions/schedule'

const today = new Date()
const startOfThisMonth = moment().startOf('month').toDate()
const endOfThisMonth = moment().endOf('month').toDate()
const startOfLastMonth = moment().subtract(30, 'days').toDate()

const currentDayIndex = dayOfTheYear(today) - 1
const startOfThisMonthIndex = dayOfTheYear(startOfThisMonth) - 1
const endOfThisMonthIndex = dayOfTheYear(endOfThisMonth) - 1
const startOfLastMonthIndex = dayOfTheYear(startOfLastMonth)

const daysSelectionsDict = {
  today: [currentDayIndex, currentDayIndex],
  thisMonth: [startOfThisMonthIndex, endOfThisMonthIndex],
  lastMonth: [startOfLastMonthIndex, currentDayIndex]
}

const initialState = {
  currentDayColor: '#fff9de',
  daysSelectionRange: [currentDayIndex, currentDayIndex]
}

export default function schedule(state = initialState, action) {
  switch (action.type) {
  case UPDATE_DAYS_SELECTION_RANGE:
    return {
      ...state,
      daysSelectionRange: daysSelectionsDict[action.range]
    }
  default:
    return state
  }
}
