import PropTypes from 'prop-types';
/* eslint react/no-did-mount-set-state: 0 */

import React, { Component } from 'react'
import cn from 'classnames'
import { isArray, isDefined, isServer } from '../../../lib/utils'
import { isViewport } from '../../../lib/responsive'
import { children } from '../../../propTypes'

const ViewportType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.string
  )
])

class Viewport extends Component {
  static propTypes = {
    children,
    only: ViewportType,
    except: ViewportType
  }

  subscriptions = {}
  state = { isActive: this.shouldDisplay }

  componentDidMount() {
    this.setState({ isActive: this.shouldDisplay })
  }

  componentWillMount() {
    this.setState({ isActive: this.shouldDisplay })
    this.subscriptions.resize = this.onResize.bind(this)

    if (!isServer()) {
      global.addEventListener('resize', this.subscriptions.resize)
    }
  }

  componentWillUnmount() {
    if (!isServer()) {
      global.removeEventListener('resize', this.subscriptions.resize)
    }
  }

  componentWillReceiveProps() {
    this.onResize()
  }

  onResize() {
    this.setState({ isActive: this.shouldDisplay })
  }

  get displayOnly() {
    let { only } = this.props
    only = isArray(only) ? only : [only]
    return only.some(name => isViewport(name))
  }

  get displayExcept() {
    let { except } = this.props
    except = isArray(except) ? except : [except]
    return except.every(name => !isViewport(name))
  }

  get shouldDisplay() {
    const { only } = this.props
    return isDefined(only) ? this.displayOnly : this.displayExcept
  }

  get classNameModifiers() {
    let { only, except } = this.props
    only = isDefined(only) ? (isArray(only) ? only : [only]) : []
    except = isDefined(except) ? (isArray(except) ? except : [except]) : []

    return [
      ...only.map(c => `only-${c}`),
      ...except.map(c => `except-${c}`)
    ].map(c => `viewport-content--${c}`)
  }

  render() {
    const { children } = this.props
    const { isActive } = this.state

    if (!isActive && !isServer() || !children) return null

    return React.cloneElement(children, {
      className: cn(children.props.className, 'viewport-content', ...this.classNameModifiers)
    })
  }
}

export default Viewport
