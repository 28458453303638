import { takeLatest, delay } from 'redux-saga'
import { put, select, call, fork, cancel } from 'redux-saga/effects'
import routes from '../api/routes'
import { makeUrl, get } from '../api/utils'
import { getFilters, getHost, updateFilterCollection } from './utils'
import { drawReport, GET_REPORT_REQUEST } from '../actions/reports'
import { ACTIVATE_FILTER, DEACTIVATE_FILTER } from '../actions/filters'
import { filtersToParams } from '../lib/filtersUtils'
import { loaderHide, loaderShow } from '../actions/loader'
import { Report } from '../lib/Report'
import ActivitiesReport from '../lib/ActivitiesReport'
import TimeOffsReport from '../lib/TimeOffsReport'
import OutlaysReport from '../lib/OutlaysReport'

const REPORTS_CONTROLLER = 'reports'

export function* fetchReport() {
  const host = yield select(getHost)
  const currFilters = yield select(getFilters)
  const url = makeUrl(host, routes.reports, filtersToParams(currFilters))

  yield put(loaderShow('reportLoader'))
  const data = yield call(get, url)

  yield call(updateFilterCollection, data.filters)
  let report;
  switch(data.feature) {
    case 'TimeOffs':
      report = new TimeOffsReport(data)
      break;
    case 'Activities':
      report = new ActivitiesReport(data)
      break;
    case 'Expenses':
    case 'Purchases':
      report = new OutlaysReport(data)
      break;
    default:
      report = new Report(data)
  }
  const output = yield call([report, report.calculate])

  yield put(drawReport(output))
  yield put(loaderHide('reportLoader'))
}

export function* fetchAndReplace({ timeout = 0 }) {
  yield call(delay, timeout)
  yield call(fetchReport)
}

let fetchAndReplaceJob

export function* startFetchAndReplace() {
  if (fetchAndReplaceJob) {
    yield cancel(fetchAndReplaceJob)
  }
  fetchAndReplaceJob = yield fork(fetchAndReplace, { timeout: 700 })
}

export function* filterChanged({ name }) {
  if (name !== 'group_by') {
    yield call(startFetchAndReplace)
  }
}

export function* watchReportsUpdates() {
  yield takeLatest(GET_REPORT_REQUEST, fetchReport)
}

export function* watchReportsFiltersUpdates({ request }) {
  if (request && request.react_controller === REPORTS_CONTROLLER) {
    yield [
      takeLatest(ACTIVATE_FILTER, filterChanged),
      takeLatest(DEACTIVATE_FILTER, filterChanged)
    ]
  }
}
