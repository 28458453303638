/* eslint camelcase: 0 */

import yup from 'yup'
import { REQUIRED_MESSAGE_ERROR, INVALID_MESSAGE_ERROR } from './lib'

yup.addMethod(yup.mixed, 'sameAs', function (ref, message) {
  return this.test('sameAs', message, function (value) {
    const other = this.resolve(ref)
    return !other || !value || value === other
  })
})

export const signInSchema = yup.object({
  email: yup.string()
    .email(`Email ${INVALID_MESSAGE_ERROR}`).required(`Email ${REQUIRED_MESSAGE_ERROR}`),
  password: yup.string().required(`Password ${REQUIRED_MESSAGE_ERROR}`),
  remember_me: yup.boolean()
})

export const resetPasswordSchema = yup.object({
  email: yup.string()
    .email(`Email ${INVALID_MESSAGE_ERROR}`).required(`Email ${REQUIRED_MESSAGE_ERROR}`)
})

export const editInvitationSchema = yup.object({
  email: yup.string()
    .email(`Email ${INVALID_MESSAGE_ERROR}`).required(`Email ${REQUIRED_MESSAGE_ERROR}`),
  personal_email_address: yup.string().email(`Email ${INVALID_MESSAGE_ERROR}`),
  first_name: yup.string(),
  last_name: yup.string(),
  password: yup.string().required(`Password ${REQUIRED_MESSAGE_ERROR}`),
  password_confirmation: yup.string()
    .sameAs(yup.ref('password'), 'Password confirmation does not match')
    .required(`Password ${REQUIRED_MESSAGE_ERROR}`)
})

export const editPasswordSchema = yup.object({
  password: yup.string().required(`Password ${REQUIRED_MESSAGE_ERROR}`),
  password_confirmation: yup.string()
    .sameAs(yup.ref('password'), 'Password confirmation does not match')
    .required(`Password ${REQUIRED_MESSAGE_ERROR}`)
})
