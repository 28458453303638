import { isHolidayGapExceeded, isBalanceExceeded } from '../lib/timeoffs'
import { UPDATE_TIMEOFF_DATES, UPDATE_DEMAND } from '../actions/demand'
import { lens, multi } from 'lorgnette'

export const initialState = {
  notEnoughBalance: {
    active: false,
    message: 'Not enough available days to make this request.'
  },
  separateDays: {
    active: false,
    message: `
It’s better to book separate holidays \
as separate requests in case you want to edit or cancel them later.
`
  }
}

const GAP = 7
const separateDaysLens = lens.prop('separateDays').prop('active')
const notEnoughBalanceLens = lens.prop('notEnoughBalance')
const warningsLens = multi(separateDaysLens, notEnoughBalanceLens)

export default function timeoffWarning(state = initialState, action) {
  switch(action.type) {
  case UPDATE_TIMEOFF_DATES:
    return separateDaysLens.set(state, isHolidayGapExceeded(action.dates, GAP))
  case UPDATE_DEMAND:
    if(!(action.data && action.data.days)) { return state }
    return warningsLens.update(
      state,
      active => (
        action.data.days ? isHolidayGapExceeded(action.data.days, GAP) : active
      ),
      warning => ({
        message: `
${action.data.user.first_name} ${action.data.user.last_name} doesn't have enough \
days available to book this without 'borrowing' from next year's allowance.`,
        active: action.data.statistic ?
          isBalanceExceeded(
            action.data.statistic.balance,
            action.data.description_sum,
            action.data.days
          ) : warning.active
      })
    )
  default:
    return state
  }
}
