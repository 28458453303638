import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Form from 'react-formal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateFields, updateErrors } from '../../actions/user'
import { signInSchema } from '../../schemas/user'
import { children } from '../../propTypes'
import SocialLinks from './SocialLinks'
import { Label, Columns, Column } from './Block'

const mapStateToProps = (state) => ({
  endpoints: state.endpoints,
  user: state.user,
  page: state.page
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    updateErrors,
    updateFields
  }, dispatch)
)

class UserForm extends Component {
  static propTypes = {
    children: children,
    endpoints: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    page: PropTypes.string.isRequired,
    showRememberMe: PropTypes.bool,
    updateErrors: PropTypes.func.isRequired,
    updateFields: PropTypes.func.isRequired,
    shouldBeStatic: PropTypes.bool,
    user: PropTypes.object.isRequired
  }

  render() {
    const {
      children,
      updateErrors,
      updateFields,
      onSubmit,
      showRememberMe,
      user,
      endpoints,
      page
    } = this.props

    return (
      <Form
        className='b-form'
        defaultValue={user}
        errors={user.errors}
        onError={updateErrors}
        onChange={updateFields}
        onSubmit={onSubmit}
        schema={signInSchema}
      >
        <Columns modifiers='authentication__columns b-columns_justify-start b-columns_wrap'>
          <Column modifiers='authentication__column authentication__column_left'>
            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['email']} />

              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                events={['onBlur']}
                id='email'
                name='email'
                placeholder='Your email address'
                type='text'
                value={user.email}
              />
            </div>

            <div className='b-form__input-group'>
              <Form.Message errorClass='b-form__validation-error' for={['password']} />
              <Form.Field
                className='b-form__input'
                errorClass='b-form__input_validation-error'
                id='password'
                name='password'
                placeholder='Password'
                type='password'
                value={user.password}
              />
            </div>

            {
              showRememberMe ?
              <div className='b-form__input-group'>
                <Form.Field
                  checked={user.remember_me}
                  className='b-form__input_checkbox'
                  id='remember_me'
                  name='remember_me'
                  type='boolean'
                />

                <Label
                  htmlFor='remember_me'
                  modifiers='b-form__label_checkbox'
                >Remember Me</Label>
              </div> : null
            }
          </Column>

          <Column modifiers='authentication__column authentication__column_right'>
            <SocialLinks endpoints={endpoints.providers} page={page} />
          </Column>

          {children}
        </Columns>
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
