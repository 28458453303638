import { createAction } from '../lib/utils'

export const UPDATE_UNITS_SETTINGS_SUCCESS = 'UPDATE_UNITS_SETTINGS_SUCCESS'
export const UPDATE_UNITS_SETTINGS_REQUEST = 'UPDATE_UNITS_SETTINGS_REQUEST'
export const CHANGE_UNITS_SETTINGS_REQUEST = 'CHANGE_UNITS_SETTINGS_REQUEST'
export const LOCK_UNITS_SWITCHES = 'LOCK_UNITS_SWITCHES'

export const updateUnitsSettingsSuccess = createAction(UPDATE_UNITS_SETTINGS_SUCCESS, 'units')
export const updateUnitsSettingsRequest = createAction(UPDATE_UNITS_SETTINGS_REQUEST)
export const changeUnitsSettingsRequest = createAction(CHANGE_UNITS_SETTINGS_REQUEST, 'units', 'reload')
export const lockUnitsSwitches = createAction(LOCK_UNITS_SWITCHES, 'value')
