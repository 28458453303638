import {
  GET_OUTLAYS_SETTINGS_SUCCESS,
  UPDATE_COMPANY_CONFIG_SUCCESS,
  ADD_COST_CENTER_SUCCESS,
  DELETE_COST_CENTER_SUCCESS,
  UPDATE_COST_CENTER_SUCCESS,
  ADD_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_BULK_UPLOADED,
  UPDATE_RANGE_REQUEST
} from '../actions/outlaysSettings'
import { lens } from 'lorgnette'

const rangesMapping = {
  'PurchaseType': 'purchase_demand_types',
  'ExpenseType': 'expense_demand_types'
}

const initialState = {
  config: {
    id: null,
    email: '',
    tax: 0,
    users_can_add_supplier: false,
    identifier_approval: false,
    part_deliver_po: false,
    demand_order_header: '',
    demand_order_footer: '',
    expense_identifier_prefix: '',
    purchase_identifier_prefix: ''
  },
  expense_demand_types: [],
  purchase_demand_types: [],
  locales: [],
  cost_centers: [],
  uploaded: {},
  demand_options: [],
  suppliers: [],
  virtual_expense_config: {},
  virtual_purchase_config: {},
  currencies: [],
  urls: {}
}

const rangeByIdAndTypeLens = (id, type) => lens.prop(rangesMapping[type]).find(c => c.id === id)
const costCentersLens = lens.prop('cost_centers')
const suppliersLens = lens.prop('suppliers')
const costCenterLens = item => costCentersLens.find(c => c.id === item.id)
const supplierLens = item => suppliersLens.find(c => c.id === item.id)

export default function (state = initialState, action) {
  switch(action.type) {
  case UPDATE_RANGE_REQUEST:
    return rangeByIdAndTypeLens(action.range.id, action.range.type).update(state, item => ({ ...item, ...action.range }))
  case GET_OUTLAYS_SETTINGS_SUCCESS:
    return { ...state, ...action.settings }
  case UPDATE_COMPANY_CONFIG_SUCCESS:
    return { ...state, config: action.config }
  case UPDATE_BULK_UPLOADED:
    return { ...state, uploaded: action.data }
  case ADD_COST_CENTER_SUCCESS:
    return costCentersLens.update(state, items => ([...items, action.item]))
  case DELETE_COST_CENTER_SUCCESS:
    return costCentersLens.update(state, items => items.filter(c => c.id !== action.item.id))
  case UPDATE_COST_CENTER_SUCCESS:
    return costCenterLens(action.item).update(state, item => action.item)
  case ADD_SUPPLIER_SUCCESS:
    return suppliersLens.update(state, items => ([...items, action.item]))
  case DELETE_SUPPLIER_SUCCESS:
    return suppliersLens.update(state, items => items.filter(c => c.id !== action.item.id))
  case UPDATE_SUPPLIER_SUCCESS:
    return supplierLens(action.item).update(state, item => action.item)
  default:
    return state
  }
}
