import { DRAW_REPORT } from '../actions/reports'

const initialState = {
  output: {
    feature: null,
    readableRange: null,
    titles: [],
    heightStep: 0,
    offset: 0,
    selectable: false,
    solidLine: true,
    step: null,
    grouped: {
      teams: [],
      cost_centers: [],
      suppliers: [],
      clients: [],
      points: [],
      categories: [],
      types: []
    },
    top: {
      teams: [],
      cost_centers: [],
      suppliers: [],
      clients: [],
      points: [],
      categories: [],
      types: []
    },
    csvs: {},
    excelSheets: {},
    main: {
      color: null,
      subtotals: null,
      title: null,
      graph: [],
      hint: null
    },
    from_date: null
  }
}

export default function reports(state = initialState, action) {
  switch (action.type) {
  case DRAW_REPORT:
    return {
      output: action.output
    }
  default:
    return state
  }
}
