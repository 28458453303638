/* eslint camelcase: 0 */

import yup from 'yup'
import {
  isUnicodeStringValid,
  STRING_MAX_LENGTH,
  CHARS_MESSAGE_ERROR,
  REQUIRED_MESSAGE_ERROR,
  INVALID_MESSAGE_ERROR
} from './lib'

const customFieldValue = yup.mixed()
  .when('kind', kind => {
    switch(kind) {
    case 'dropdown':
      return yup.string().default('')
    case 'link':
      return yup.string().url(INVALID_MESSAGE_ERROR).default('')
    default:
      return yup.string().max(STRING_MAX_LENGTH, CHARS_MESSAGE_ERROR)
    }
  })
  .when('required', (required, schema) => required ? schema
    .required(REQUIRED_MESSAGE_ERROR) : schema)

export const customField = yup.object().shape({
  title: yup.string().test('title', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)),
  option_id: yup.number(),
  kind: yup.string(),
  value: customFieldValue,
  variant_id: yup.number(),
  required: yup.bool(),
  variants: yup.array().of(yup.object({
    id: yup.number(),
    value: yup.string().test('values', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH))
  }))
})
