import {
  GET_ACTIVITIES_SETTINGS_SUCCESS,
  ADD_POINT_CATEGORY_SUCCESS,
  DELETE_POINT_CATEGORY_SUCCESS,
  UPDATE_POINT_CATEGORY_SUCCESS,
  ADD_POINT_SUCCESS,
  DELETE_POINT_SUCCESS,
  UPDATE_POINT_SUCCESS,
  ADD_CLIENT_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_BULK_UPLOADED
} from '../actions/activitiesSettings'
import { lens } from 'lorgnette'

const initialState = {
  points: [],
  categories: [],
  uploaded: {},
  clients: [],
  urls: {},
  config_options: {
    accrual: [],
    untaken: [],
    expiration: []
  }
}

const categoriesLens = lens.prop('categories')
const pointsLens = lens.prop('points')
const clientsLens = lens.prop('clients')
const categoryLens = item => categoriesLens.find(c => c.id === item.id)
const pointLens = item => pointsLens.find(c => c.id === item.id)
const clientLens = item => clientsLens.find(c => c.id === item.id)


export default function (state = initialState, action) {
  switch(action.type) {
  case GET_ACTIVITIES_SETTINGS_SUCCESS:
    return { ...state, ...action.settings }
  case ADD_POINT_CATEGORY_SUCCESS:
      return categoriesLens.update(state, items => ([...items, action.item]))
  case DELETE_POINT_CATEGORY_SUCCESS:
      return categoriesLens.update(state, items => items.filter(c => c.id !== action.item.id))
  case UPDATE_POINT_CATEGORY_SUCCESS:
      return categoryLens(action.item).update(state, item => action.item)
  case ADD_POINT_SUCCESS:
    return pointsLens.update(state, items => ([...items, action.item]))
  case DELETE_POINT_SUCCESS:
    return pointsLens.update(state, items => items.filter(c => c.id !== action.item.id))
  case UPDATE_POINT_SUCCESS:
    return pointLens(action.item).update(state, item => action.item)
  case ADD_CLIENT_SUCCESS:
    return clientsLens.update(state, items => ([...items, action.item]))
  case DELETE_CLIENT_SUCCESS:
    return clientsLens.update(state, items => items.filter(c => c.id !== action.item.id))
  case UPDATE_CLIENT_SUCCESS:
    return clientLens(action.item).update(state, item => action.item)
  case UPDATE_BULK_UPLOADED:
    return { ...state, uploaded: action.data }
  default:
    return state
  }
}
