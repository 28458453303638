import routes from './routes'
import { post, put, makeUrl } from './utils'

export const updateCard = (host, stripeToken) => (
  post(makeUrl(host, routes.settingsAddCard), { stripeToken })
)

export const refreshPendingPayment = (host) => (
  put(makeUrl(host, routes.pendingPayment))
)
