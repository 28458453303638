import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

export const children = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.node
  )
])

export const dateCollectionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    date: momentPropTypes.momentObj,
    duration: PropTypes.oneOf([1, 0.5])
  })
)

export const daysCollectionPropType = PropTypes.shape({
  active: dateCollectionPropType,
  completed: dateCollectionPropType
})
