import { UPDATE_HOST } from '../actions/host'

const initialState = ''

export default function host(state = initialState, action) {
  switch (action.type) {
  case UPDATE_HOST:
    return action.host
  default:
    return state
  }
}
