import ReactGA from 'react-ga'
import { takeEvery, delay } from 'redux-saga'
import { updateUser, signOut, checkStatus, confirmInvitation, updatePassword } from '../api/user'
import { redirect } from '../api/utils'
import { PAGES, updatePage } from '../actions/page'
import { call, fork, put, select } from 'redux-saga/effects'
import { getBaseHost, getHost, getPage, showFlash, takeFirst } from './utils'
import {
  CONFIRM_INVITATION_REQUEST,
  SIGN_IN_REQUEST,
  SIGN_UP_REQUEST,
  SIGN_OUT_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  STATUS_REQUEST,
  updateFromServer,
  updateErrors
} from '../actions/user'
import { updateEndpoints } from '../actions/endpoints'

const SIGN_IN_TRANSITION_TIME = 5000

const getSignInEndpoint = state => state.endpoints.sign_in_url
const getSignUpEndpoint = state => state.endpoints.sign_up_url
const getSignOutEndpoint = state => state.endpoints.sign_out_url
const getConfirmInvitationEndpoint = state => state.endpoints.confirm_invitation_url
const getResetPasswordEndpoint = state => state.endpoints.forgot_url
const getUpdatePasswordEndpoint = state => state.endpoints.edit_password_url
const getCompany = state => state.company

export function* authSuccess() {
  let host = yield select(getHost)
  let status = yield call(checkStatus, host)
  yield call(redirect, status.endpoints.dashboard_url)
}

export function* updateStatus() {
  const host = yield select(getHost)
  const result = yield call(checkStatus, host)
  yield put(updateEndpoints(result.endpoints))

  if (result.user) {
    yield put(updateFromServer(result.user))
  }
}

export function* confirmInvitationRequest({ user }) {
  const confirmInvitationEndpoint = yield select(getConfirmInvitationEndpoint)
  const result = yield call(confirmInvitation, confirmInvitationEndpoint, { user: user })
  if (result.success) {
    yield call(redirect, result.url)
  } else {
    yield put(updateErrors(result.errors))
  }
}

export function* signInUser({ user }) {
  const signInEndpoint = yield select(getSignInEndpoint)
  const result = yield call(updateUser, signInEndpoint, user)

  if (result.success) {
    yield fork(authSuccess)
    yield call(ReactGA.event, { category: 'User', action: 'Sign In' })
  }
  else {
    const page = yield select(getPage)
    if (page !== PAGES.SIGN_IN_WITH_OTP && result.errors.otp_attempt) {
      yield put(updatePage(PAGES.SIGN_IN_WITH_OTP))
      yield fork(showFlash, 'error', result.errors.otp_attempt[0])
    }
    yield put(updateErrors(result.errors))
  }
}

export function* signUpUser({ user }) {
  const signUpEndpoint = yield select(getSignUpEndpoint)
  const company = yield select(getCompany)
  const newUser = { ...user, ...company }

  // Redirect to loading page
  yield put(updatePage(PAGES.SETUP_ACCOUNT))

  // Meanwhile making user registration request
  const result = yield call(updateUser, signUpEndpoint, newUser)

  if (result.success) {
    yield fork(authSuccess)
    yield call(ReactGA.event, { category: 'User', action: 'Sign Up' })
  } else {
    // Update errors and redirect to sign up page
    yield put(updateErrors(result.errors))
    yield put(updatePage(PAGES.SIGN_UP))
  }
}

export function* signOutUser() {
  const signOutEndpoint = yield select(getSignOutEndpoint)
  yield call(ReactGA.event, { category: 'User', action: 'Sign Out' })
  const result = yield call(signOut, signOutEndpoint)

  if (result.success) {
    let host = yield select(getBaseHost)
    yield call(redirect, host)
  }
}

export function* resetPassword({ user }) {
  const resetPasswordEndpoint = yield select(getResetPasswordEndpoint)
  const result = yield call(updateUser, resetPasswordEndpoint, user)

  if (result.success) {
    yield put(updatePage(PAGES.PASSWORD_SUCCESS))
    yield call(delay, SIGN_IN_TRANSITION_TIME)
    yield put(updatePage(PAGES.SIGN_IN))
  } else {
    yield put(updateErrors(result.errors))
  }
}

export function* updatePasswordRequest({ user }) {
  const updatePasswordEndpoint = yield select(getUpdatePasswordEndpoint)
  const result = yield call(updatePassword, updatePasswordEndpoint, user)
  if (result.success) {
    yield call(redirect, result.url)
  } else {
    yield put(updateErrors(result.errors))
  }
}

export function* watchUserActions() {
  yield [
    takeEvery(CONFIRM_INVITATION_REQUEST, confirmInvitationRequest),
    takeEvery(SIGN_IN_REQUEST, signInUser),
    takeEvery(SIGN_UP_REQUEST, signUpUser),
    takeEvery(SIGN_OUT_REQUEST, signOutUser),
    takeFirst(RESET_PASSWORD_REQUEST, resetPassword),
    takeEvery(UPDATE_PASSWORD_REQUEST, updatePasswordRequest),
    takeEvery(STATUS_REQUEST, updateStatus)
  ]
}
