import { takeEvery } from 'redux-saga'
import { call, fork, put, select } from 'redux-saga/effects'
import { loaderHide, loaderShow } from '../actions/loader'
import {
  CANCEL_COMPANY_REQUEST,
  GET_PLANS_SETTINGS_REQUEST,
  getPlansSettingsRequest,
  getPlansSettingsSuccess,
  SET_CARD_REQUEST,
  SET_PLAN_REQUEST,
  APPLY_PROMO_CODE_REQUEST
} from '../actions/plansSettings'
import { applyPromoCode } from '../api/company'
import { updateCard, refreshPendingPayment } from '../api/plansSettings'
import routes from '../api/routes'
import { del, get, makeUrl, post, put as apiPut, redirect } from '../api/utils'
import { getHost, showFlash } from './utils'

export function* getPlansSettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.plansSettings)
  const { settings } = yield call(get, url)

  if (settings) {
    yield put(getPlansSettingsSuccess(settings))
  }
}

export function* setCard({ response: { error, token } }) {
  if (error) {
    yield fork(showFlash, 'error', error.message)
  } else {
    yield call(cardUpdate, { token })
  }
}

export function* cardUpdate({ token }) {
  const host = yield select(getHost)
  yield put(loaderShow('addCardLoader'))
  const { error, client_secret } = yield call(updateCard, host, token.id)
  yield put(loaderHide('addCardLoader'))
  if (error) {
    yield fork(showFlash, 'error', error.message)
  } else {
    yield call(finalizeCardUpdate, { clientSecret: client_secret })
  }
}

export function* finalizeCardUpdate({ clientSecret }) {
  if (clientSecret) {
    yield call(initiatePayment, { clientSecret })
  } else {
    yield fork(showFlash, 'success', 'Card saved')
  }
  yield put(getPlansSettingsRequest())
}

export function* initiatePayment({ clientSecret }) {
  yield put(loaderShow('addCardLoader'))
  const { error } = yield call(Stripe.handleCardPayment, clientSecret)
  const host = yield select(getHost)
  yield call(refreshPendingPayment, host)
  yield put(loaderHide('addCardLoader'))
  if (error) {
    yield fork(showFlash, 'error', error.message)
  } else {
    yield fork(showFlash, 'success', 'Card charged successfully')
  }
}

export function* setPlan({ plan, currency, billing_interval }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.planSettings, { plan, currency, billing_interval })
  yield call(apiPut, url)
  yield fork(showFlash, 'success', 'Plan changed')
  yield put(getPlansSettingsRequest())
}

export function* cancelCompany() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.adminCompany)
  yield call(del, url)
  yield fork(showFlash, 'success', 'Account cancelled')
  yield put(redirect('/users/sign_in'))
}

export function* setPromoCode({ code, onValid, onInvalid }) {
  const host = yield select(getHost)
  const result = yield call(applyPromoCode, host, { promo_code: code })

  if (result.errors) {
    onInvalid && onInvalid(result.errors.promo_code)
  } else if (!result.applied) {
    onInvalid && onInvalid('Not applied')
  } else {
    yield fork(showFlash, 'success', 'Promo code applied')
    onValid && onValid()
  }
}

export function* watchPlansSettingsUpdates() {
  yield [
    takeEvery(GET_PLANS_SETTINGS_REQUEST, getPlansSettings),
    takeEvery(SET_CARD_REQUEST, setCard),
    takeEvery(SET_PLAN_REQUEST, setPlan),
    takeEvery(CANCEL_COMPANY_REQUEST, cancelCompany),
    takeEvery(APPLY_PROMO_CODE_REQUEST, setPromoCode)
  ]
}
