import { createAction } from '../lib/utils'

export const ACTIVATE_ITEM = 'ACTIVATE_ITEM'
export const APPEND_EMPLOYEES_DATA = 'APPEND_EMPLOYEES_DATA'
export const REMOVE_EMPLOYEE_ITEM = 'REMOVE_EMPLOYEE_ITEM'
export const LOCK_SUBMIT = 'LOCK_SUBMIT'
export const REPLACE_EMPLOYEES_DATA = 'REPLACE_EMPLOYEES_DATA'
export const UPDATE_EMPLOYEES_REQUEST = 'UPDATE_EMPLOYEES_REQUEST'
export const UPDATE_SINGLE_EMPLOYEE = 'UPDATE_SINGLE_EMPLOYEE'
export const UPDATE_UNINVITED = 'UPDATE_UNINVITED'
export const UPDATE_UPLOADED_EMPLOYEES = 'UPDATE_UPLOADED_EMPLOYEES'
export const EXPORT_EMAIL_EMPLOYEES_REQUEST = 'EXPORT_EMAIL_EMPLOYEES_REQUEST'

export const activateItem = createAction(ACTIVATE_ITEM, 'id')
export const appendEmployeesData = createAction(APPEND_EMPLOYEES_DATA, 'data')
export const removeEmployeeItem = createAction(REMOVE_EMPLOYEE_ITEM, 'id')
export const lockSubmit = createAction(LOCK_SUBMIT, 'value')
export const replaceEmployeesData = createAction(REPLACE_EMPLOYEES_DATA, 'data')
export const updateEmployeesRequest = createAction(UPDATE_EMPLOYEES_REQUEST)
export const updateSingleEmployee = createAction(UPDATE_SINGLE_EMPLOYEE, 'id', 'data')
export const updateUninvited = createAction(UPDATE_UNINVITED, 'data')
export const updateUploadedEmployees = createAction(UPDATE_UPLOADED_EMPLOYEES, 'data')
export const exportEmailEmployeesRequest = createAction(EXPORT_EMAIL_EMPLOYEES_REQUEST)
