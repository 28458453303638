import pluralize from 'pluralize'
import { Report } from './Report'

export default class OutlaysReport extends Report {
  constructor(data) {
    super(data)
  }

  calculateGraphs() {
    this.stats.cost_center = {}
    this.stats.supplier = {}
    this.usersTotals.cost_center = {}
    this.usersTotals.supplier = {}
    this.datesTotals.cost_center = {}
    this.datesTotals.supplier = {}

    const baseResult = super.calculateGraphs()

    const cost_centers = this.sortedTotals(this.stats.cost_center)
    const suppliers = this.sortedTotals(this.stats.supplier)

    const topCostCenters = this.generateProgress(cost_centers)
    const topSuppliers = this.generateProgress(suppliers)

    const bySupplier = this.grouped(suppliers, 'supplier')
    const byCostCenter = this.grouped(cost_centers, 'cost_center')

    return {
      ...baseResult,
      grouped: {
        ...baseResult.grouped,
        cost_centers: byCostCenter,
        suppliers: bySupplier
      },
      top: {
        ...baseResult.top,
        cost_centers: topCostCenters,
        suppliers: topSuppliers
      },
      csvs: {
        ...baseResult.csvs,
        cost_centers: this.generateCSV(byCostCenter),
        suppliers: this.generateCSV(bySupplier)
      },
      excelSheets: {
        ...baseResult.excelSheets,
        cost_centers: this.generateExcelData(byCostCenter),
        suppliers: this.generateExcelData(bySupplier),
      }
    }
  }

  calculateUsersTotals(item, demand, demandsTotal) {
    super.calculateUsersTotals(item, demand, demandsTotal)

    const { cost_center, supplier, user } = item

    if (supplier) this.calculateUsersTotalsFor(supplier.name, 'supplier', user, demand, demandsTotal)
    if (cost_center) this.calculateUsersTotalsFor(cost_center.name, 'cost_center', user, demand, demandsTotal)
  }

  calculateDateTotals(e, item, date, value) {
    super.calculateDateTotals(e, item, date, value)

    const { cost_center, supplier } = item
    const cost_centers = [cost_center.name]
    const suppliers = [supplier.name]

    if (supplier) this.calculateDateTotalsFor(suppliers, 'supplier', date, value)
    if (cost_center) this.calculateDateTotalsFor(cost_centers, 'cost_center', date, value)
  }
}
