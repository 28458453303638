/* eslint camelcase: 0 */

import {
  UPDATE_USER_FIELDS,
  UPDATE_USER_FIELD,
  UPDATE_USER_FROM_SERVER,
  UPDATE_USER_ERRORS
} from '../actions/user'

const initialState = {
  first_name: '',
  feature_notifications: [],
  nickname: '',
  id: '',
  email: '',
  password: '',
  password_confirmation: '',
  remember_me: false,
  status: 'inactive',
  company: {},
  errors: {},
  photo_url: '',
  tutorials: {}
}

export default function user(state = initialState, action) {
  switch(action.type) {
  case UPDATE_USER_FIELDS:
  case UPDATE_USER_FROM_SERVER:
    return {
      ...state,
      ...action.user
    }
  case UPDATE_USER_FIELD:
    return {
      ...state,
      [action.field]: action.data
    }
  case UPDATE_USER_ERRORS:
    return {
      ...state,
      errors: action.errors
    }
  default:
    return state
  }
}
