import { watchUserActions } from './user'
import { watchUserStatusUpdates } from './page'
import { watchModalUpdates } from './modal'
import { watchCompanyUpdates } from './company'
import { watchDemandsUpdates, watchDemandsSubtotalUpdates,
         watchDemandFiltersUpdates } from './demands'
import { watchDemandUpdates } from './demand'
import { watchFiltersSources } from './filters'
import { watchSupplierUpdates } from './supplier'
import { watchTeamsUpdates } from './teams'
import { watchTeamFiltersUpdates } from './teams'
import { watchTeamUpdates } from './team'
import { watchHandbooksUpdates } from './handbooks'
import { watchEmployeeUpdates } from './employee'
import { watchEmployeesUpdates, watchEmployeeFiltersUpdates } from './employees'
import { watchUnitsUpdates } from './units'
import { watchTimeoffsSettingsUpdates } from './timeoffsSettings'
import { watchOutlaysSettingsUpdates } from './outlaysSettings'
import { watchActivitiesSettingsUpdates } from './activitiesSettings'
import { watchRolesSettingsUpdates } from './rolesSettings'
import { watchDemandStatesUpdates } from './demandStates'
import { watchCustomFieldsUpdates } from './customFields'
import { watchPlansSettingsUpdates } from './plansSettings'
import { watchUserAttributesSettingsUpdates } from './userAttributesSettings'
import { watchReportsUpdates, watchReportsFiltersUpdates } from './reports'
import { watchTutorialUpdates } from './tutorial'
import { watchFeatureNotifications } from './featureNotifications'

export default function* rootSaga(context) {
  yield [
    watchFiltersSources(context),
    watchUserActions(context),
    watchUserStatusUpdates(context),
    watchModalUpdates(context),
    watchCompanyUpdates(context),
    watchDemandsUpdates(context),
    watchDemandsSubtotalUpdates(context),
    watchDemandUpdates(context),
    watchSupplierUpdates(context),
    watchDemandFiltersUpdates(context),
    watchTeamsUpdates(context),
    watchTeamUpdates(context),
    watchTeamFiltersUpdates(context),
    watchHandbooksUpdates(context),
    watchEmployeeUpdates(context),
    watchEmployeeFiltersUpdates(context),
    watchEmployeesUpdates(context),
    watchUnitsUpdates(context),
    watchTimeoffsSettingsUpdates(context),
    watchOutlaysSettingsUpdates(context),
    watchActivitiesSettingsUpdates(context),
    watchDemandStatesUpdates(context),
    watchCustomFieldsUpdates(context),
    watchRolesSettingsUpdates(context),
    watchPlansSettingsUpdates(context),
    watchUserAttributesSettingsUpdates(context),
    watchReportsUpdates(context),
    watchReportsFiltersUpdates(context),
    watchTutorialUpdates(context),
    watchFeatureNotifications(context)
  ]
}
