import { createAction } from '../lib/utils'

export const REGISTER_POPOVER = 'REGISTER_POPOVER'
export const POPOVER_TOGGLE = 'POPOVER_TOGGLE'
export const POPOVER_SHOW = 'POPOVER_SHOW'
export const POPOVER_HIDE = 'POPOVER_HIDE'

export const registerPopover = createAction(REGISTER_POPOVER, 'name')
export const popoverToggle = createAction(POPOVER_TOGGLE, 'name')
export const popoverShow = createAction(POPOVER_SHOW, 'name')
export const popoverHide = createAction(POPOVER_HIDE, 'name')
