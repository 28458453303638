import {
  GET_PLANS_SETTINGS_SUCCESS
} from '../actions/plansSettings'

const initialState = {
  credit_card: {},
  plans: {
    current_plan: {},
    current_cost: 0,
    recommended: null,
    users_count: 0,
    available_plans: [],
    avaliable_currencies: []
  }
}

export default function timeoffSettings(state = initialState, action) {
  switch(action.type) {
  case GET_PLANS_SETTINGS_SUCCESS:
    return {
      ...state,
      ...action.settings
    }
  default:
    return state
  }
}
