import { takeEvery } from 'redux-saga'
import {
  DELETE_HANDBOOK_REQUEST,
  GET_HANDBOOKS_REQUEST,
  getHandbooksRequest,
  UPDATE_HANDBOOK_REQUEST,
  updateHandbooks,
  GET_HANDBOOKS_SETTINGS_REQUEST,
  getHandbooksSettingsSuccess,
  UPDATE_HANDBOOKS_SETTINGS_REQUEST,
  UPLOAD_HANDBOOK_REQUEST
} from '../actions/handbooks'
import { call, fork, put, select } from 'redux-saga/effects'
import { loaderHide, loaderShow } from '../actions/loader'
import { handbookMapping, handbooksSettingsMapping } from '../api/mappings'
import { del, get, put as apiPut, post, makeUrl, upload } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import routes from '../api/routes'
import { isEmpty } from '../lib/utils'

const ROOT_URL = '/wiki'

function* fetchHandbooks({ router }, { url }) {
  const { collection } = yield call(get, url)

  yield put(updateHandbooks(collection))

  if (!isEmpty(collection) && location.pathname.endsWith(ROOT_URL)) {
    const firstPage = collection[0].page.url
    yield call([router, router.transitionTo], `${ROOT_URL}/${firstPage}`)
  }
}

function* saveHandbook({ router }, { page: newPage }) {
  const data = handbookMapping(newPage)
  const { page, errors } = newPage.id ?
                           yield call(apiPut, `${routes.handbook}/${newPage.url}`, data) :
                           yield call(post, routes.handbook, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Page saved')
    yield put(getHandbooksRequest(routes.handbook))
    yield call([router, router.transitionTo], `${ROOT_URL}/${page.url}`)
  }
}

function* deleteHandbook({ router }, page) {
  const deleteConfirmed = yield call(proceed)

  if (deleteConfirmed) {
    yield call(del, `${routes.handbook}/${page.url}`)
    yield put(getHandbooksRequest(routes.handbook))
    yield call([router, router.transitionTo], ROOT_URL)
  }
}

export function* getHandbooksSettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.handbooksSettings)
  const { settings } = yield call(get, url)
  yield put(getHandbooksSettingsSuccess(settings))
}

export function* updateHandbooksSettings({ settings: newSettings }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.handbooksSetting)
  const data = handbooksSettingsMapping(newSettings)
  const { errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Settings updated')
    window.location.reload()
  }
}

function* uploadHandbook({ file }) {
  yield put(loaderShow('uploadHandbook'))
  const host = yield select(getHost)
  const path = yield call(makeUrl, host, routes.handbooksSetting)
  const { errors } = yield call(upload, { path, file })
  yield put(loaderHide('uploadHandbook'))
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Document uploaded')
    window.location.reload()
  }
}

export function* watchHandbooksUpdates(context) {
  yield [
    takeEvery(GET_HANDBOOKS_REQUEST, fetchHandbooks, context),
    takeEvery(DELETE_HANDBOOK_REQUEST, deleteHandbook, context),
    takeEvery(UPDATE_HANDBOOK_REQUEST, saveHandbook, context),
    takeEvery(GET_HANDBOOKS_SETTINGS_REQUEST, getHandbooksSettings),
    takeEvery(UPDATE_HANDBOOKS_SETTINGS_REQUEST, updateHandbooksSettings),
    takeEvery(UPLOAD_HANDBOOK_REQUEST, uploadHandbook)
  ]
}
