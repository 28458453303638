import { createAction } from '../lib/utils'

export const UPDATE_TEAMS_REQUEST = 'UPDATE_TEAMS_REQUEST'
export const APPEND_TEAMS_DATA = 'APPEND_TEAMS_DATA'
export const REPLACE_TEAMS_DATA = 'REPLACE_TEAMS_DATA'
export const UPDATE_SINGLE_TEAM = 'UPDATE_SINGLE_TEAM'
export const DELETE_TEAM = 'DELETE_TEAM'

export const updateTeamsRequest = createAction(UPDATE_TEAMS_REQUEST)
export const appendTeamsData = createAction(APPEND_TEAMS_DATA, 'data')
export const replaceTeamsData = createAction(REPLACE_TEAMS_DATA, 'data')
export const updateSingleTeam = createAction(UPDATE_SINGLE_TEAM, 'id', 'data')
export const deleteTeam = createAction(DELETE_TEAM, 'id')
