import { takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, cancel } from 'redux-saga/effects'
import { get } from '../api/utils'
import {
  UPDATE_DEMANDS_REQUEST,
  UPDATE_DEMANDS_SUBTOTAL_REQUEST,
  APPEND_DEMANDS_DATA,
  REPLACE_DEMANDS_DATA,
  EXPORT_EMAIL_DEMANDS_REQUEST,
  EXPORT_DEMANDS_REQUEST,
  appendDemandsData,
  replaceDemandsData,
  updateDemandsItem,
  updateDemandsSubtotal
} from '../actions/demands'
import {
  UPDATE_DEMAND,
  CREATE_DEMAND_REQUEST,
  CHANGE_DEMAND_REQUEST,
  CHANGE_DEMAND_STATE
} from '../actions/demand'
import { ACTIVATE_FILTER, DEACTIVATE_FILTER } from '../actions/filters'
import {
  fetchCollection,
  fetchSubtotal,
  getPage,
  requestDelayedDownload,
  showFlash,
  updateFilters
} from './utils'

const CONTROLLERS_WITH_SUBTOTAL = ['activities', 'expenses', 'purchases', 'time_offs', 'time off']
const DEMAND_CONTROLLERS = ['dashboard', 'expenses', 'purchases', 'time_offs', 'activities', 'schedules']

export const getDemand = state => state.demand
export const getDemands = state => state.demands

export function* refreshSubtotal() {
  const totals = yield call(fetchSubtotal)
  yield put(updateDemandsSubtotal(totals))
}

export function* fetchAndAppend() {
  const demandsData = yield call(fetchCollection)
  yield put(appendDemandsData(demandsData))
}

export function* fetchAndSilentlyReplace() {
  const demandsData = yield call(fetchCollection)
  yield put(replaceDemandsData(demandsData))
}

export function* fetchAndReplace({ timeout = 0 } = {}) {
  // delay makes this job cancelable
  yield call(delay, timeout)
  const page = yield select(getPage)
  if (CONTROLLERS_WITH_SUBTOTAL.includes(page)) {
    yield fork(refreshSubtotal)
  }
  yield put(replaceDemandsData({ collection: [], filters: {}, activeId: null }))
  const demandsData = yield call(fetchCollection)
  yield put(replaceDemandsData(demandsData))
}

// Prevent double ajax request
// when activating and deactivating filters
// in short period of time
let fetchAndReplaceJob

export function* startFetchAndReplace() {
  if (fetchAndReplaceJob) {
    yield cancel(fetchAndReplaceJob)
  }
  fetchAndReplaceJob = yield fork(fetchAndReplace, { timeout: 700 })
}

export function* filterChanged({ name }) {
  if (['demand_state', 'search'].includes(name)) {
    yield call(delay, 700)
  }
  if (name === 'page') {
    yield fork(fetchAndAppend)
  } else {
    yield call(startFetchAndReplace)
  }
}

export function* filterDeactivated({ name }) {
  if (name !== 'page') {
    yield call(startFetchAndReplace)
  }
}

export function* singleDemandChanged({ data }) {
  const demand = yield select(getDemand)

  yield put(updateDemandsItem(demand.id, data))
}

export function* tryUpdateSchedule() {
  const demands = yield select(getDemands)

  // We need this to happen after demand update
  // so here goes the timeout
  if (demands.schedule && demands.schedule.users.length > 0) {
    yield fork(fetchAndReplace, { timeout: 500 })
  }
}

export function* watchDemandsUpdates() {
  yield [
    takeLatest(UPDATE_DEMANDS_REQUEST, fetchAndSilentlyReplace),
    takeLatest(UPDATE_DEMAND, singleDemandChanged),

    takeLatest(APPEND_DEMANDS_DATA, updateFilters),
    takeLatest(REPLACE_DEMANDS_DATA, updateFilters),

    takeLatest(CHANGE_DEMAND_REQUEST, tryUpdateSchedule),
    takeLatest(CHANGE_DEMAND_STATE, tryUpdateSchedule),
    takeLatest(CREATE_DEMAND_REQUEST, tryUpdateSchedule),

    takeLatest(EXPORT_EMAIL_DEMANDS_REQUEST, requestDelayedDownload),
    takeLatest(EXPORT_DEMANDS_REQUEST, requestDelayedDownload)
  ]
}

export function* watchDemandsSubtotalUpdates(context) {
  if (context.request && CONTROLLERS_WITH_SUBTOTAL.includes(context.request.react_controller)) {
    yield [
      takeLatest(UPDATE_DEMANDS_SUBTOTAL_REQUEST, refreshSubtotal)
    ]
  }
}

export function* watchDemandFiltersUpdates(context) {
  if (context.request && DEMAND_CONTROLLERS.includes(context.request.react_controller)) {
    yield [
      takeLatest(ACTIVATE_FILTER, filterChanged),
      takeLatest(DEACTIVATE_FILTER, filterDeactivated)
    ]
  }
}
