/* eslint camelcase: 0 */

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getParams } from '../../lib/utils'
import Asset from '../shared/Asset'
import { statusRequest, updateFields } from '../../actions/user'
import { updatePlan, updateCompany } from '../../actions/company'
import { updatePage, PAGES } from '../../actions/page'

import { flashShow, flashHide } from '../../actions/flash'
import Flash from '../shared/Layout/Flash'

import ViewportSensor from '../shared/Responsive/ViewportSensor'

import InvitationEdit from './InvitationEdit/Index'
import SignIn from './SignIn/Index'
import SignInWithOtp from './SignInWithOtp/Index'
import SignUp from './SignUp/Index'
import SiteName from './SiteName/Index'
import Password from './Password/Index'
import PasswordEdit from './PasswordEdit/Index'
import PasswordSuccess from './PasswordSuccess/Index'
import ContinueSession from './ContinueSession/Index'
import SetupAccount from './SetupAccount/Index'

const mapStateToProps = (state) => {
  return {
    currentPage: state.page,
    company: state.company,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    flashShow,
    flashHide,
    statusRequest,
    updateCompany,
    updateFields,
    updatePage,
    updatePlan
  }, dispatch)
)

export class Authentication extends Component {
  static propTypes = {
    currentPage: PropTypes.string.isRequired,
    invitation_token: PropTypes.string,
    displayLogo: PropTypes.bool,
    flashShow: PropTypes.func.isRequired,
    flashHide: PropTypes.func.isRequired,
    page: PropTypes.string,
    promoCode: PropTypes.string,
    plan: PropTypes.string,
    reset_password_token: PropTypes.string,
    statusRequest: PropTypes.func.isRequired,
    updateCompany: PropTypes.func.isRequired,
    updateFields: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    updatePlan: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  static defaultProps = {
    displayLogo: true
  }

  componentWillMount() {
    const {
      currentPage,
      flashShow,
      flashHide,
      invitation_token,
      statusRequest,
      user,
      updateCompany,
      updateFields,
      updatePage,
      updatePlan,
      page,
      plan,
      promoCode,
      reset_password_token
    } = this.props

    if (typeof window !== 'undefined' && window.pendingFlashMessages) {
      while (window.pendingFlashMessages.length) {
        const { type, text } = window.pendingFlashMessages.pop()
        flashShow(type === 'alert' ? 'error' : 'success', text)
      }
      setTimeout(flashHide, 5000)
    }

    if (page) {
      updatePage(page)
    }

    if (plan) {
      updatePlan(plan)
    }

    if (invitation_token) {
      updateFields({ ...user, invitation_token })
    }

    if (reset_password_token) {
      updateFields({ reset_password_token })
    }

    if (user && user.status === 'active' && currentPage !== PAGES.PASSWORD_EDIT) {
      updatePage(PAGES.CONTINUE_SESSION)
    }

    updateCompany({ promo_code: promoCode || getParams()['promo_code'] })
    statusRequest()
  }

  dispatchPage() {
    const { currentPage, company: { promo_code } } = this.props

    switch (currentPage) {
    case PAGES.INVITATION_EDIT:
      return <InvitationEdit/>
    case PAGES.SIGN_IN:
      return promo_code == null ? <SignIn/> : <SiteName promoCode={promo_code}/>
    case PAGES.SIGN_IN_WITH_OTP:
      return <SignInWithOtp/>
    case PAGES.SIGN_UP:
      return <SignUp/>
    case PAGES.SITE_NAME:
      return <SiteName promoCode={promo_code}/>
    case PAGES.PASSWORD:
      return <Password/>
    case PAGES.PASSWORD_SUCCESS:
      return <PasswordSuccess/>
    case PAGES.PASSWORD_EDIT:
      return <PasswordEdit/>
    case PAGES.CONTINUE_SESSION:
      return <ContinueSession/>
    case PAGES.SETUP_ACCOUNT:
      return <SetupAccount/>
    default:
      return <SignIn/>
    }
  }

  render() {
    const { displayLogo } = this.props

    return (
      <div>
        <Flash />
        <ViewportSensor/>

        {displayLogo ?
          <div className='b-logo b-logo_center'>
            <Asset src='/assets/turbine-dark.svg'>
              <img className='b-logo__image'/>
            </Asset>
          </div> : null
        }

        {this.dispatchPage()}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)
