import routes from './routes'
import { post, makeUrl } from './utils'

export const validateSiteName = (host, company) => (
  post(makeUrl(host, routes.validateSiteName), company)
)

export const removePromoCode = (host) => (
  post(makeUrl(host, routes.removePromoCode))
)

export const applyPromoCode = (host, company) => (
  post(makeUrl(host, routes.applyPromoCode), company)
)

export const updateHost = (host, companyName) => (
  host.replace(/^(http\:\/\/|https\:\/\/)(\w+)/, (_str, schema) => `${schema}${companyName}`)
)
