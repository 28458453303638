/* eslint camelcase: 0 */

import yup from 'yup'
import { STRING_MAX_LENGTH, CHARS_MESSAGE_ERROR, isUnicodeStringValid } from './lib'
import countries from '../lib/countries'

export const countryCodes = countries.map(c => c.id)

const titleSchema = yup
  .string()
  .matches(/^[0-9A-Za-z][0-9A-Za-z\-]*$/, 'Should contain only letters, numbers and hyphen')

export const createCompanySchema = yup.object({
  site_name: titleSchema,
  promo_code: yup.string().nullable()
})

export const editCompanySchema = yup.object({
  site_name: titleSchema,
  name: yup.string().test('name', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)),
  phone_number: yup.string()
    .test('phone_number', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
  address_attributes: yup.object().shape({
    address1: yup.string()
      .test('address1', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    address2: yup.string()
      .test('address2', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    state: yup.string()
      .test('state', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    city: yup.string()
      .test('city', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    postal_code: yup.string()
      .test('postal_code', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    country_code: yup.string().oneOf(countryCodes)
  }),
  config_attributes: yup.object().shape({
    show_logo: yup.boolean(),
    hide_demands_before: yup.string().nullable(),
    show_company_name: yup.boolean(),
    currency: yup.string(),
    time_zone: yup.string().nullable(),
    datetime_format: yup.string()
  })
})
