import React, { Component } from 'react'
import SignUpForm from './Form'

class SignUp extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Almost there!
          </h2>
          <div className='b-title__subtitle b-preline'>
            {`Set email and password for your account or use
Social network account.`}
          </div>
        </div>

        <SignUpForm />
      </div>
    )
  }
}

export default SignUp
