import { UPDATE_SUPPLIER } from '../actions/supplier'

export const initialState = {
  name: '',
  email: '',
  contact_email: '',
  country_codes: [],
  phone_number: '',
  address_attributes: {
    address1: '',
    address2: '',
    country_code: {
      id: -1,
      name: ''
    },
    city: '',
    state: '',
    postal_code: ''
  }
}

export default function supplier(state = initialState, action) {
  switch(action.type) {
  case UPDATE_SUPPLIER:
    return { ...state, ...action.value }
  default:
    return state
  }
}
