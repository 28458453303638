import { takeLatest } from 'redux-saga'
import { select, call, put } from 'redux-saga/effects'
import routes from '../api/routes'
import { makeUrl, put as apiPut } from '../api/utils'
import { TUTORIAL_FINISH_REQUEST } from '../actions/tutorial'
import { getHost, getUser, getPage } from './utils'

export function* finish() {
  const host = yield select(getHost)
  const page = yield select(getPage)
  const user = yield select(getUser)
  const id = user.tutorials[page].id
  const url = makeUrl(host, routes.tutorials, { id })

  yield call(apiPut, url)
}

export function* watchTutorialUpdates() {
  yield takeLatest(TUTORIAL_FINISH_REQUEST, finish)
}
