import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Viewport from './Responsive/Viewport'
import { Link } from './Block'

export default class SocialLinks extends Component {
  static propTypes = {
    endpoints: PropTypes.object.isRequired,
    page: PropTypes.string
  }

  get label() {
    const { page } = this.props
    return page === 'sign_in' ? 'Sign in' : 'Sign up'
  }

  render() {
    const { endpoints } = this.props

    return (
      <div className='b-social-links'>
        <Viewport only={['mobile', 'mobile-narrow']}>
          <div className='b-panel__delimiter b-delimiter'>
            <span className='b-delimiter__inner b-delimiter__inner_white'>
              or { this.label } with
            </span>
          </div>
        </Viewport>

        <div>
          <Link
            href={endpoints.google}
            modifiers='b-social-links__link b-social-links__link_google'
          >
            { this.label } with Google
          </Link>
        </div>

        <div>
          <Link
            href={endpoints.linkedin}
            modifiers='b-social-links__link b-social-links__link_linkedin'
          >
            { this.label } with LinkedIn
          </Link>
        </div>

        <div>
          <Link
            href={endpoints.office365}
            modifiers='b-social-links__link b-social-links__link_office365'
          >
            { this.label } with Office 365
          </Link>
        </div>
      </div>
    )
  }
}
