import pluralize from 'pluralize'
import { Report } from './Report'

export default class ActivitiesReport extends Report {
  constructor(data) {
    super(data)
  }

  calculateGraphs() {
    this.selectedClients = this.getSelected('clients_multi')
    this.selectedCategories = this.getSelected('categories_multi')
    this.stats.client = {}
    this.stats.point = {}
    this.stats.category = {}
    this.usersTotals.client = {}
    this.usersTotals.point = {}
    this.usersTotals.category = {}
    this.datesTotals.client = {}
    this.datesTotals.point = {}
    this.datesTotals.category = {}

    const baseResult = super.calculateGraphs()

    const clients = this.sortedTotals(this.stats.client)
    const points = this.sortedTotals(this.stats.point)
    const categories = this.sortedTotals(this.stats.category)

    const topClients = this.generateProgress(clients)
    const topPoints = this.generateProgress(points)
    const topCategories = this.generateProgress(categories)

    const byPoint = this.grouped(points, 'point')
    const byCategory = this.grouped(categories, 'category')
    const byClient = this.grouped(clients, 'client')

    return {
      ...baseResult,
      grouped: {
        ...baseResult.grouped,
        clients: byClient,
        points: byPoint,
        categories: byCategory
      },
      top: {
        ...baseResult.top,
        clients: topClients,
        points: topPoints,
        categories: topCategories
      },
      csvs: {
        ...baseResult.csvs,
        clients: this.generateCSV(byClient),
        points: this.generateCSV(byPoint),
        categories: this.generateCSV(byCategory)
      },
      excelSheets: {
        ...baseResult.excelSheets,
        clients: this.generateExcelData(byClient),
        points: this.generateExcelData(byPoint),
        categories: this.generateExcelData(byCategory),
      }
    }
  }

  calculateUsersTotals(item, demand, demandsTotal) {
    super.calculateUsersTotals(item, demand, demandsTotal)

    const { client, point, category, user } = item

    if (point) this.calculateUsersTotalsFor(point.name, 'point', user, demand, demandsTotal)
    if (category) this.calculateUsersTotalsFor(category.name, 'category', user, demand, demandsTotal)
    if (client) this.calculateUsersTotalsFor(client.name, 'client', user, demand, demandsTotal)
  }

  calculateDateTotals(e, item, date, value) {
    super.calculateDateTotals(e, item, date, value)

    const { client, point, category } = item
    const clients = [client.name]
    const points = [point.name]
    const categories = [category.name]
    const approvedClients = this.selectedClients ? clients.filter(t => this.selectedClients.includes(t)) : clients
    const approvedPoints = points
    const approvedCategories = this.selectedCategories ? categories.filter(t => this.selectedCategories.includes(t)) : categories

    if (point) this.calculateDateTotalsFor(approvedPoints, 'point', date, value)
    if (category) this.calculateDateTotalsFor(approvedCategories, 'category', date, value)
    if (client) this.calculateDateTotalsFor(approvedClients, 'client', date, value)
  }

  subtotalTitle = total => pluralize('Point', total)

  formatValue = v => v
}
