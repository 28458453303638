import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updatePage, PAGES } from '../../../actions/page'
import { signUpRequest } from '../../../actions/user'
import UserForm from '../../shared/UserForm'
import SubmitButton from '../../shared/SubmitButton'
import { Column, Link } from '../../shared/Block'

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ signUpRequest, updatePage }, dispatch)
)

class SignUpForm extends Component {
  static propTypes = {
    signUpRequest: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired
  }

  render() {
    const { signUpRequest, updatePage } = this.props

    return (
      <UserForm onSubmit={signUpRequest}>
        <Column modifiers='authentication__margin-top authentication__terms'>
          <div className='authentication__terms-text'>
            { "You're starting a 30-day free trial. You can upgrade, downgrade or cancel at any time. By clicking 'Begin free trial' you agree to our " }

            <Link target='_blank' href='https://turbinehq.com/terms-and-conditions/'>
              terms and conditions
            </Link>.
          </div>
        </Column>

        <Column
          modifiers='b-columns__column_with-margin authentication__margin-top authentication__back'
         >
          <a
            className='b-button b-button_link'
            onClick={() => updatePage(PAGES.SITE_NAME)}
          >back</a>
        </Column>

        <Column modifiers='authentication__margin-top authentication__submit'>
          <SubmitButton
            className='b-button b-button_primary'
          >Begin free trial</SubmitButton>
        </Column>
      </UserForm>
    )
  }
}

export default connect(() => ({}), mapDispatchToProps)(SignUpForm)
