import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Form from 'react-formal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateFields, updateErrors, updatePasswordRequest } from '../../../actions/user'
import { editPasswordSchema } from '../../../schemas/user'
import SubmitButton from '../../shared/SubmitButton'

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    updateFields,
    updateErrors,
    updatePasswordRequest
  }, dispatch)
)

class ResetPassword extends Component {
  static propTypes = {
    updateFields: PropTypes.func.isRequired,
    updateErrors: PropTypes.func.isRequired,
    updatePasswordRequest: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  render() {
    const {
      user,
      updateErrors,
      updateFields,
      updatePasswordRequest
    } = this.props

    return (
      <Form
        className='b-form'
        defaultValue={user}
        errors={user.errors}
        onError={updateErrors}
        onChange={updateFields}
        onSubmit={updatePasswordRequest}
        schema={editPasswordSchema}
      >
        <div className='b-form__input-group'>
          <Form.Message errorClass='b-form__validation-error' for={['password']}/>
          <Form.Field
            className='b-form__input'
            errorClass='b-form__input_validation-error'
            id='password'
            name='password'
            placeholder='Password'
            type='password'
            value={user.password}
          />
        </div>

        <div className='b-form__input-group'>
          <Form.Message errorClass='b-form__validation-error' for={['password_confirmation']}/>
          <Form.Field
            className='b-form__input'
            errorClass='b-form__input_validation-error'
            events={['onBlur']}
            id='password_confirmation'
            name='password_confirmation'
            placeholder='Password confirmation'
            type='password'
            value={user.password_confirmation}
          />
        </div>

        <div className='authentication__buttons'>
          <SubmitButton
            className='b-button b-button_primary'
          >Submit</SubmitButton>
        </div>
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
