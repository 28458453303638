/* eslint camelcase: 0 */

import { lens } from 'lorgnette'
import {
  UPDATE_FILTER,
  RESET_FILTER,
  ACTIVATE_FILTER,
  DEACTIVATE_FILTER
} from '../actions/filters'

export const initialState = {
  page: {
    type: 'conditional',
    param: 'page',
    value: 1,
    active: false
  },
  user_id: {
    type: 'conditional',
    param: 'user_id',
    value: null,
    active: false
  },
  show: {
    type: 'conditional',
    param: 'show',
    value: null,
    active: false
  },
  search: {
    type: 'conditional',
    param: 'search',
    value: '',
    active: false
  },
  only_my: {
    type: 'boolean',
    param: 'only_my',
    value: false,
    active: false
  },
  show_invited: {
    type: 'boolean',
    param: 'show_invited',
    value: false,
    active: false
  },
  show_ex_employee: {
    type: 'boolean',
    param: 'show_ex_employee',
    value: false,
    active: false
  },
  show_regular: {
    type: 'boolean',
    param: 'show_regular',
    value: false,
    active: false
  },
  show_uninvited: {
    type: 'boolean',
    param: 'show_uninvited',
    value: false,
    active: false
  },
  demand_state: {
    type: 'multi_boolean',
    title: 'Status',
    param: 'demand_state',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  clients: {
    type: 'conditional',
    title: 'Clients',
    param: 'client',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  clients_multi: {
    type: 'multi_boolean',
    title: 'Clients',
    param: 'clients',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  categories: {
    type: 'conditional',
    title: 'Categories',
    param: 'category',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  categories_multi: {
    type: 'multi_boolean',
    title: 'Categories',
    param: 'categories',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  cost_centers: {
    type: 'conditional',
    title: 'Cost Centers',
    param: 'cost_center',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  suppliers: {
    type: 'conditional',
    title: 'Suppliers',
    param: 'supplier',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  teams: {
    type: 'conditional',
    title: 'Teams',
    param: 'team',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  teams_multi: {
    type: 'multi_boolean',
    title: 'Teams',
    param: 'teams',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  period: {
    type: 'conditional',
    title: 'Periods',
    param: 'period',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  custom_period: {
    type: 'dictionary',
    param: 'custom_period',
    value: {},
    active: false,
    visible: false
  },
  year: {
    type: 'conditional',
    title: 'Year',
    param: 'year',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  sort_by: {
    type: 'conditional',
    title: 'Sort by',
    param: 'ordered_by',
    value: '',
    values: [],
    active: false,
    visible: false
  },
  group_by: {
    type: 'conditional',
    title: 'Group by',
    param: 'group_by',
    value: 'teams',
    values: [],
    active: false,
    visible: false
  },
  manager: {
    type: 'conditional',
    title: 'Managers',
    param: 'manager',
    value: '',
    values: [],
    active: false,
    visible: false
  },
  type: {
    type: 'conditional',
    title: 'Type',
    param: 'type',
    value: '',
    values: [],
    active: false,
    visible: false
  },
  type_multi: {
    type: 'multi_boolean',
    title: 'Type',
    param: 'types',
    value: [],
    values: [],
    active: false,
    visible: false
  },
  feature: {
    type: 'conditional',
    title: 'Feature',
    param: 'feature',
    value: '',
    values: [],
    active: false,
    visible: false
  },
  attachments: {
    type: 'conditional',
    title: 'Attachments',
    param: 'attachments',
    value: [],
    values: [],
    active: false,
    visible: false
  }
}

const filterLens = (name) => lens.prop(name)
const activeFilterLens = (name) => lens.prop(name).prop('active')

export default function page(state = initialState, action) {
  switch (action.type) {
  case UPDATE_FILTER:
    return filterLens(action.name).update(state, oldFilter => ({ ...oldFilter, ...action.data }))
  case RESET_FILTER:
    return filterLens(action.name).set(state, initialState[action.name])
  case ACTIVATE_FILTER:
    return activeFilterLens(action.name).set(state, true)
  case DEACTIVATE_FILTER:
    return activeFilterLens(action.name).set(state, false)
  default:
    return state
  }
}
