import { UPDATE_PAGE } from '../actions/page'

const initialState = ''

export default function page(state = initialState, action) {
  switch (action.type) {
  case UPDATE_PAGE:
    return action.page
  default:
    return state
  }
}
