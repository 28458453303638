import { takeEvery } from 'redux-saga'
import { put, select } from 'redux-saga/effects'
import { UPDATE_USER_FROM_SERVER } from '../actions/user'
import { PAGES, updatePage } from '../actions/page'

const getCurrentPage = (state) => state.page

export function *updateAuthenticationPage({user}) {
  if (user.status === 'active') {
    let page = yield select(getCurrentPage)

    if (page !== PAGES.PASSWORD_EDIT) {
      yield put(updatePage(PAGES.CONTINUE_SESSION))
    }
  }
}

export function *watchUserStatusUpdates() {
  yield [
    takeEvery(UPDATE_USER_FROM_SERVER, updateAuthenticationPage)
  ]
}
