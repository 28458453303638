import React, { Component } from 'react'
import Loading from '../../shared/svg/Loading'

class PasswordSuccess extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Please check your inbox
          </h2>
          <div className='b-title__subtitle b-title__subtitle_margin-top b-preline'>
            {`We’ve sent the email with instructions about
how to reset your password.

If you don't see the email in a few minutes,
please check your spam folder.`}
          </div>
        </div>

        <div className='b-center'>
          <Loading />
        </div>
      </div>
    )
  }
}

export default PasswordSuccess
