import { UPDATE_ENDPOINTS } from '../actions/endpoints'

const initialState = {
  providers: {},
  settings: {},
  userSettings: {}
}

export default function user(state = initialState, action) {
  switch (action.type) {
  case UPDATE_ENDPOINTS:
    return {
      ...state,
      ...action.endpoints
    }
  default:
    return state
  }
}
