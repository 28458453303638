import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { isServer } from '../lib/utils'
import sagas from '../sagas'
import reducers from '../reducers'

export default function configureStore(initialState, context = {}) {
  const sagaMiddleware = createSagaMiddleware()
  const rootReducer = combineReducers(reducers)
  const preparedState = {}

  if (initialState) {
    Object.keys(reducers).forEach((key) => {
      if (initialState.hasOwnProperty(key)) {
        preparedState[key] = initialState[key]
      }
    })
  }

  const store = compose(
    applyMiddleware(sagaMiddleware),
    global.devToolsExtension ? window.devToolsExtension() : f => f
  )(createStore)(rootReducer, preparedState)

  // Do not run sagas during server side rendering
  // Prevent fetch errors
  if (!isServer()) {
    sagaMiddleware.run(sagas, context)
  }

  return store
}
