/* eslint camelcase: 0 */

import yup from 'yup'
import {
  STRING_MAX_LENGTH,
  CHARS_MESSAGE_ERROR,
  REQUIRED_MESSAGE_ERROR,
  isUnicodeStringValid
} from './lib'

import { customField } from './customField'
import { countryCodes } from './company'

const DATE_REGEXP = /^\d{4}-\d{2}-\d{2}$/

export const employeeSchema = yup.object().shape({
  base: yup.string().nullable(),
  first_name: yup.string()
    .test('first_name', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
  last_name: yup.string()
    .test('last_name', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
  date_of_birth: yup.string().matches(DATE_REGEXP, ' ').nullable(),
  job_title: yup.string()
    .test('job_title', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
  email: yup.string()
    .test('email', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH))
    .nullable().required(REQUIRED_MESSAGE_ERROR),
  phone_number: yup.string()
    .test('phone_number', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
  personal_email_address: yup.string()
    .test('personal_email_address', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH))
    .nullable(),
  joined_at: yup.string().matches(DATE_REGEXP, ' ').nullable(),
  address: yup.object().shape({
    address1: yup.string()
      .test('address1', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    address2: yup.string()
      .test('address2', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    city: yup.string()
      .test('city', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    state: yup.string()
      .test('state', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    postal_code: yup.string()
      .test('postal_code', CHARS_MESSAGE_ERROR, isUnicodeStringValid(STRING_MAX_LENGTH)).nullable(),
    country_code: yup.string().oneOf(countryCodes, REQUIRED_MESSAGE_ERROR).nullable()
  }),
  blackout_config_id: yup.number().nullable(),
  config_id: yup.number().nullable(),
  notes: yup.string().nullable(),
  public_notes: yup.string().nullable(),
  values_attributes: yup.array().of(customField)
})

export const otpSchema = yup.object({
  otp_attempt: yup.string()
    .matches(/^\d{6}$/, 'Only 6 digits')
    .required(`Code ${REQUIRED_MESSAGE_ERROR}`)
})
