import {
  serialize,
  deserialize,
  isNumeric
} from './utils'

const serializers = {
  conditional: (f) => serialize({ [f.param]: f.value }),
  boolean: (f) => serialize({ [f.param]: (f.value ? 'on' : 'off') }),
  multi_boolean: (f, options) => {
    let valueToSerialize

    if (f.value.length > 0) {
      valueToSerialize = { [f.param]: f.value }
    } else if (options.replaceEmpty) {
      valueToSerialize = { [f.param]: [options.replaceEmpty] }
    } else {
      valueToSerialize = null
    }

    return serialize(valueToSerialize)
  },
  dictionary: ({ param, value }) => serialize({ [param]: value })
}

const deserializers = {
  boolean: (v, f) => ({ ...f, active: true, value: v === 'on' }),
  conditional: (v, f) => ({
    ...f,
    active: true,
    value: (isNumeric(v) ? Number(v) : v)
  }),
  multi_boolean: (v, f, options) => {
    const value = v.filter(i => i !== options.replaceEmpty)
    return { ...f, value, active: true }
  },
  dictionary: (v, f) => ({ ...f, value: v, active: true })
}

export const filtersToParams = (filters, options = {}) => {
  const params = Object.keys(filters)
    .map(k => filters[k])
    .filter(f => f.active)
    .filter(f => !options.ignore || !options.ignore.includes(f.param))
    .map(f => serializers[f.type](f, options))

  return (options.userId ? [...params, `user_id=${options.userId}`] : params)
    .join('&')
}

export const paramsToFilters = (query, filters, options = {}) => {
  const params = deserialize(query)

  return Object.keys(filters)
    .map(k => [k, filters[k]])
    .map(([k, v]) => {
      return params[v.param] ?
        [k, deserializers[v.type](params[v.param], v, options)] :
        [k, v]
    })
    .reduce((acc, [k, v]) => {
      acc[k] = v
      return acc
    }, {})
}
