import { takeEvery } from 'redux-saga'
import { select, call } from 'redux-saga/effects'
import { MODAL_SHOW, MODAL_HIDE } from '../actions/modal'

const getBlur = (state) => state.modal.blur

export function *addBlurEffect() {
  const blur = yield select(getBlur)
  if (blur) {
    yield call(() => document.body.classList.add('b-modal_blur'))
  }
}

export function *removeBlurEffect() {
  yield call(() => document.body.classList.remove('b-modal_blur'))
}

export function *addNoscroll() {
  yield call(() => document.body.classList.add('b-modal_no-scroll'))
}

export function *removeNoscroll() {
  yield call(() => document.body.classList.remove('b-modal_no-scroll'))
}

export function *watchModalUpdates() {
  yield [
    takeEvery(MODAL_SHOW, addBlurEffect),
    takeEvery(MODAL_HIDE, removeBlurEffect),
    takeEvery(MODAL_SHOW, addNoscroll),
    takeEvery(MODAL_HIDE, removeNoscroll)
  ]
}
