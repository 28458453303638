import { MODAL_SHOW, MODAL_HIDE } from '../actions/modal'

const initialState = {
  blur: false,
  show: false,
  content: null
}

export default (state = initialState, action) => {
  switch (action.type) {
  case MODAL_SHOW:
    return {
      ...state,
      show: true,
      content: action.content
    }
  case MODAL_HIDE:
    return initialState
  default:
    return state
  }
}
