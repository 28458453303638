import pluralize from 'pluralize'
import { Report } from './Report'

export default class TimeOffsReport extends Report {
  constructor(data) {
    super(data)
  }

  calculateGraphs() {
    this.stats.type = {}
    this.usersTotals.type = {}
    this.datesTotals.type = {}

    const baseResult = super.calculateGraphs()

    const types = this.sortedTotals(this.stats.type)
    const topTypes = this.generateProgress(types)
    const byType = this.grouped(types, 'type')

    return {
      ...baseResult,
      grouped: {
        ...baseResult.grouped,
        types: byType,
      },
      top: {
        ...baseResult.top,
        types: topTypes
      },
      csvs: {
        ...baseResult.csvs,
        types: this.generateCSV(byType)
      },
      excelSheets: {
        ...baseResult.excelSheets,
        types: this.generateExcelData(byType)
      }
    }
  }

  calculateUsersTotals(item, demand, demandsTotal) {
    super.calculateUsersTotals(item, demand, demandsTotal)

    const { user, type } = item

    if (type) this.calculateUsersTotalsFor(type, 'type', user, demand, demandsTotal)
  }

  calculateDateTotals(e, item, date, value) {
    super.calculateDateTotals(e, item, date, value)

    const { type } = item

    this.calculateDateTotalsFor([type], 'type', date, value)
  }

  subtotalTitle = total => pluralize('Time Off', total)

  formatValue = v => v
}
