import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export const yearDaysRange = year => {
  const start = moment([year]).startOf('year')
  const end = moment([year]).endOf('year')

  return moment.range(start, end)
}

export const daysInMonth = (month, year) => (
  moment(`${year}-${month}`, 'YYYY-M').daysInMonth()
)

export const monthsOffsets = year => (
  Array.from({ length: 12 })
    .map((_, i) => daysInMonth(i + 1, year))
    .reduce((acc, days, i) => (
      [...acc, acc[i] + days]
    ), [0])
)

export const dayOfTheYear = date => {
  const dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334]
  const mn = date.getMonth()
  const dn = date.getDate()
  let dayOfYear = dayCount[mn] + dn

  if (mn > 1 && moment(date).isLeapYear()) {
    dayOfYear = dayOfYear + 1
  }

  return dayOfYear
}

export const convertOldDate = dateString => {
  const r = /^\d{2}\/\d{2}\/\d{4}$/

  return r.test(dateString) ? moment(dateString, 'DD/MM/YYYY').toDate() : new Date(dateString)
}

export const isLeapYear = year => year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)

export const daysInYear = year => isLeapYear(year) ? 366 : 365

export const dateFromDay = (year, day) => {
  const date = new Date(year, 0)
  return new Date(date.setDate(day))
}

const dateFormatRubyToJS = {
  '%m/%d/%Y': 'MM/DD/YYYY',
  '%d/%m/%Y': 'DD/MM/YYYY',
  '%d/%m/%y': 'DD/MM/YY',
  '%d %b %Y': 'DD MMM YYYY',
  '%Y %b %d': 'YYYY MMM DD',
  '%A %d %B %Y': 'dddd DD MMMM YYYY',
  '%Y/%d/%m': 'YYYY/DD/MM',
  '%Y-%d-%m': 'YYYY-DD-MM',
  '%Y-%m-%d': 'YYYY-MM-DD'
}

export const convertRubyDateFormat = rubyFormat => (
  dateFormatRubyToJS[rubyFormat] || 'DD/MM/YYYY'
)
