import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { redirect } from '../api/utils'
import { isServer } from '../lib/utils'
import configureStore from '../store/configureStore'

const currentPage = () => isServer() ? '' : window.location.pathname

let lastPage = currentPage()

class BaseApp extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    router: PropTypes.object,
    user: PropTypes.object,
    company: PropTypes.object,
    ga_tracker_app: PropTypes.string
  }

  constructor(props) {
    super(props)
    const { requested = {}, user, company = {} } = props

    if (company.expired) {
      if (user.payer || user.finance_payer) {
        if (requested.controller !== 'api/v1/admin/settings/plans') {
          redirect('/expired_payer')
        }
      } else {
        redirect('/expired')
      }
    }
  }

  componentWillMount() {
    const { requested, router, user, ga_tracker_app } = this.props

    this.store = configureStore(this.props, {
      router,
      request: requested
    })

    if (!isServer() && ga_tracker_app) {
      ReactGA.initialize(ga_tracker_app)
      user && ReactGA.set({ userId: user.id })
      ReactGA.pageview(lastPage)
    }

  }

  componentDidUpdate({ ga_tracker_app }) {
    if (isServer() || !ga_tracker_app) {
      return
    }
    const newPage = currentPage()

    if (lastPage !== newPage) {
      ReactGA.pageview(newPage)
      lastPage = newPage
    }
  }

  render() {
    const { children } = this.props
    return <Provider store={this.store} children={children} />
  }
}

export default BaseApp
