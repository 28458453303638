import { createAction } from '../lib/utils'

export const ADD_EMPLOYEE_ATTACHMENT_REQUEST = 'ADD_EMPLOYEE_ATTACHMENT_REQUEST'
export const ADD_EMPLOYEE_DAYS_REQUEST = 'ADD_EMPLOYEE_DAYS_REQUEST'
export const ADD_EMPLOYEE_TO_TEAM_REQUEST = 'ADD_EMPLOYEE_TO_TEAM_REQUEST'
export const CONFIRM_EMPLOYEES_REQUEST = 'CONFIRM_EMPLOYEES_REQUEST'
export const CONNECT_EMPLOYEE_ACCOUNT_REQUEST = 'CONNECT_EMPLOYEE_ACCOUNT_REQUEST'
export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST'
export const DELETE_EMPLOYEE_ATTACHMENT_REQUEST = 'DELETE_EMPLOYEE_ATTACHMENT_REQUEST'
export const DELETE_EMPLOYEE_PHOTO_REQUEST = 'DELETE_EMPLOYEE_PHOTO_REQUEST'
export const DISCHARGE_EMPLOYEE_REQUEST = 'DISCHARGE_EMPLOYEE_REQUEST'
export const DISCONNECT_EMPLOYEE_ACCOUNT_REQUEST = 'DISCONNECT_EMPLOYEE_ACCOUNT_REQUEST'
export const DISCONNECT_SERVICE_REQUEST = 'DISCONNECT_SERVICE_REQUEST'
export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export const INVITE_EMPLOYEE_REQUEST = 'INVITE_EMPLOYEE_REQUEST'
export const REHIRE_EMPLOYEE_REQUEST = 'REHIRE_EMPLOYEE_REQUEST'
export const REMOVE_EMPLOYEE_FROM_TEAM_REQUEST = 'REMOVE_EMPLOYEE_FROM_TEAM_REQUEST'
export const RESET_EMPLOYEE_PASSWORD_REQUEST = 'RESET_EMPLOYEE_PASSWORD_REQUEST'
export const SWITCH_EMPLOYEE_PAGE = 'SWITCH_EMPLOYEE_PAGE'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_BLACKOUT_CONFIG_REQUEST = 'UPDATE_EMPLOYEE_BLACKOUT_CONFIG_REQUEST'
export const UPDATE_EMPLOYEE_CONFIG_REQUEST = 'UPDATE_EMPLOYEE_CONFIG_REQUEST'
export const UPDATE_EMPLOYEE_EMAIL_CONFIG_REQUEST = 'UPDATE_EMPLOYEE_EMAIL_CONFIG_REQUEST'
export const UPDATE_EMPLOYEE_EMAIL_DIGEST_CONFIG_REQUEST = 'UPDATE_EMPLOYEE_EMAIL_DIGEST_CONFIG_REQUEST'
export const UPDATE_EMPLOYEE_EMAIL_SETTINGS = 'UPDATE_EMPLOYEE_EMAIL_SETTINGS'
export const UPDATE_EMPLOYEE_FIELD = 'UPDATE_EMPLOYEE_FIELD'
export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST'
export const UPLOAD_EMPLOYEE_PHOTO_REQUEST = 'UPLOAD_EMPLOYEE_PHOTO_REQUEST'
export const UPLOAD_EMPLOYEES_REQUEST = 'UPLOAD_EMPLOYEES_REQUEST'
export const DELETE_EMPLOYEE_ACCOUNT_REQUEST = 'DELETE_EMPLOYEE_ACCOUNT_REQUEST'
export const VERIFY_QRCODE_REQUEST = 'VERIFY_QRCODE_REQUEST'
export const DISABLE_TWO_FACTOR_AUTH_REQUEST = 'DISABLE_TWO_FACTOR_AUTH_REQUEST'
export const TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH'
export const LOCK_EMPLOYEE_SWITCHES = 'LOCK_EMPLOYEE_SWITCHES'
export const UPDATE_EMPLOYEE_ERRORS = 'UPDATE_EMPLOYEE_ERRORS'

export const addEmployeeAttachmentRequest = createAction(ADD_EMPLOYEE_ATTACHMENT_REQUEST, 'id', 'data')
export const addEmployeeDaysRequest = createAction(ADD_EMPLOYEE_DAYS_REQUEST, 'id', 'data')
export const addEmployeeToTeamRequest = createAction(ADD_EMPLOYEE_TO_TEAM_REQUEST, 'employee', 'team', 'role')
export const confirmEmployeesRequest = createAction(CONFIRM_EMPLOYEES_REQUEST, 'data', 'next')
export const connectEmployeeAccountRequest = createAction(CONNECT_EMPLOYEE_ACCOUNT_REQUEST, 'id', 'data')
export const createEmployeeRequest = createAction(CREATE_EMPLOYEE_REQUEST, 'employee', 'invite', 'photo')
export const deleteEmployeeAttachmentRequest = createAction(DELETE_EMPLOYEE_ATTACHMENT_REQUEST, 'id')
export const deleteEmployeePhotoRequest = createAction(DELETE_EMPLOYEE_PHOTO_REQUEST, 'id')
export const dischargeEmployeeRequest = createAction(DISCHARGE_EMPLOYEE_REQUEST, 'id')
export const disconnectEmployeeAccountRequest = createAction(DISCONNECT_EMPLOYEE_ACCOUNT_REQUEST, 'id', 'connectedId')
export const disconnectServiceRequest = createAction(DISCONNECT_SERVICE_REQUEST, 'employee', 'command')
export const getEmployeeRequest = createAction(GET_EMPLOYEE_REQUEST, 'id')
export const inviteEmployeeRequest = createAction(INVITE_EMPLOYEE_REQUEST, 'id')
export const rehireEmployeeRequest = createAction(REHIRE_EMPLOYEE_REQUEST, 'id')
export const removeEmployeeFromTeamRequest = createAction(REMOVE_EMPLOYEE_FROM_TEAM_REQUEST, 'employee', 'team', 'role')
export const resetEmployeePasswordRequest = createAction(RESET_EMPLOYEE_PASSWORD_REQUEST, 'id')
export const switchEmployeePage = createAction(SWITCH_EMPLOYEE_PAGE, 'page')
export const updateEmployee = createAction(UPDATE_EMPLOYEE, 'data')
export const updateEmployeeBlackoutConfigRequest = createAction(UPDATE_EMPLOYEE_BLACKOUT_CONFIG_REQUEST, 'id', 'blackoutConfigId')
export const updateEmployeeConfigRequest = createAction(UPDATE_EMPLOYEE_CONFIG_REQUEST, 'id', 'configId')
export const updateEmployeeEmailConfigRequest = createAction(UPDATE_EMPLOYEE_EMAIL_CONFIG_REQUEST)
export const updateEmployeeEmailDigestConfigRequest = createAction(UPDATE_EMPLOYEE_EMAIL_DIGEST_CONFIG_REQUEST, 'employee')
export const updateEmployeeEmailSettings = createAction(UPDATE_EMPLOYEE_EMAIL_SETTINGS, 'key', 'value')
export const updateEmployeeField = createAction(UPDATE_EMPLOYEE_FIELD, 'field', 'data')
export const updateEmployeeRequest = createAction(UPDATE_EMPLOYEE_REQUEST, 'employee')
export const uploadEmployeePhotoRequest = createAction(UPLOAD_EMPLOYEE_PHOTO_REQUEST, 'id', 'data')
export const uploadEmployeesRequest = createAction(UPLOAD_EMPLOYEES_REQUEST, 'file')
export const deleteEmployeeAccountRequest = createAction(DELETE_EMPLOYEE_ACCOUNT_REQUEST, 'id')
export const verifyQrcodeRequest = createAction(VERIFY_QRCODE_REQUEST, 'otp_attempt')
export const disableTwoFactorAuthRequest = createAction(DISABLE_TWO_FACTOR_AUTH_REQUEST)
export const twoFactorAuth = createAction(TWO_FACTOR_AUTH, 'value')
export const lockEmployeeSwitches = createAction(LOCK_EMPLOYEE_SWITCHES, 'value')
export const updateErrors = createAction(UPDATE_EMPLOYEE_ERRORS, 'errors')
