import { lens, multi } from 'lorgnette'
import {
  ACTIVATE_ITEM,
  APPEND_EMPLOYEES_DATA,
  REMOVE_EMPLOYEE_ITEM,
  UPDATE_SINGLE_EMPLOYEE,
  UPDATE_UNINVITED,
  REPLACE_EMPLOYEES_DATA,
  LOCK_SUBMIT,
  UPDATE_UPLOADED_EMPLOYEES
} from '../actions/employees'

const initialState = {
  activeId: null,
  all_employees: [],
  collection: [],
  export: {},
  filters: {},
  next_page: 1,
  uninvited_users: [],
  uploaded_employees: {},
  default_avatar_url: '',
  isSubmitLocked: false
}

const employeesLens = multi(
  lens.prop('filters'),
  lens.prop('next_page'),
  lens.prop('uninvited_users'),
  lens.prop('collection'),
  lens.prop('export'),
  lens.prop('default_avatar_url'),
  lens.prop('all_employees')
)
const employeeByIdLens = id => lens.prop('collection').find(e => e.id === id)

export default function employees(state = initialState, action) {
  switch(action.type) {
  case APPEND_EMPLOYEES_DATA:
    const { data } = action
    return employeesLens.update(
      state,
      () => data.filters,
      () => data.next_page,
      () => data.uninvited_users,
      oldCollection => data.collection ? oldCollection.concat(data.collection) : oldCollection,
      () => data.export,
      () => data.default_avatar_url,
      () => data.all_employees
    )
  case UPDATE_SINGLE_EMPLOYEE:
    return employeeByIdLens(action.id).update(state, item => ({ ...item, ...action.data }))
  case REPLACE_EMPLOYEES_DATA:
    return { ...state, ...action.data }
  case UPDATE_UNINVITED:
    return { ...state, uninvited_users: action.data }
  case UPDATE_UPLOADED_EMPLOYEES:
    return { ...state, uploaded_employees: action.data }
  case ACTIVATE_ITEM:
    return { ...state, activeId: action.id }
  case REMOVE_EMPLOYEE_ITEM:
    return {
      ...state,
      collection: state.collection.filter(e => e.id !== action.id),
      uninvited_users: state.uninvited_users.filter(e => e.id !== action.id),
      all_employees: state.all_employees.filter(e => e.id !== action.id)
    }
  case LOCK_SUBMIT:
    return {
      ...state,
      isSubmitLocked: action.value
    }
  default:
    return state
  }
}
