import { createAction } from '../lib/utils'

export const GET_PLANS_SETTINGS_REQUEST = 'GET_PLANS_SETTINGS_REQUEST'
export const GET_PLANS_SETTINGS_SUCCESS = 'GET_PLANS_SETTINGS_SUCCESS'
export const SET_CARD_REQUEST = 'SET_CARD_REQUEST'
export const SET_PLAN_REQUEST = 'SET_PLAN_REQUEST'
export const CANCEL_COMPANY_REQUEST = 'CANCEL_COMPANY_REQUEST'
export const APPLY_PROMO_CODE_REQUEST = 'APPLY_PROMO_CODE_REQUEST'

export const getPlansSettingsRequest = createAction(GET_PLANS_SETTINGS_REQUEST)
export const getPlansSettingsSuccess = createAction(GET_PLANS_SETTINGS_SUCCESS, 'settings')
export const setCardRequest = createAction(SET_CARD_REQUEST, 'response')
export const setPlanRequest = createAction(SET_PLAN_REQUEST, 'plan', 'currency', 'billing_interval')
export const cancelCompanyRequest = createAction(CANCEL_COMPANY_REQUEST)
export const applyPromoCodeRequest = createAction(APPLY_PROMO_CODE_REQUEST, 'code', 'onValid', 'onInvalid')
