import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-formal'
import { connect } from 'react-redux'
import {
  updateCompany,
  updateCompanyPlan,
  updateErrors,
  cancelSignUp,
  validateSiteName
} from '../../../actions/company'
import { createCompanySchema } from '../../../schemas/company'
import SubmitButton from '../../shared/SubmitButton'
import { convertOldDate } from '../../../lib/dateUtils'
import { Column, Link } from '../../shared/Block'

const mapStateToProps = (state) => ({
  company: state.company
})

const mapDispatchToProps = {
  updateCompany,
  updateCompanyPlan,
  updateErrors,
  cancelSignUp,
  validateSiteName
}

class SiteNameForm extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    updateCompany: PropTypes.func.isRequired,
    updateCompanyPlan: PropTypes.func.isRequired,
    updateErrors: PropTypes.func.isRequired,
    cancelSignUp: PropTypes.func.isRequired,
    validateSiteName: PropTypes.func.isRequired,
    promoCode: PropTypes.string
  }

  componentWillMount() {
    const { updateCompanyPlan, updateCompany, promoCode } = this.props

    updateCompanyPlan()

    if (!!promoCode) {
      updateCompany({ promo_code: promoCode, isGotPromoCode: true })
    }
  }

  render() {
    const {
      company,
      updateErrors,
      updateCompany,
      cancelSignUp,
      validateSiteName
    } = this.props

    return (
      <Form
        className='b-form b-form-company'
        value={company}
        errors={company.errors}
        onError={updateErrors}
        onChange={updateCompany}
        onSubmit={validateSiteName}
        schema={createCompanySchema}
      >
        <div className='b-form__input-group'>
          <Form.Message errorClass='b-form__validation-error' for={['site_name']} />

          <Form.Field
            className='b-form__input b-form__input_larger b-form__input_site-name'
            errorClass='b-form__input_validation-error'
            events={['onChange']}
            id='site_name'
            name='site_name'
            type='text'
            value={company.site_name}
          />
          <div className='b-form__input-suffix'>
            .turbinehq.com
          </div>
        </div>

        {this.renderPromoCodeSection()}

        <div className='b-columns authentication__columns b-columns_justify-start b-columns_wrap'>
          <Column
            modifiers='b-columns__column_with-margin authentication__margin-top authentication__back'
           >
            <a
              className='b-button b-button_link'
              onClick={cancelSignUp}
            >back</a>
          </Column>

          <Column modifiers='authentication__margin-top authentication__submit authentication__submit_company_first_step'>
            <SubmitButton
              className='b-button b-button_primary b-button_full-width'
            >Submit</SubmitButton>
          </Column>
        </div>
      </Form>
    )
  }

  renderPromoCodeSection() {
    const { company: { isGotPromoCode }, updateCompany } = this.props

    if (!isGotPromoCode) {
      return (
        <div className='authentication__additional-link'>
          <Link
            modifiers='b-link_smaller'
            onClick={() => updateCompany({ isGotPromoCode: true })}
          >
            Got a promo code?
          </Link>
        </div>
      )
    }

    return (
      <div>
        <div className='b-title__subtitle b-title_center b-preline'>
          Apply Promo code to get the discount. You’ll be able <br/>
          to add or change it anytime from settings page.
        </div>

        <div className='b-form__input-group'>

          <Form.Message errorClass='b-form__validation-error' for={['promo_code']}/>

          <Form.Field
            className='b-form__input b-form__input_larger b-form__input_promo-code'

            errorClass='b-form__input_validation-error'
            events={['onChange']}
            id='promo_code'
            name='promo_code'
            placeholder='30-day-trial'
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteNameForm)
