import React, { Component } from 'react'
import OtpForm from './Form'

class SignInWithOtp extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Sign In
          </h2>
          <div className='b-title__subtitle b-title__subtitle_larger'>
            Please input one-time code
          </div>
        </div>

        <OtpForm />
      </div>
    )
  }
}

export default SignInWithOtp
