import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signOutRequest } from '../../../actions/user'
import { Columns, Column } from '../../shared/Block'

const mapStateToProps = (state) => ({
  user: state.user,
  endpoints: state.endpoints
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    signOutRequest
  }, dispatch)
)

class ContinueSession extends Component {
  static propTypes = {
    endpoints: PropTypes.object.isRequired,
    signOutRequest: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  render() {
    const { user, endpoints, signOutRequest } = this.props

    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Sign In
          </h2>
          <div className='b-title__subtitle b-title__subtitle_larger b-title__subtitle_margin-top'>
            You have been logged in as

            <div className='b-text b-text_medium-weight'>
              { user.first_name || user.email }
            </div>
          </div>
          <br />
          <div className='authentication__buttons'>
            <a
              className='b-button b-button_primary'
              href={endpoints.dashboard_url}
            >
              Continue
            </a>
          </div>
          <div className='authentication__buttons'>
            <a
              className='b-link'
              onClick={signOutRequest}
            >
              Log Out
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinueSession)
