import {
  ADD_USER_ATTRIBUTE_SUCCESS,
  GET_USER_ATTRIBUTES_SUCCESS,
  DELETE_USER_ATTRIBUTE_SUCCESS,
  UPDATE_USER_ATTRIBUTE_SUCCESS
} from '../actions/userAttributesSettings'
import { lens } from 'lorgnette'

const initialState = {
  collection: []
}

const fieldByIdLens = id => lens.prop('collection').find(s => s.id === id)

export default function userAttributesSettings(state = initialState, action) {
  switch(action.type) {
  case GET_USER_ATTRIBUTES_SUCCESS:
    return {
      ...state,
      collection: action.collection
    }
  case ADD_USER_ATTRIBUTE_SUCCESS:
    return {
      ...state,
      collection: [...state.collection, action.item]
    }
  case DELETE_USER_ATTRIBUTE_SUCCESS:
    return {
      ...state,
      collection: state.collection.filter(s => s.id !== action.item.id)
    }
  case UPDATE_USER_ATTRIBUTE_SUCCESS:
    return fieldByIdLens(action.item.id).update(state, item => ({ ...item, ...action.item }))
  default:
    return state
  }
}
