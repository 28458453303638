/* eslint camelcase: 0 */

import { lens } from 'lorgnette'
import { removeByIndex } from '../lib/utils'
import { daysDuration } from '../lib/timeoffs'

import {
  UPDATE_DEMAND,
  UPDATE_DEMAND_FIELDS,
  UPDATE_DEMAND_ERRORS,
  UPDATE_DEMAND_FIELD,
  RESET_DEMAND_ERRORS,
  DELETE_ATTACHMENT,
  ADD_ATTACHMENT,
  ADD_DEMAND_ITEM,
  DELETE_DEMAND_ITEM,
  RESET_DEMAND_FIELDS,
  SWITCH_DEMAND_ITEM_DELIVERED,
  UPDATE_TIMEOFF_DATES
} from '../actions/demand'
import { CREATE_SUPPLIER_SUCCESS } from '../actions/supplier'

export const initialState = {
  user: {},
  assignable_employees: [],
  export: {
    pdf: {},
    clone: {}
  },
  exportActive: false,
  suppliers: [],
  cost_centers: [],
  points: [],
  clients: [],
  supplier: {
    name: ''
  },
  cost_center: {
    name: ''
  },
  point: {
    name: ''
  },
  client: {
    name: ''
  },
  state: {
    name: '',
    caption: '',
    color: ''
  },
  predefined_items: {
    available: false,
    kind: 'block',
    items: []
  },
  values_attributes: [],
  items: [],
  attachments: [],
  comments: [],
  description_count: 0,
  description_max: 0,
  currency_code: 'USD',
  cost: 0,
  total_cost: 0,
  is_draft: false,
  additional_info: '',
  type: '',
  demand_type_name: '',
  demand_type: {},
  demand_types: [],
  description_sum: '',
  statistic: {
    balance: {
      available_days: 0,
      balance_days: 0,
      taken_days: 0,
      taken_non_charged_days: 0,
      annual_days: 0,
      balancing_days: { pending: 0, approved: 0 }
    },
    links: {
      taken_link: '#',
      balance_link: '#',
      pending_link: '#',
      approved_link: '#'
    }
  },
  activeDaysCount: null
}

const attachmentsLens = lens.prop('attachments')
const suppliersLens = lens.prop('suppliers')
const itemsLens = lens.prop('items')
const itemLens = item => itemsLens.find(i => i === item)

export default function demand(state = initialState, action) {
  switch(action.type) {
  case UPDATE_DEMAND:
  case UPDATE_DEMAND_FIELDS:
    return { ...state, ...action.data }
  case UPDATE_DEMAND_FIELD:
    return { ...state, [action.name]: action.data }
  case UPDATE_DEMAND_ERRORS:
    return { ...state, errors: action.errors }
  case RESET_DEMAND_ERRORS:
    return { ...state, errors: {} }
  case DELETE_ATTACHMENT:
    const attachmentIndex = state.attachments.findIndex(a => a.id === action.attachment.id)
    return attachmentsLens.update(state, attachments => removeByIndex(attachments, attachmentIndex))
  case ADD_ATTACHMENT:
    return attachmentsLens.update(state, attachments => [...attachments, action.attachment])
  case ADD_DEMAND_ITEM:
    return itemsLens.update(state, items => [...items, action.item])
  case DELETE_DEMAND_ITEM:
    return itemLens(action.item).update(state, item => ({ ...item, _destroy: true }))
  case RESET_DEMAND_FIELDS:
    return initialState
  case SWITCH_DEMAND_ITEM_DELIVERED:
    return itemLens(action.item).update(state, item => ({ ...item, delivered: !item.delivered }))
  case UPDATE_TIMEOFF_DATES:
    return {
      ...state,
      activeDaysCount: daysDuration(action.dates),
      dates: action.dates.reduce((acc, day) => {
        acc[day.date.format('DD/MM/YYYY')] = day.duration < 1 ? `${day.duration} ${day.time}` : day.duration
        return acc
      }, {})
    }
  case CREATE_SUPPLIER_SUCCESS:
    return suppliersLens.update(state, items => ([...items, action.supplier]))
  default:
    return state
  }
}
