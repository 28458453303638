import { /*TUTORIAL_START,*/ TUTORIAL_NEXT, TUTORIAL_CLOSE, TUTORIAL_FINISH_REQUEST } from '../actions/tutorial'

export const initialState = {
  currentStep: 1
}

export default function supplier(state = initialState, action) {
  switch(action.type) {
  // case TUTORIAL_START:
  //   return { ...state, currentStep: 1 }
  case TUTORIAL_NEXT:
    return { ...state, currentStep: state.currentStep + 1 }
  case TUTORIAL_FINISH_REQUEST:
  case TUTORIAL_CLOSE:
    return { ...state, currentStep: -1 }
  default:
    return state
  }
}
