import { AllHtmlEntities } from 'html-entities'
import { globalCurrencies } from './currencies'

export const formatString = (s, ...args) => (
  s.replace(/{(\d+)}/g, (match, number) => (
    typeof args[number] !== 'undefined'
    ? args[number]
    : match
  ))
)

export const moneyFormat = (n, currency = 'USD', maximumFractionDigits = 2) => {
  const entities = new AllHtmlEntities()

  const value = parseFloat(n).toLocaleString('en-US', {
    // style: 'currency', // TODO: let use it
    // currency: currency, // TODO: let use it
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits
  })

  const item = globalCurrencies[currency] || { symbol: currency, format: '{0}' }

  return formatString(
    entities.decode(item.format),
    value,
    item.symbol
  )
}
