import { takeLatest, delay } from 'redux-saga'
import { call, put, cancel, fork } from 'redux-saga/effects'
import {
  UPDATE_TEAMS_REQUEST,
  APPEND_TEAMS_DATA,
  REPLACE_TEAMS_DATA,
  updateSingleTeam,
  appendTeamsData,
  replaceTeamsData
} from '../actions/teams'
import { ACTIVATE_FILTER, DEACTIVATE_FILTER } from '../actions/filters'
import { UPDATE_TEAM } from '../actions/team'
import routes from '../api/routes'
import { fetchCollection, updateFilters } from './utils'

const TEAM_CONTROLLER = 'teams'

export function *fetchAndAppend() {
  const data = yield call(fetchCollection, {
    loaderId: 'teamsCollection',
    getApiPath: routes.teams
  })

  yield put(appendTeamsData(data))
}

export function *fetchAndReplace({ timeout = 0 }) {
  // delay makes this job cancelable
  yield call(delay, timeout)

  const data = yield call(fetchCollection, {
    loaderId: 'teamsCollection',
    getApiPath: routes.teams
  })

  yield put(replaceTeamsData(data))
}

// Prevent double ajax request
// when activating and deactivating filters
// in short period of time
let fetchAndReplaceJob
export function *startFetchAndReplace() {
  if (fetchAndReplaceJob) {
    yield cancel(fetchAndReplaceJob)
  }
  fetchAndReplaceJob = yield fork(fetchAndReplace, { timeout: 700 })
}

export function *updateSingleTeamProcess({ data }) {
  const { id } = data
  yield put(updateSingleTeam(id, data))
}

export function *filterChanged() {
  yield call(startFetchAndReplace)
}

export function *watchTeamsUpdates() {
  yield [
    takeLatest(UPDATE_TEAMS_REQUEST, fetchAndAppend),
    takeLatest(UPDATE_TEAM, updateSingleTeamProcess),

    takeLatest(APPEND_TEAMS_DATA, updateFilters),
    takeLatest(REPLACE_TEAMS_DATA, updateFilters)
  ]
}

export function *watchTeamFiltersUpdates(context) {
  if (context.request && TEAM_CONTROLLER === context.request.react_controller) {
    yield [takeLatest(ACTIVATE_FILTER, filterChanged), takeLatest(DEACTIVATE_FILTER, filterChanged)]
  }
}
