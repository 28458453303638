import { takeEvery } from 'redux-saga'
import { put, select, call, fork } from 'redux-saga/effects'
import routes from '../api/routes'
import { makeUrl, get, del, put as apiPut, post } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import { loaderShow, loaderHide } from '../actions/loader'
import {
  ADD_CATEGORY_REQUEST,
  ADD_BLACKOUT_CONFIG_REQUEST,
  ADD_CONFIG_REQUEST,
  DELETE_CATEGORY_REQUEST,
  DELETE_BLACKOUT_CONFIG_REQUEST,
  DELETE_CONFIG_REQUEST,
  GET_TIMEOFFS_SETTINGS_REQUEST,
  UPDATE_BLACKOUT_CONFIG_REQUEST,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CONFIG_REQUEST,
  addBlackoutConfigSuccess,
  addCategorySuccess,
  addConfigSuccess,
  deleteBlackoutConfigSuccess,
  deleteCategorySuccess,
  deleteConfigSuccess,
  getTimeoffsSettingsSuccess,
  updateBlackoutConfigSuccess,
  updateCategorySuccess,
  updateConfigSuccess
} from '../actions/timeoffSettings'
import {
  blackoutConfigMapping,
  demandTypeMapping,
  userConfigMapping
} from '../api/mappings'
import pluralize from 'pluralize'

export function* updateTimeoffsSettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.timeoffsSettings)
  const { data } = yield call(get, url)

  if (data) {
    yield put(getTimeoffsSettingsSuccess(data))
  }
}

export function* updateBlackoutConfig({ blackoutConfig, onSuccess }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.blackoutConfig, { id: blackoutConfig.id })
  const data = blackoutConfigMapping(blackoutConfig)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Calendar updated')
    yield put(updateBlackoutConfigSuccess(item))
    yield put(onSuccess())
  }
}

export function* updateCategory({ category }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.timeoffDemandTypes, { id: category.id })
  const data = demandTypeMapping(category)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Category updated')
    yield put(updateCategorySuccess(item))
  }
}

export function* updateConfig({ config, onSuccess }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.userConfig, { id: config.id })
  const data = userConfigMapping(config)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Config updated')
    yield put(updateConfigSuccess(item))
    yield put(onSuccess())
  }
}

export function* addBlackoutConfig({ blackoutConfig, onSuccess }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.blackoutConfigs)
  const data = blackoutConfigMapping(blackoutConfig)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield put(addBlackoutConfigSuccess(item))
    yield put(onSuccess())
  }
}

export function* addCategory({ category }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.timeoffsDemandTypes)
  const data = demandTypeMapping(category)
  yield put(loaderShow('addTimeoffsCategory'))
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield put(addCategorySuccess(item))
  }
  yield put(loaderHide('addTimeoffsCategory'))
}

export function* addConfig({ config, onSuccess }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.userConfigs, { company_id: config.company_id })
  const data = userConfigMapping(config)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield put(addConfigSuccess(item))
    yield put(onSuccess())
  }
}

export function* deleteBlackoutConfig({ blackoutConfig }) {
  const deleteConfirmed = yield call(proceed)

  if (deleteConfirmed) {
    const host = yield select(getHost)
    const url = yield call(makeUrl, host, routes.blackoutConfig, { id: blackoutConfig.id })
    const { item, errors } = yield call(del, url)
    if (errors) {
      yield fork(showFlash, 'error', allErrors(errors))
    } else {
      yield put(deleteBlackoutConfigSuccess(blackoutConfig))
      yield fork(showFlash, 'success', 'Calendar deleted')
    }
  }
}

export function* deleteCategory({ category }) {
  const deleteConfirmed = yield call(proceed)
  if (!deleteConfirmed) {
    return
  }
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.timeoffDemandTypes, { id: category.id })
  yield put(loaderShow('addTimeoffsCategory'))
  yield call(del, url)
  yield put(deleteCategorySuccess(category))
  yield put(loaderHide('addTimeoffsCategory'))
  yield fork(showFlash, 'success', 'Category deleted')
}

export function* deleteConfig({ config }) {
  const users = config.users_count
  const message = users > 0
    ? `Are you sure?
This config is used by ${users} ${pluralize('user', users)}.
After we remove this config these users will have the default company config.`
    : undefined
  const deleteConfirmed = yield call(proceed, message)

  if (deleteConfirmed) {
    const host = yield select(getHost)
    const url = yield call(makeUrl, host, routes.userConfig, { id: config.id })
    yield call(del, url)
    yield put(deleteConfigSuccess(config))
    yield fork(showFlash, 'success', 'Config deleted')
  }
}

export function* watchTimeoffsSettingsUpdates() {
  yield [
    takeEvery(ADD_BLACKOUT_CONFIG_REQUEST, addBlackoutConfig),
    takeEvery(ADD_CATEGORY_REQUEST, addCategory),
    takeEvery(ADD_CONFIG_REQUEST, addConfig),
    takeEvery(DELETE_BLACKOUT_CONFIG_REQUEST, deleteBlackoutConfig),
    takeEvery(DELETE_CATEGORY_REQUEST, deleteCategory),
    takeEvery(DELETE_CONFIG_REQUEST, deleteConfig),
    takeEvery(GET_TIMEOFFS_SETTINGS_REQUEST, updateTimeoffsSettings),
    takeEvery(UPDATE_BLACKOUT_CONFIG_REQUEST, updateBlackoutConfig),
    takeEvery(UPDATE_CATEGORY_REQUEST, updateCategory),
    takeEvery(UPDATE_CONFIG_REQUEST, updateConfig)
  ]
}
