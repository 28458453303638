import { takeLatest } from 'redux-saga'
import { singular } from 'pluralize'
import { put, call, fork, select } from 'redux-saga/effects'
import {
  UPDATE_TEAM_REQUEST,
  UPDATE_STAFF_REQUEST,
  CHANGE_TEAM_REQUEST,
  ADD_STAFF_REQUEST,
  DELETE_STAFF_REQUEST,
  DELETE_TEAM_REQUEST,
  updateTeam,
  updateTeamRequest,
  updateTeamErrors,
  updateStaff
} from '../actions/team'
import { deleteTeam } from '../actions/teams'
import routes from '../api/routes'
import { errorsMapping, isDefined } from '../lib/utils'
import { teamMapping } from '../api/mappings'
import { makeUrl, get, post, del } from '../api/utils'
import {
  fetchCollectionItem,
  changeCollectionItem,
  createCollectionItem,
  deleteCollectionItem,
  showFlash,
  getHost
} from './utils'

const getTeam = state => state.team

const isTeamPersisted = team => isDefined(team.id)

export function *getStaffUrl(path, urlOptions) {
  const { id } = yield select(getTeam)
  const host = yield select(getHost)
  return makeUrl(host, path, { ...urlOptions, teamId: id })
}

export function *fetchTeam({ id }) {
  const team = yield call(fetchCollectionItem, id, { getApiPath: routes.teams })
  yield put(updateTeam(team))
}

export function *changePersistedTeam(team) {
  const response = yield call(
    changeCollectionItem,
    team.id,
    { team: teamMapping(team) },
    { getApiPath: routes.teams }
  )

  if (response.errors) {
    const errors = yield call(errorsMapping, response.errors)
    yield put(updateTeamErrors(errors))
  } else {
    yield put(updateTeam(response))
    yield fork(showFlash, 'success', 'Team was updated')
  }
}

export function *createTeam(team) {
  const response = yield call(
    createCollectionItem,
    { team: teamMapping(team) },
    { getApiPath: routes.teams }
  )

  if (response.errors) {
    const errors = yield call(errorsMapping, response.errors)
    yield put(updateTeamErrors(errors))
  } else {
    yield put(updateTeam(response))
    yield fork(showFlash, 'success', 'Team was created')
  }
}

export function *changeTeam({ team }) {
  const isPersisted = yield call(isTeamPersisted, team)

  if (isPersisted) {
    yield fork(changePersistedTeam, team)
  } else {
    yield fork(createTeam, team)
  }
}

export function *deleteTeamProcess({ router }, { id }) {
  const response = yield call(deleteCollectionItem, id, { getApiPath: routes.teams })

  if (response.errors) {
    yield fork(showFlash, 'error', 'Team wasn\'t deleted')
  } else {
    yield put(deleteTeam(id))
    yield fork(showFlash, 'success', 'Team was deleted')
    yield call([router, router.transitionTo], '/teams')
  }
}

export function *updateStaffProcess({ staffType }) {
  const newStaffUrl = yield call(getStaffUrl, routes.staffNew, { staffType })
  const { collection } = yield call(get, newStaffUrl)
  yield put(updateStaff(staffType, collection))
}

export function *addStaffProcess({ staffType, ids }) {
  const staffUrl = yield call(getStaffUrl, routes.staff, { staffType })
  const response = yield call(post, staffUrl, { user_ids: ids })

  if (response.error) {
    yield fork(showFlash, 'error', `${singular(staffType)} wasn't added`)
  } else {
    const { id } = yield select(getTeam)
    yield put(updateTeamRequest(id))
  }
}

export function *deleteStaffProcess({ staffType, id }) {
  const staffMemberUrl = yield call(getStaffUrl, routes.staffMember, { staffType, id })
  const response = yield call(del, staffMemberUrl)

  if (response.error) {
    yield fork(showFlash, 'error', `${singular(staffType)} wasn't deleted`)
  } else {
    const { id } = yield select(getTeam)
    yield put(updateTeamRequest(id))
  }
}

export function *watchTeamUpdates(context) {
  yield [
    takeLatest(UPDATE_TEAM_REQUEST, fetchTeam),
    takeLatest(CHANGE_TEAM_REQUEST, changeTeam),
    takeLatest(UPDATE_STAFF_REQUEST, updateStaffProcess),
    takeLatest(ADD_STAFF_REQUEST, addStaffProcess),
    takeLatest(DELETE_STAFF_REQUEST, deleteStaffProcess),
    takeLatest(DELETE_TEAM_REQUEST, deleteTeamProcess, context)
  ]
}
