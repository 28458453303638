import { takeEvery } from 'redux-saga'
import { put, select, call, fork } from 'redux-saga/effects'
import routes from '../api/routes'
import { makeUrl, get, put as apiPut } from '../api/utils'
import { allErrors, getHost, showFlash } from './utils'
import {
  GET_DEMAND_STATES_REQUEST,
  UPDATE_DEMAND_STATE_REQUEST,
  getDemandStatesSuccess,
  updateDemandStateSuccess
} from '../actions/demandStates'
import { demandStateMapping } from '../api/mappings'

export function* getStates() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.settingsDemandStates)
  const { collection } = yield call(get, url)

  if (collection) {
    yield put(getDemandStatesSuccess(collection))
  }
}

export function* updateState({ state, onSuccess }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.settingsDemandState, { id: state.id })
  const data = demandStateMapping(state)
  const { errors, item } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'State updated')
    yield put(updateDemandStateSuccess(item))
    yield put(onSuccess())
  }
}

export function* watchDemandStatesUpdates() {
  yield [
    takeEvery(GET_DEMAND_STATES_REQUEST, getStates),
    takeEvery(UPDATE_DEMAND_STATE_REQUEST, updateState)
  ]
}
