import { createAction } from '../lib/utils'

export const UPDATE_TEAM = 'UPDATE_TEAM'
export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST'
export const UPDATE_STAFF = 'UPDATE_STAFF'
export const UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST'
export const UPDATE_TEAM_FIELDS = 'UPDATE_TEAM_FIELDS'
export const UPDATE_TEAM_ERRORS = 'UPDATE_TEAM_ERRORS'
export const RESET_TEAM_ERRORS = 'RESET_TEAM_ERRORS'
export const CHANGE_TEAM_REQUEST = 'CHANGE_TEAM_REQUEST'
export const ADD_STAFF_REQUEST = 'ADD_STAFF_REQUEST'
export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST'
export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST'
export const RESET_TEAM = 'RESET_TEAM'

export const updateTeam = createAction(UPDATE_TEAM, 'data')
export const updateTeamRequest = createAction(UPDATE_TEAM_REQUEST, 'id')
export const updateStaff = createAction(UPDATE_STAFF, 'staffType', 'data')
export const updateStaffRequest = createAction(UPDATE_STAFF_REQUEST, 'staffType')
export const updateTeamFields = createAction(UPDATE_TEAM_FIELDS, 'data')
export const updateTeamErrors = createAction(UPDATE_TEAM_ERRORS, 'errors')
export const resetTeamErrors = createAction(RESET_TEAM_ERRORS)
export const changeTeamRequest = createAction(CHANGE_TEAM_REQUEST, 'team')
export const addStaffRequest = createAction(ADD_STAFF_REQUEST, 'staffType', 'ids')
export const deleteStaffRequest = createAction(DELETE_STAFF_REQUEST, 'staffType', 'id')
export const deleteTeamRequest = createAction(DELETE_TEAM_REQUEST, 'id')
export const resetTeam = createAction(RESET_TEAM)
