import { takeEvery } from 'redux-saga'
import { call, put, select, fork } from 'redux-saga/effects'
import {
  GET_ROLES_REQUEST,
  UPDATE_ROLES_REQUEST,
  getRolesRequest,
  getRolesSuccess
} from '../actions/rolesSettings'
import routes from '../api/routes'
import { makeUrl, get, del, put as apiPut, post, upload } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import { loaderHide, loaderShow } from '../actions/loader'
import { rolesMapping } from '../api/mappings'

export function* getRoles() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.rolesSettings)
  const { settings } = yield call(get, url)

  if (settings) {
    yield put(getRolesSuccess(settings))
  }
}

export function* updateRoles({ groups }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.rolesSettings)
  const data = rolesMapping(groups)
  const { success } = yield call(apiPut, url, data)
  if (success) {
    yield fork(showFlash, 'success', 'Roles updated')
    yield put(getRolesRequest())
  }
}

export function* watchRolesSettingsUpdates() {
  yield [
    takeEvery(GET_ROLES_REQUEST, getRoles),
    takeEvery(UPDATE_ROLES_REQUEST, updateRoles)
  ]
}
