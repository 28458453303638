import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
  host: state.host
})

export class Asset extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    host: PropTypes.string,
    src: PropTypes.string.isRequired
  }

  static defaultProps = {
    host: ''
  }

  render() {
    const { children, host, src } = this.props

    return (
      React.cloneElement(children, { src: host + src })
    )
  }
}

export default connect(mapStateToProps)(Asset)
