import { get, post, del, makeUrl, put as apiPut, redirect } from '../api/utils'
import { delay } from 'redux-saga'
import { call, select, put, take, fork } from 'redux-saga/effects'
import { filtersToParams } from '../lib/filtersUtils'
import { allValuesOf, isString } from '../lib/utils'
import { loaderShow, loaderHide } from '../actions/loader'
import { flashShow, flashHide } from '../actions/flash'
import { updateFilter } from '../actions/filters'
import { updateDemandsSubtotal } from '../actions/demands'

const FLASH_CLOSE_DELAY = 10000

export const getHost = state => state.host
export const getUser = state => state.user
export const getPage = state => state.page
export const getBaseHost = state => state.base_host || state.host || ''
export const getAuthToken = state => state.user.authentication_token
export const getFilters = state => state.filters
export const getDateTimeFormat = state => state.company.config.datetime_format

export const getDemandApiUrl = state => state.endpoints.demand
export const getDemandsApiUrl = state => state.demands.apiUrl
export const getDemandsTotalsApiUrl = state => state.demands.apiTotalsUrl

function* apiUrlParts(getApiPath) {
  return yield [select(getHost), isString(getApiPath) ? getApiPath : select(getApiPath)]
}

function* demandApiUrl({ getApiPath = getDemandApiUrl } = {}) {
  return yield call(apiUrlParts, getApiPath)
}

export function* requestDelayedDownload({ url }) {
  const { download_url, error, flash } = yield call(get, url)

  if (flash) {
    yield fork(showFlash, 'success', flash)
  }
  if (error) {
    yield fork(showFlash, 'error', error)
  } else if (download_url) {
    yield call(redirect, download_url)
  }
}

export function* fetchSubtotal({ getApiPath = getDemandsTotalsApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)

  if (!path) {
    return null
  }

  const filters = yield select(getFilters)
  const { totals } = yield call(
    get,
    makeUrl(host, path, filtersToParams(filters, { replaceEmpty: 'filtering' }))
  )

  return totals
}

export function* fetchCollection({ loaderId = 'demandsCollection', getApiPath = getDemandsApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)
  const filters = yield select(getFilters)

  yield put(loaderShow(loaderId))

  const collection = yield call(
    get,
    makeUrl(
      host,
      path,
      filtersToParams(filters, {
        replaceEmpty: 'filtering'
      })
    )
  )

  yield put(loaderHide(loaderId))

  return collection
}

export function* fetchCollectionItem(id, { getApiPath = getDemandApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)
  return yield call(get, `${host}${path}/${id}`)
}

export function* fetchNewCollectionItem({ getApiPath = getDemandApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)
  const filters = yield select(getFilters)
  const userId = filters.user_id.value
  return yield call(get, `${host}${path}/new${userId ? `?user_id=${userId}` : ''}`)
}

export function* fetchClonedCollectionItem(id) {
  const [host, path] = yield call(demandApiUrl)
  return yield call(get, `${host}${path}/${id}/clone_data`)
}

export function* changeCollectionItem(id, data, { getApiPath = getDemandApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)
  return yield call(apiPut, `${host}${path}/${id}`, data)
}

export function* createCollectionItem(data, { getApiPath = getDemandApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)
  const filters = yield select(getFilters)
  const user_id = filters.user_id.value
  const item = user_id ? { ...data, user_id } : data
  return yield call(post, makeUrl(host, path), item)
}

export function* createCollectionItemChild(id, path, data) {
  const host = yield select(getHost)
  const apiUrl = makeUrl(host, path, { id })
  return yield call(post, apiUrl, data)
}

export function* deleteCollectionItem(id, { getApiPath = getDemandApiUrl } = {}) {
  const [host, path] = yield call(apiUrlParts, getApiPath)
  return yield call(del, `${host}${path}/${id}`)
}

export function* proceed(text = 'Are you sure?') {
  return yield call([global, global.confirm], text)
}

export function* showFlash(type, text) {
  yield put(flashShow(type, text))
  yield call(delay, FLASH_CLOSE_DELAY)
  yield put(flashHide())
}

export function* takeFirst(pattern, saga, ...args) {
  const task = yield fork(function*() {
    let firstTask

    while (true) {
      const action = yield take(pattern)

      if (!(firstTask && firstTask.isRunning())) {
        firstTask = yield fork(saga, ...args.concat(action))
      }
    }
  })

  return task
}

export function* updateFilterCollection(filters) {
  yield Object.keys(filters).map(filter => put(updateFilter(filter, filters[filter])))
}

export function* updatePageFilter({ data: { next_page } }) {
  yield put(updateFilter('page', { value: next_page }))
}

export function* updateFilters(action) {
  const { data: { filters } } = action

  yield call(updateFilterCollection, filters)
  yield call(updatePageFilter, action)
}

export const allErrors = errors => allValuesOf(errors).join(', ')
