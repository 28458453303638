import { createAction } from '../lib/utils'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_PLAN = 'UPDATE_PLAN'
export const UPDATE_COMPANY_ERRORS = 'UPDATE_COMPANY_ERRORS'
export const UPDATE_COMPANY_SETTINGS_ERRORS = 'UPDATE_COMPANY_SETTINGS_ERRORS'
export const RESET_COMPANY_SETTINGS_ERRORS = 'RESET_COMPANY_SETTINGS_ERRORS'
export const VALIDATE_SITE_NAME_REQUEST = 'VALIDATE_SITE_NAME_REQUEST'
export const CANCEL_SIGN_UP = 'CANCEL_SIGN_UP'
export const UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST'
export const UPDATE_COMPANY_SETTINGS_REQUEST = 'UPDATE_COMPANY_SETTINGS_REQUEST'
export const UPDATE_COMPANY_SETTINGS_SUCCESS = 'UPDATE_COMPANY_SETTINGS_SUCCESS'
export const CHANGE_COMPANY_LOGO_REQUEST = 'CHANGE_COMPANY_LOGO_REQUEST'
export const DELETE_COMPANY_LOGO_REQUEST = 'DELETE_COMPANY_LOGO_REQUEST'
export const CHANGE_COMPANY_ALT_LOGO_REQUEST = 'CHANGE_COMPANY_ALT_LOGO_REQUEST'
export const DELETE_COMPANY_ALT_LOGO_REQUEST = 'DELETE_COMPANY_ALT_LOGO_REQUEST'
export const EXPORT_ATTACHMENTS_REQUEST = 'EXPORT_ATTACHMENTS_REQUEST'
export const CHANGE_COMPANY_SETTINGS = 'CHANGE_COMPANY_SETTINGS'
export const SYNC_COMPANY_SETTINGS_REQUEST = 'SYNC_COMPANY_SETTINGS_REQUEST'

export const updateCompany = createAction(UPDATE_COMPANY, 'company')
export const updatePlan = createAction(UPDATE_PLAN, 'plan')
export const updateErrors = createAction(UPDATE_COMPANY_ERRORS, 'errors')
export const updateSettingsErrors = createAction(UPDATE_COMPANY_SETTINGS_ERRORS, 'errors')
export const resetSettingsErrors = createAction(RESET_COMPANY_SETTINGS_ERRORS)
export const validateSiteName = createAction(VALIDATE_SITE_NAME_REQUEST, 'company')
export const cancelSignUp = createAction(CANCEL_SIGN_UP)
export const updateCompanyPlan = createAction(UPDATE_PLAN_REQUEST)
export const updateCompanySettingsRequest = createAction(UPDATE_COMPANY_SETTINGS_REQUEST)
export const updateCompanySettingsSuccess = createAction(UPDATE_COMPANY_SETTINGS_SUCCESS, 'data')
export const changeCompanyLogoRequest = createAction(CHANGE_COMPANY_LOGO_REQUEST, 'file')
export const deleteCompanyLogoRequest = createAction(DELETE_COMPANY_LOGO_REQUEST)
export const changeCompanyAltLogoRequest = createAction(CHANGE_COMPANY_ALT_LOGO_REQUEST, 'file')
export const deleteCompanyAltLogoRequest = createAction(DELETE_COMPANY_ALT_LOGO_REQUEST)
export const exportAttachmentsRequest = createAction(EXPORT_ATTACHMENTS_REQUEST)
export const changeCompanySettings = createAction(CHANGE_COMPANY_SETTINGS, 'data', 'path')
export const syncCompanySettingsRequest = createAction(SYNC_COMPANY_SETTINGS_REQUEST)
