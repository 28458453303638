import React, { Component } from 'react'
import EditInvitationForm from './Form'

class InvitationEdit extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Confirm invitation
          </h2>
          <div className='b-title__subtitle'>Set your password to continue</div>
        </div>

        <EditInvitationForm />
      </div>
    )
  }
}

export default InvitationEdit
