import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Form from 'react-formal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateFields, updateErrors, resetPasswordRequest } from '../../../actions/user'
import { updatePage, PAGES } from '../../../actions/page'
import { resetPasswordSchema } from '../../../schemas/user'
import SubmitButton from '../../shared/SubmitButton'
import { Columns, Column } from '../../shared/Block'

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    updateFields,
    updateErrors,
    updatePage,
    resetPasswordRequest
  }, dispatch)
)

class ResetPassword extends Component {
  static propTypes = {
    updateFields: PropTypes.func.isRequired,
    updateErrors: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    resetPasswordRequest: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  }

  render() {
    const {
      user,
      updateErrors,
      updateFields,
      updatePage,
      resetPasswordRequest
    } = this.props

    return (
      <Form
        className='b-form'
        defaultValue={user}
        errors={user.errors}
        onError={updateErrors}
        onChange={updateFields}
        onSubmit={resetPasswordRequest}
        schema={resetPasswordSchema}
      >
        <div className='b-form__input-group'>
          <Form.Message errorClass='b-form__validation-error' for={['email']}/>

          <Form.Field
            className='b-form__input'
            errorClass='b-form__input_validation-error'
            events={['onBlur']}
            id='email'
            name='email'
            placeholder='Your email address'
            type='text'
            value={user.email}
          />
        </div>

        <Columns modifiers='authentication__buttons'>
          <Column modifiers='b-columns__column_with-margin authentication__back'>
            <a
              className='b-button b-button_link'
              onClick={() => updatePage(PAGES.SIGN_IN)}
            >back</a>
          </Column>

          <Column modifiers='authentication__submit'>
            <SubmitButton
              className='b-button b-button_primary'
            >Submit</SubmitButton>
          </Column>
        </Columns>
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
