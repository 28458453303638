import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { children } from '../../propTypes'

export const block = (blockName, tagName) => (
  class Block extends Component {
    static propTypes = {
      children,
      className: PropTypes.string,
      modifiers: PropTypes.string
    }

    render() {
      const { modifiers, className, ...rest } = this.props
      let cn = classNames(blockName, className, {
        [this.props.modifiers]: modifiers
      })
      return React.createElement(
        tagName,
        { ...rest, className: cn }
      )
    }
  }
)

const element = (blockName, elemName, tagName) => (
  block(`${blockName}__${elemName}`, tagName)
)

export const Panel = block('b-panel', 'div')
export const Link = block('b-link', 'a')
export const Columns = block('b-columns', 'div')
export const Column = element('b-columns', 'column', 'div')
export const Form = block('b-form', 'div')
export const Label = element('b-form', 'label', 'label')
export const Input = element('b-form', 'input', 'input')
export const Button = element('b-form', 'button', 'button')

export const Page = block('page', 'div')
export const PagePart = element('page', 'part', 'div')
export const PageMenu = element('page', 'menu', 'div')
export const PageContent = element('page', 'content', 'div')

export const InputLabel = block('input-label', 'label')
export const FormGroup = block('form-group', 'div')
export const Touchable = block('touchable', 'a')
export const Close = block('close', 'a')

export const Content = block('content', 'div')
export const Text = block('text', 'span')
