import { takeLatest, delay } from 'redux-saga'
import { call, put, cancel, fork, select } from 'redux-saga/effects'
import { get } from '../api/utils'
import {
  UPDATE_EMPLOYEES_REQUEST,
  APPEND_EMPLOYEES_DATA,
  REPLACE_EMPLOYEES_DATA,
  EXPORT_EMAIL_EMPLOYEES_REQUEST,
  updateSingleEmployee,
  appendEmployeesData,
  replaceEmployeesData
} from '../actions/employees'
import { ACTIVATE_FILTER, DEACTIVATE_FILTER } from '../actions/filters'
import { UPDATE_EMPLOYEE } from '../actions/employee'
import routes from '../api/routes'
import { fetchCollection, showFlash, updateFilters } from './utils'

const EMPLOYEE_CONTROLLER = 'users'

export function* fetchAndAppend() {
  const data = yield call(fetchCollection, {
    loaderId: 'employeesCollection',
    getApiPath: routes.employees
  })

  yield put(appendEmployeesData(data))
}

export function* fetchAndReplace({ timeout = 0 }) {
  if (timeout > 0) {
    // delay makes this job cancelable
    yield call(delay, timeout)
  }

  const data = yield call(fetchCollection, {
    loaderId: 'employeesCollection',
    getApiPath: routes.employees
  })

  yield put(replaceEmployeesData(data))
}

// Prevent double ajax request
// when activating and deactivating filters
// in short period of time
let fetchAndReplaceJob

export function* startFetchAndReplace() {
  if (fetchAndReplaceJob) {
    yield cancel(fetchAndReplaceJob)
  }
  fetchAndReplaceJob = yield fork(fetchAndReplace, { timeout: 700 })
}

export function* updateSingleEmployeeProcess({ data }) {
  const { id } = data
  yield put(updateSingleEmployee(id, data))
}

export function* exportViaEmail() {
  const { id } = yield select(s => s.user)
  const { flash } = yield call(get, `${routes.employees}/${id}/export_email`)

  yield fork(showFlash, 'success', flash)
}

export function* filterChanged({ name }) {
  if (name === 'show') {
    return
  }
  if (name === 'search') {
    yield call(delay, 700)
  }
  if (name === 'page') {
    yield fork(fetchAndAppend)
  } else {
    yield call(startFetchAndReplace)
  }
}

export function* watchEmployeesUpdates() {
  yield [
    takeLatest(UPDATE_EMPLOYEES_REQUEST, fetchAndReplace),
    takeLatest(UPDATE_EMPLOYEE, updateSingleEmployeeProcess),
    takeLatest(APPEND_EMPLOYEES_DATA, updateFilters),
    takeLatest(REPLACE_EMPLOYEES_DATA, updateFilters),
    takeLatest(EXPORT_EMAIL_EMPLOYEES_REQUEST, exportViaEmail)
  ]
}

export function* watchEmployeeFiltersUpdates(context) {
  if (context.request && EMPLOYEE_CONTROLLER === context.request.react_controller) {
    yield [
      takeLatest(ACTIVATE_FILTER, filterChanged),
      takeLatest(DEACTIVATE_FILTER, filterChanged)
    ]
  }
}
