/* eslint camelcase: 0 */
/* eslint no-constant-condition: 0 */

import { takeEvery, buffers } from 'redux-saga'
import { put, select, call, fork, take, actionChannel } from 'redux-saga/effects'
import { getParameterByName, deepEqual } from '../lib/utils'
import { validateSiteName, removePromoCode, updateHost } from '../api/company'
import routes from '../api/routes'
import { makeUrl, get, upload, put as putRequest } from '../api/utils'
import { getHost, requestDelayedDownload, showFlash } from './utils'
import { PAGES, updatePage } from '../actions/page'
import { updateCompanySettingsSuccess, resetSettingsErrors } from '../actions/company'
import { updateEndpoints } from '../actions/endpoints'
import { updateHost as updateHostAction } from '../actions/host'
import { companySettingsMapping, companySettingsErrorsMapping } from '../api/mappings'
import {
  VALIDATE_SITE_NAME_REQUEST,
  CANCEL_SIGN_UP,
  UPDATE_PLAN_REQUEST,
  UPDATE_COMPANY_SETTINGS_REQUEST,
  SYNC_COMPANY_SETTINGS_REQUEST,
  CHANGE_COMPANY_LOGO_REQUEST,
  DELETE_COMPANY_LOGO_REQUEST,
  CHANGE_COMPANY_ALT_LOGO_REQUEST,
  DELETE_COMPANY_ALT_LOGO_REQUEST,
  EXPORT_ATTACHMENTS_REQUEST,
  updatePlan,
  updateCompany,
  updateErrors,
  updateSettingsErrors
} from '../actions/company'
import { loaderHide, loaderShow } from '../actions/loader'

export const getCompanySettings = state => state.company.settings

export const companySettingsCache = {
  prev: null,
  next: null,
  setNext(value) {
    this.next = value
  },
  setPrev(value) {
    this.prev = value
  }
}

export function* validateCompanySiteName({ company }) {
  const host = yield select(getHost)
  const result = yield call(validateSiteName, host, company)

  if (result.success) {
    yield put(updateEndpoints({ providers: result.endpoints }))
    yield put(updatePage(PAGES.SIGN_UP))
  } else {
    yield put(updateErrors(result.errors))
  }
}

export function* cancelCompanySignUp() {
  const host = yield select(getHost)

  yield call(removePromoCode, host)
  history.pushState({}, null, global.location.pathname)
  yield put(updateCompany({ promo_code: null }))
  yield put(updatePage(PAGES.SIGN_IN))
}

export function* updateCompanyPlan() {
  const plan = yield call(getParameterByName, 'plan')

  if (plan) {
    yield put(updatePlan(plan))
  }
}

export function* updateCompanySettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.editCompanySettings)
  const { item } = yield call(get, url)
  if (item) {
    yield put(updateCompanySettingsSuccess(item))
    yield call([companySettingsCache, companySettingsCache.setPrev], item)
  }
}

export function* changeCompanyLogo({ file }) {
  yield put(loaderShow('companyLogo'))
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.companySettings)
  const { item } = yield call(upload, {
    file,
    path: url,
    name: 'company[logo_attributes][image]',
    method: 'PUT'
  })
  yield put(loaderHide('companyLogo'))

  if (item) {
    yield put(updateCompanySettingsSuccess(item))
  }
}

export function* deleteCompanyLogo() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.companySettings)
  const { item } = yield call(putRequest, url, { company: { delete_logo: 1 } })

  if (item) {
    yield put(updateCompanySettingsSuccess(item))
  }
}

export function* changeCompanyAltLogo({ file }) {
  yield put(loaderShow('companyAltLogo'))
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.companySettings)
  const { item } = yield call(upload, {
    file,
    path: url,
    name: 'company[alt_logo_attributes][image]',
    method: 'PUT'
  })
  yield put(loaderHide('companyAltLogo'))

  if (item) {
    yield put(updateCompanySettingsSuccess(item))
  }
}

export function* deleteCompanyAltLogo() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.companySettings)
  const { item } = yield call(putRequest, url, { company: { delete_alt_logo: 1 } })

  if (item) {
    yield put(updateCompanySettingsSuccess(item))
  }
}

export function* requestAttachmentsArchive() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.attachmentsArchive)
  yield call(requestDelayedDownload, { url })
}

export function* handleChangeCompanySettings(data) {
  yield put(loaderShow('companySettings'))
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.companySettings)
  const companyData = yield call(companySettingsMapping, data)
  const { item, errors } = yield call(putRequest, url, { company: companyData })
  yield put(loaderHide('companySettings'))

  yield put(resetSettingsErrors())

  if (item) {
    yield call([companySettingsCache, companySettingsCache.setPrev], item)

    if (item.site_name) {
      const newHost = yield call(updateHost, host, item.site_name)
      yield put(updateHostAction(newHost))
    }

    yield fork(showFlash, 'success', 'Company settings were updated')
  } else {
    yield put(updateSettingsErrors(companySettingsErrorsMapping(errors)))
  }
}

export function* changeCompanySettings() {
  const changeRequestChan = yield actionChannel(SYNC_COMPANY_SETTINGS_REQUEST, buffers.sliding(1))

  while (true) {
    yield take(changeRequestChan)

    const nextCompanySettings = yield select(getCompanySettings)
    yield call([companySettingsCache, companySettingsCache.setNext], nextCompanySettings)

    if (!deepEqual(companySettingsCache.next, companySettingsCache.prev)) {
      yield call(handleChangeCompanySettings, nextCompanySettings)
    }

    yield call([companySettingsCache, companySettingsCache.setPrev], nextCompanySettings)
  }
}

export function* watchCompanyUpdates() {
  yield [
    takeEvery(VALIDATE_SITE_NAME_REQUEST, validateCompanySiteName),
    takeEvery(CANCEL_SIGN_UP, cancelCompanySignUp),
    takeEvery(UPDATE_PLAN_REQUEST, updateCompanyPlan),
    takeEvery(UPDATE_COMPANY_SETTINGS_REQUEST, updateCompanySettings),
    takeEvery(CHANGE_COMPANY_LOGO_REQUEST, changeCompanyLogo),
    takeEvery(DELETE_COMPANY_LOGO_REQUEST, deleteCompanyLogo),
    takeEvery(CHANGE_COMPANY_ALT_LOGO_REQUEST, changeCompanyAltLogo),
    takeEvery(DELETE_COMPANY_ALT_LOGO_REQUEST, deleteCompanyAltLogo),
    takeEvery(EXPORT_ATTACHMENTS_REQUEST, requestAttachmentsArchive),
    call(changeCompanySettings)
  ]
}
