import {
  REGISTER_POPOVER,
  POPOVER_TOGGLE,
  POPOVER_SHOW,
  POPOVER_HIDE
} from '../actions/popovers'

export default function popovers(state = {}, action) {
  switch (action.type) {
  case REGISTER_POPOVER:
  case POPOVER_HIDE:
    return {
      ...state,
      [action.name]: false
    }
  case POPOVER_SHOW:
    return {
      [action.name]: true
    }
  case POPOVER_TOGGLE:
    const isVisible = !state[action.name]
    const newState = isVisible ? {} : {...state}

    return {
      ...newState,
      [action.name]: isVisible
    }
  default:
    return state
  }
}
