import { createAction } from '../lib/utils'

export const GET_HANDBOOKS_REQUEST = 'GET_HANDBOOKS_REQUEST'
export const GET_HANDBOOKS_SETTINGS_REQUEST = 'GET_HANDBOOKS_SETTINGS_REQUEST'
export const UPDATE_HANDBOOKS_SETTINGS_REQUEST = 'UPDATE_HANDBOOKS_SETTINGS_REQUEST'
export const GET_HANDBOOKS_SETTINGS_SUCCESS = 'GET_HANDBOOKS_SETTINGS_SUCCESS'
export const UPDATE_HANDBOOKS_SETTINGS_FIELD = 'UPDATE_HANDBOOKS_SETTINGS_FIELD'
export const UPDATE_HANDBOOK_REQUEST = 'UPDATE_HANDBOOK_REQUEST'
export const UPLOAD_HANDBOOK_REQUEST = 'UPLOAD_HANDBOOK_REQUEST'
export const DELETE_HANDBOOK_REQUEST = 'DELETE_HANDBOOK_REQUEST'
export const UPDATE_HANDBOOKS = 'UPDATE_HANDBOOKS'

export const getHandbooksRequest = createAction(GET_HANDBOOKS_REQUEST, 'url')
export const getHandbooksSettingsRequest = createAction(GET_HANDBOOKS_SETTINGS_REQUEST)
export const updateHandbooksSettingsRequest = createAction(UPDATE_HANDBOOKS_SETTINGS_REQUEST, 'settings')
export const getHandbooksSettingsSuccess = createAction(GET_HANDBOOKS_SETTINGS_SUCCESS, 'settings')
export const updateHandbooksSettingsField = createAction(UPDATE_HANDBOOKS_SETTINGS_FIELD, 'name', 'value')
export const updateHandbookRequest = createAction(UPDATE_HANDBOOK_REQUEST, 'page')
export const uploadHandbookRequest = createAction(UPLOAD_HANDBOOK_REQUEST, 'file')
export const deleteHandbookRequest = createAction(DELETE_HANDBOOK_REQUEST, 'url')
export const updateHandbooks = createAction(UPDATE_HANDBOOKS, 'collection')
