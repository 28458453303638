import { takeLatest } from 'redux-saga'
import { call, fork, put } from 'redux-saga/effects'
import routes from '../api/routes'
import { post, get } from '../api/utils'
import {
  CREATE_SUPPLIER_REQUEST,
  NEW_SUPPLIER_REQUEST,
  createSupplierSuccess,
  updateSupplier
} from '../actions/supplier'
import { updateDemandField } from '../actions/demand'
import { supplierMapping } from '../api/mappings'
import { showFlash } from './utils'

export function* submitSupplier({ value, afterCreate }) {
  const supplier = supplierMapping(value)
  const response = yield call(post, routes.suppliers, { supplier })

  yield put(createSupplierSuccess(response.supplier))
  yield put(updateDemandField('supplier', response.supplier))
  yield fork(showFlash, 'success', 'Supplier created')
  afterCreate && afterCreate()
}

export function* newEmptySupplier() {
  const response = yield call(get, routes.supplierNew)
  yield put(updateSupplier(response.supplier))
}

export function* watchSupplierUpdates() {
  yield [
    takeLatest(NEW_SUPPLIER_REQUEST, newEmptySupplier),
    takeLatest(CREATE_SUPPLIER_REQUEST, submitSupplier)
  ]
}
