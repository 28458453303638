import { lens, multi } from 'lorgnette'
import { isObject, isBoolean } from './utils'

export const dfs = (obj, filterFn) => {
  const paths = []

  const dfsIter = (path, obj, filterFn) => {
    Object.keys(obj).forEach(k => {
      const v = obj[k]

      if (isObject(v)) {
        dfsIter([...path, k], v, filterFn)
      } else if (filterFn(v)) {
        paths.push([...path, k])
      }
    })

    return paths
  }

  return dfsIter([], obj, filterFn)
}

export const updateIn = (obj, filterFn, updateFn) => {
  const paths = dfs(obj, filterFn)
  const lenses = paths.map(path => path.reduce((l, name) => l.prop(name), lens))
  const multiLens = multi(...lenses)
  const updateFns = Array.from({length: lenses.length}).map(() => updateFn)

  return multiLens.update(obj, ...updateFns)
}

export const convertBooleans = obj => updateIn(obj, isBoolean, v => v ? 1 : 0)
