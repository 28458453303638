import { createAction } from '../lib/utils'

export const GET_DEMAND_STATES_REQUEST = 'GET_DEMAND_STATES_REQUEST'
export const GET_DEMAND_STATES_SUCCESS = 'GET_DEMAND_STATES_SUCCESS'
export const UPDATE_DEMAND_STATE_REQUEST = 'UPDATE_DEMAND_STATE_REQUEST'
export const UPDATE_DEMAND_STATE_SUCCESS = 'UPDATE_DEMAND_STATE_SUCCESS'
export const UPDATE_DEMAND_STATE_ERRORS = 'UPDATE_DEMAND_STATE_ERRORS'

export const getDemandStatesRequest = createAction(GET_DEMAND_STATES_REQUEST)
export const getDemandStatesSuccess = createAction(GET_DEMAND_STATES_SUCCESS, 'collection')
export const updateDemandStateRequest = createAction(UPDATE_DEMAND_STATE_REQUEST, 'state', 'onSuccess')
export const updateDemandStateSuccess = createAction(UPDATE_DEMAND_STATE_SUCCESS, 'item')
export const updateDemandStateErrors = createAction(UPDATE_DEMAND_STATE_ERRORS, 'errors')

