import { put, select, call, fork } from 'redux-saga/effects'
import { delay, takeLatest } from 'redux-saga'
import routes from '../api/routes'
import { makeUrl, get, put as apiPut } from '../api/utils'
import { getHost, showFlash } from './utils'
import {
  UPDATE_UNITS_SETTINGS_REQUEST,
  CHANGE_UNITS_SETTINGS_REQUEST,
  updateUnitsSettingsSuccess,
  lockUnitsSwitches
} from '../actions/units'
import { unitsMapping } from '../api/mappings'
import { GET_OUTLAYS_SETTINGS_REQUEST } from '../actions/outlaysSettings'
import { loaderHide, loaderShow } from '../actions/loader'

export function* updateUnitsSettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.editUnitsSettings)
  const { item } = yield call(get, url)

  if (item) {
    yield put(updateUnitsSettingsSuccess(item))
  }
}

export function* changeUnitsSettings({ units, reload }) {
  yield call(delay, 2000)
  yield put(lockUnitsSwitches(true))
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.unitsSettings)
  const data = unitsMapping(units)
  yield put(loaderShow('unitsLoader'))
  const { item } = yield call(apiPut, url, data)
  yield put(loaderHide('unitsLoader'))

  if (item) {
    yield put(updateUnitsSettingsSuccess(item))
    yield fork(showFlash, 'success', 'Features were successfully updated')
    if (reload) {
      document.location.reload(true)
    }
  }
  yield put(lockUnitsSwitches(false))
}

export function* watchUnitsUpdates() {
  yield [
    takeLatest(GET_OUTLAYS_SETTINGS_REQUEST, updateUnitsSettings),
    takeLatest(UPDATE_UNITS_SETTINGS_REQUEST, updateUnitsSettings),
    takeLatest(CHANGE_UNITS_SETTINGS_REQUEST, changeUnitsSettings)
  ]
}
