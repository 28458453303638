import { createAction } from '../lib/utils'

export const UPDATE_DEMAND_REQUEST = 'UPDATE_DEMAND_REQUEST'
export const UPDATE_DEMAND = 'UPDATE_DEMAND'
export const NEW_DEMAND_REQUEST = 'NEW_DEMAND_REQUEST'
export const CLONE_DEMAND_REQUEST = 'CLONE_DEMAND_REQUEST'
export const CREATE_DEMAND_REQUEST = 'CREATE_DEMAND_REQUEST'
export const CHANGE_DEMAND_REQUEST = 'CHANGE_DEMAND_REQUEST'
export const ASSIGN_USER_TO_DEMAND_REQUEST = 'ASSIGN_USER_TO_DEMAND_REQUEST'
export const ADD_DEMAND_COMMENT = 'ADD_DEMAND_COMMENT'
export const CHANGE_DEMAND_STATE = 'CHANGE_DEMAND_STATE'
export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST'
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'
export const ADD_ATTACHMENTS_REQUEST = 'ADD_ATTACHMENTS_REQUEST'
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT'
export const DELETE_DEMAND_REQUEST = 'DELETE_DEMAND_REQUEST'
export const UPDATE_DEMAND_FIELDS = 'UPDATE_DEMAND_FIELDS'
export const UPDATE_DEMAND_FIELD = 'UPDATE_DEMAND_FIELD'
export const UPDATE_DEMAND_ERRORS = 'UPDATE_DEMAND_ERRORS'
export const RESET_DEMAND_ERRORS = 'RESET_DEMAND_ERRORS'
export const ADD_DEMAND_ITEM = 'ADD_DEMAND_ITEM'
export const DELETE_DEMAND_ITEM = 'DELETE_DEMAND_ITEM'
export const RESET_DEMAND_FIELDS = 'RESET_DEMAND_FIELDS'
export const SWITCH_DEMAND_ITEM_DELIVERED = 'SWITCH_DEMAND_ITEM_DELIVERED'
export const UPDATE_TIMEOFF_DATES = 'UPDATE_TIMEOFF_DATES'

export const updateDemandRequest = createAction(UPDATE_DEMAND_REQUEST, 'id')
export const updateDemand = createAction(UPDATE_DEMAND, 'data')
export const newDemandRequest = createAction(NEW_DEMAND_REQUEST)
export const cloneDemandRequest = createAction(CLONE_DEMAND_REQUEST, 'id')
export const createDemandRequest = createAction(CREATE_DEMAND_REQUEST, 'data', 'successUrl')
export const assignUserToDemandRequest = createAction(
  ASSIGN_USER_TO_DEMAND_REQUEST, 'user', 'demand'
)
export const addDemandComment = createAction(ADD_DEMAND_COMMENT, 'id', 'data')
export const changeDemandState = createAction(CHANGE_DEMAND_STATE, 'id', 'data')
export const deleteAttachmentRequest = createAction(
  DELETE_ATTACHMENT_REQUEST, 'demand', 'attachment'
)
export const deleteAttachment = createAction(DELETE_ATTACHMENT, 'attachment')
export const addAttachmentRequest = createAction(
  ADD_ATTACHMENTS_REQUEST, 'demand', 'attachments', 'rejectedAttachments'
)
export const addAttachment = createAction(ADD_ATTACHMENT, 'attachment')
export const deleteDemandRequest = createAction(DELETE_DEMAND_REQUEST, 'demand', 'successUrl')
export const updateDemandFields = createAction(UPDATE_DEMAND_FIELDS, 'data')
export const updateDemandField = createAction(UPDATE_DEMAND_FIELD, 'name', 'data')
export const updateDemandErrors = createAction(UPDATE_DEMAND_ERRORS, 'errors')
export const resetDemandErrors = createAction(RESET_DEMAND_ERRORS)
export const changeDemandRequest = createAction(CHANGE_DEMAND_REQUEST, 'demand', 'successUrl')
export const addDemandItem = createAction(ADD_DEMAND_ITEM, 'item')
export const deleteDemandItem = createAction(DELETE_DEMAND_ITEM, 'item')
export const resetDemandFields = createAction(RESET_DEMAND_FIELDS)
export const switchDemandItemDelivered = createAction(SWITCH_DEMAND_ITEM_DELIVERED, 'item')
export const updateTimeoffDates = createAction(UPDATE_TIMEOFF_DATES, 'dates')
