import React, { Component } from 'react'
import ResetPasswordForm from './Form'

class Password extends Component {
  render() {
    return (
      <div>
        <div className='b-title b-title_center'>
          <h2 className='b-title__main-title'>
            Forgotten your password?
          </h2>
          <div className='b-title__subtitle b-preline'>
            {`Please enter the email address you used to sign up
for Turbine and we will send a password reset email.`}
          </div>
        </div>

        <ResetPasswordForm />
      </div>
    )
  }
}

export default Password
