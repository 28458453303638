import { lens } from 'lorgnette'
import {
  ADD_BLACKOUT_CONFIG_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  ADD_CONFIG_SUCCESS,
  DELETE_BLACKOUT_CONFIG_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CONFIG_SUCCESS,
  GET_TIMEOFFS_SETTINGS_SUCCESS,
  UPDATE_BLACKOUT_CONFIG_REQUEST,
  UPDATE_BLACKOUT_CONFIG_SUCCESS,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CONFIG_REQUEST
} from '../actions/timeoffSettings'

const initialState = {
  categories: [],
  configs: [],
  blackout_configs: [],
  config_options: {
    accrual: [],
    untaken: [],
    expiration: []
  }
}
const categoryByIdLens = id => lens.prop('categories').find(c => c.id === id)
const configByIdLens = id => lens.prop('configs').find(c => c.id === id)
const blackoutConfigsByIdLens = id => lens.prop('blackout_configs').find(c => c.id === id)

export default function timeoffSettings(state = initialState, action) {
  switch(action.type) {
  case GET_TIMEOFFS_SETTINGS_SUCCESS:
    return {
      ...state,
      ...action.data
    }
  case ADD_BLACKOUT_CONFIG_SUCCESS:
    return {
      ...state,
      blackout_configs: [...state.blackout_configs, action.item]
    }
  case ADD_CATEGORY_SUCCESS:
    return {
      ...state,
      categories: [...state.categories, action.item]
    }
  case ADD_CONFIG_SUCCESS:
    return {
      ...state,
      configs: [...state.configs, action.item]
    }
  case DELETE_BLACKOUT_CONFIG_SUCCESS:
    return {
      ...state,
      blackout_configs: state.blackout_configs.filter(c => c.id !== action.item.id)
    }
  case DELETE_CATEGORY_SUCCESS:
    return {
      ...state,
      categories: state.categories.filter(c => c.id !== action.item.id)
    }
  case DELETE_CONFIG_SUCCESS:
    return {
      ...state,
      configs: state.configs.filter(c => c.id !== action.item.id)
    }
  case UPDATE_BLACKOUT_CONFIG_REQUEST:
    return blackoutConfigsByIdLens(action.blackoutConfig.id).update(state, item => ({ ...item, ...action.blackoutConfig }))
  case UPDATE_BLACKOUT_CONFIG_SUCCESS:
    return blackoutConfigsByIdLens(action.item.id).update(state, item => ({ ...item, ...action.item }))
  case UPDATE_CATEGORY_REQUEST:
    return categoryByIdLens(action.category.id).update(state, item => ({ ...item, ...action.category }))
  case UPDATE_CONFIG_REQUEST:
    return configByIdLens(action.config.id).update(state, item => ({ ...item, ...action.config }))
  default:
    return state
  }
}
