/* eslint camelcase: 0 */

import { takeEvery } from 'redux-saga'
import { call, put, select, fork } from 'redux-saga/effects'
import {
  GET_ACTIVITIES_SETTINGS_REQUEST,
  ADD_POINT_CATEGORY_REQUEST,
  ADD_POINT_REQUEST,
  ADD_CLIENT_REQUEST,
  CONFIRM_ACTIVITIES_REQUEST,
  CONFIRM_POINTS_REQUEST,
  CONFIRM_CLIENTS_REQUEST,
  DELETE_POINT_CATEGORY_REQUEST,
  DELETE_POINT_REQUEST,
  DELETE_CLIENT_REQUEST,
  UPDATE_POINT_CATEGORY_REQUEST,
  UPDATE_POINT_REQUEST,
  UPDATE_CLIENT_REQUEST,
  UPLOAD_ACTIVITIES_REQUEST,
  UPLOAD_POINTS_REQUEST,
  UPLOAD_CLIENTS_REQUEST,
  getActivitiesSettingsSuccess,
  addPointCategorySuccess,
  addPointSuccess,
  addClientSuccess,
  deletePointCategorySuccess,
  deletePointSuccess,
  deleteClientSuccess,
  updatePointCategorySuccess,
  updatePointSuccess,
  updateClientSuccess,
  updateBulkUploaded
} from '../actions/activitiesSettings'
import routes from '../api/routes'
import { makeUrl, get, del, put as apiPut, post, upload } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import {
  pointsMapping,
  clientsMapping,
  activitiesBulkMapping,
  categoriesMapping,
  pointsBulkMapping,
  clientsBulkMapping
} from '../api/mappings'
import { loaderHide, loaderShow } from '../actions/loader'

export function* getSettings() {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.activitiesSettings)
  const { settings } = yield call(get, url)
  if (settings) {
    yield put(getActivitiesSettingsSuccess(settings))
  }
}

export function* uploadActivities({ file }) {
  yield put(loaderShow('activitiesUpload'))
  const { success, flash, data } = yield call(
    upload,
    {
      path: routes.bulkActivities,
      file,
      name: 'csv_file'
    }
  )

  if (success) {
    yield put(updateBulkUploaded({ activities: data }))
    yield fork(showFlash, 'success', flash)
  } else {
    yield fork(showFlash, 'error', flash)
  }
  yield put(loaderHide('activitiesUpload'))
}

export function* confirmActivities({ data, next }) {
  yield put(loaderShow('confirmBulkUpload'))
  const { success, flash } = yield call(
    post,
    routes.bulkActivities,
    {
      activities_parameters_confirmation: 1,
      activities_parameters: activitiesBulkMapping(data)
    }
  )

  if (success) {
    yield fork(showFlash, 'success', flash)
    window.location.reload()
  } else {
    yield fork(showFlash, 'error', flash)
  }

  yield put(updateBulkUploaded({}))
  yield put(loaderHide('confirmBulkUpload'))
  next && next()
}

export function* addPointCategory({ category }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.categoriesSettings)
  const data = categoriesMapping(category)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Category added')
    yield put(addPointCategorySuccess(item))
  }
}

export function* deletePointCategory({ category }) {
  const deleteConfirmed = yield call(proceed)
  if (!deleteConfirmed) {
    return
  }
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.categorySettings, { id: category.id })
  const { item, errors } = yield call(del, url)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Category deleted')
    yield put(deletePointCategorySuccess(item))
  }
}

export function* updatePointCategory({ category }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.categorySettings, { id: category.id })
  const data = categoriesMapping(category)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Category updated')
    yield put(updatePointCategorySuccess(item))
  }
}

export function* addPoint({ point }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.pointsSettings)
  const data = pointsMapping(point)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Point added')
    yield put(addPointSuccess(item))
  }
}

export function* deletePoint({ point }) {
  const deleteConfirmed = yield call(proceed)
  if (!deleteConfirmed) {
    return
  }
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.pointSettings, { id: point.id })
  const { item, errors } = yield call(del, url)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Point deleted')
    yield put(deletePointSuccess(item))
  }
}

export function* updatePoint({ point }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.pointSettings, { id: point.id })
  const data = pointsMapping(point)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Point updated')
    yield put(updatePointSuccess(item))
  }
}

export function* uploadPoints({ file }) {
  yield put(loaderShow('pointsUpload'))
  const { success, flash, data } = yield call(
    upload, { path: routes.bulkPoints, file, name: 'csv_file' })
  yield put(updateBulkUploaded({ points: data }))
  yield put(loaderHide('pointsUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
}

export function* confirmPoints({ data, next }) {
  yield put(loaderShow('confirmBulkUpload'))
  const { success, flash } = yield call(
    post,
    routes.bulkPoints,
    {
      points_parameters_confirmation: 1,
      points_parameters: pointsBulkMapping(data)
    }
  )
  yield put(loaderHide('confirmBulkUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
  yield put(updateBulkUploaded({}))
  next && next()
}

export function* addClient({ client, after }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.clientsSettings)
  const data = clientsMapping(client)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Client added')
    yield put(after())
    yield put(addClientSuccess(item))
  }
}

export function* deleteClient({ client }) {
  const deleteConfirmed = yield call(proceed)
  if (!deleteConfirmed) {
    return
  }
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.clientSettings, { id: client.id })
  const { item, errors } = yield call(del, url)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Client deleted')
    yield put(deleteClientSuccess(item))
  }
}

export function* updateClient({ client, after }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.clientSettings, { id: client.id })
  const data = clientsMapping(client)
  const { item, errors } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Client updated')
    yield put(after())
    yield put(updateClientSuccess(item))
  }
}

export function* uploadClients({ file }) {
  yield put(loaderShow('clientsUpload'))
  const { success, flash, data } = yield call(
    upload,
    {
      path: routes.bulkClients,
      name: 'csv_file',
      file
    }
  )
  yield put(updateBulkUploaded({ clients: data }))
  yield put(loaderHide('clientsUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
}

export function* confirmClients({ data, next }) {
  yield put(loaderShow('confirmBulkUpload'))
  const { success, flash } = yield call(
    post,
    routes.bulkClients,
    {
      clients_parameters_confirmation: 1,
      clients_parameters: clientsBulkMapping(data)
    }
  )
  yield put(loaderHide('confirmBulkUpload'))
  yield fork(showFlash, success ? 'success' : 'error', flash)
  yield put(updateBulkUploaded({}))
  next && next()
}

export function* watchActivitiesSettingsUpdates() {
  yield [
    takeEvery(GET_ACTIVITIES_SETTINGS_REQUEST, getSettings),
    takeEvery(ADD_POINT_CATEGORY_REQUEST, addPointCategory),
    takeEvery(ADD_POINT_REQUEST, addPoint),
    takeEvery(ADD_CLIENT_REQUEST, addClient),
    takeEvery(CONFIRM_ACTIVITIES_REQUEST, confirmActivities),
    takeEvery(CONFIRM_POINTS_REQUEST, confirmPoints),
    takeEvery(CONFIRM_CLIENTS_REQUEST, confirmClients),
    takeEvery(DELETE_POINT_CATEGORY_REQUEST, deletePointCategory),
    takeEvery(DELETE_POINT_REQUEST, deletePoint),
    takeEvery(DELETE_CLIENT_REQUEST, deleteClient),
    takeEvery(UPDATE_POINT_CATEGORY_REQUEST, updatePointCategory),
    takeEvery(UPDATE_POINT_REQUEST, updatePoint),
    takeEvery(UPDATE_CLIENT_REQUEST, updateClient),
    takeEvery(UPLOAD_ACTIVITIES_REQUEST, uploadActivities),
    takeEvery(UPLOAD_POINTS_REQUEST, uploadPoints),
    takeEvery(UPLOAD_CLIENTS_REQUEST, uploadClients)
  ]
}
