import { takeLatest, takeEvery, delay } from 'redux-saga'
import { call, put, select, fork } from 'redux-saga/effects'
import routes from '../api/routes'
import { proceed, getHost, showFlash, allErrors } from './utils'
import { makeUrl, del, upload, post, put as putRequest } from '../api/utils'
import { isEmpty } from '../lib/utils'
import { daysToTimeOffDates } from '../lib/timeoffs'
import { deleteAttachment, addAttachment } from '../actions/demand'
import { loaderShow, loaderHide } from '../actions/loader'
import { demandMapping } from '../api/mappings'
import {
  UPDATE_DEMAND_REQUEST,
  NEW_DEMAND_REQUEST,
  CLONE_DEMAND_REQUEST,
  ASSIGN_USER_TO_DEMAND_REQUEST,
  ADD_DEMAND_COMMENT,
  CHANGE_DEMAND_STATE,
  CHANGE_DEMAND_REQUEST,
  CREATE_DEMAND_REQUEST,
  DELETE_ATTACHMENT_REQUEST,
  ADD_ATTACHMENTS_REQUEST,
  DELETE_DEMAND_REQUEST,
  SWITCH_DEMAND_ITEM_DELIVERED,
  updateDemand,
  updateDemandErrors,
  updateTimeoffDates,
  resetDemandErrors,
  resetDemandFields
} from '../actions/demand'
import { deleteDemandsItem, prependDemandsItem, updateDemandsRequest } from '../actions/demands'
import {
  fetchCollectionItem,
  fetchNewCollectionItem,
  fetchClonedCollectionItem,
  createCollectionItemChild,
  createCollectionItem,
  changeCollectionItem,
  deleteCollectionItem
} from './utils'

// Wait for transaction commit.
const REDIRECT_DELAY = 500

export function *fetchDemand({id}) {
  const { item } = yield call(fetchCollectionItem, id)
  yield put(updateDemand(item))
  if (item.days) {
    yield put(updateTimeoffDates(daysToTimeOffDates(item.days)))
  }
  yield put(resetDemandErrors())
}

export function *fetchNewDemand() {
  const { item } = yield call(fetchNewCollectionItem)
  yield put(resetDemandFields())
  yield put(updateDemand(item))

  yield put(resetDemandErrors())
}

export function *assignUserToDemand({ demand, user }) {
  if (demand.user.id === user.id) return;

  const message = `Are you sure to re-assign this ${demand.type} from ${demand.user.name} to ${user.name}?`
  const isAssignConfirmed = yield call(proceed, message)

  if (isAssignConfirmed) {
    const host = yield select(getHost)
    const url = yield call(makeUrl, host, routes.activityUser, { activity_id: demand.id })
    const { item, success } = yield call(putRequest, url, { user_id: user.id })

    if (success) {
      yield fork(showFlash, 'success', 'Employee successfully assigned')
      yield put(updateDemand(item))
    } else {
      yield fork(showFlash, 'error', 'Failed to assign employee')
    }
  }
}

export function *addDemandComment({id, data}) {
  const demandPart = yield call(createCollectionItemChild, id, routes.demandComments, data)
  yield put(updateDemand(demandPart))
}

export function *changeDemandState({id, data}) {
  const { item } = yield call(createCollectionItemChild, id, routes.demandStates, data)
  const newState = item.state.caption
  yield fork(showFlash, 'success', `Request state was changed to ${newState}`)
  yield put(updateDemand(item))
}

export function *switchItemDeliveryState({ item }) {
  const response = yield post(item.switch_delivery_url, { item: item.id })
  yield put(updateDemand(response))
}

export function *deleteAttachmentProcess({ demand, attachment }) {
  const deleteConfirmed = yield call(proceed)

  if (deleteConfirmed) {
    const host = yield select(getHost)
    const apiUrl = demand.id ?
      makeUrl(host, routes.demandAttachment, {
        demand_id: demand.id,
        id: attachment.id
      }) :
      makeUrl(host, routes.attachment, { id: attachment.id })
    const response = yield del(apiUrl)

    if (response.success) {
      yield put(deleteAttachment(attachment))
      yield put(updateDemand(response.demand))
    }
  }
}

export function *addAttachmentProcess(attach, apiUrl) {
  yield put(loaderShow('demandAttachments'))
  const response = yield call(upload, { path: apiUrl, file: attach })

  if (response.success) {
    yield put(addAttachment(response.attachment))
    yield put(updateDemand(response.demand))
  }

  yield put(loaderHide('demandAttachments'))
}

export function *addAttachmentsProcess({demand, attachments, rejectedAttachments}) {
  const host = yield select(getHost)
  const apiUrl = demand.id ?
    makeUrl(host, routes.demandAttachmentsNew, { demand_id: demand.id }) :
    makeUrl(host, routes.attachments)
  yield attachments.map(attach => fork(addAttachmentProcess, attach, apiUrl))

  if (!isEmpty(rejectedAttachments)) {
    yield fork(showFlash, 'error', 'File size should be less than 6MB')
  }
}

export function *deleteDemandProcess({router}, {demand, successUrl}) {
  const deleteConfirmed = yield call(proceed)

  if (deleteConfirmed) {
    const response = yield call(deleteCollectionItem, demand.id)

    if (response.success) {
      yield fork(showFlash, 'success', 'Request deleted')
      yield put(deleteDemandsItem(demand))
      if(demand.type==='TimeOff' && successUrl==='/schedule') {
        yield put(updateDemandsRequest())
      }
      yield call([router, router.transitionTo], successUrl)
    } else {
      yield fork(showFlash, 'error', 'Request deleting error')
    }
  }
}

export function *changeDemandProcess({router}, {demand, successUrl}) {
  const { success, item, errors } = yield call(changeCollectionItem, demand.id, {
    demand: demandMapping(demand)
  })

  if (success) {
    yield fork(showFlash, 'success', 'Request saved')
    yield put(updateDemand(item))
    yield put(updateDemandErrors({}))
    yield call([router, router.transitionTo], successUrl)
  } else {
    yield fork(showFlash, 'error', allErrors(errors))
    yield put(updateDemandErrors(errors))
  }
}

export function *createDemandProcess({router}, {data, successUrl}) {
  const { success, item, errors } = yield call(createCollectionItem, {
    demand: demandMapping(data)
  })

  if (success) {
    yield fork(showFlash, 'success', 'Request created')
    yield put(updateDemand(item))
    yield put(prependDemandsItem(item))
    yield put(updateDemandErrors({}))
    yield call(delay, REDIRECT_DELAY)
    yield call([router, router.transitionTo], makeUrl('', successUrl, { id: item.id }))
  } else {
    yield fork(showFlash, 'error', allErrors(errors))
    yield put(updateDemandErrors(errors))
  }
}

export function *cloneDemandProcess({id}) {
  const { success, item } = yield call(fetchClonedCollectionItem, id)

  if (success) {
    yield put(updateDemand(item))
  } else {
    yield fork(showFlash, 'error', 'Request clone error')
  }
}

export function *watchDemandUpdates(context) {
  yield [
    takeLatest(UPDATE_DEMAND_REQUEST, fetchDemand),
    takeEvery(NEW_DEMAND_REQUEST, fetchNewDemand),
    takeEvery(CLONE_DEMAND_REQUEST, cloneDemandProcess),
    takeEvery(CHANGE_DEMAND_REQUEST, changeDemandProcess, context),
    takeEvery(CREATE_DEMAND_REQUEST, createDemandProcess, context),
    takeEvery(ASSIGN_USER_TO_DEMAND_REQUEST, assignUserToDemand),
    takeEvery(ADD_DEMAND_COMMENT, addDemandComment),
    takeEvery(CHANGE_DEMAND_STATE, changeDemandState),
    takeEvery(DELETE_ATTACHMENT_REQUEST, deleteAttachmentProcess),
    takeEvery(ADD_ATTACHMENTS_REQUEST, addAttachmentsProcess),
    takeEvery(DELETE_DEMAND_REQUEST, deleteDemandProcess, context),
    takeEvery(SWITCH_DEMAND_ITEM_DELIVERED, switchItemDeliveryState)
  ]
}
