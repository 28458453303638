import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { flashHide } from '../../../actions/flash'

const mapStateToProps = state => ({
  flash: state.flash
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ flashHide }, dispatch)
)

export class Flash extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    flash: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      kind: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired,
    flashHide: PropTypes.func.isRequired
  }

  onClose() {
    const { onClose, flashHide } = this.props

    flashHide()
    if (onClose) {
      onClose()
    }
  }

  render() {
    const { flash } = this.props

    return (
      <div>
        {flash.active &&
         <div className='b-flash'>
           <span className={`b-flash__text b-flash__text_${flash.kind}`}>
             {flash.text}
           </span>
           <span className='b-flash__button' onClick={() => this.onClose()}>
           </span>
         </div>}
      </div>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Flash)
