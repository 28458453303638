import { lens } from 'lorgnette'
import {
  ADD_CUSTOM_FIELD_SUCCESS,
  GET_CUSTOM_FIELDS_SUCCESS,
  DELETE_CUSTOM_FIELD_SUCCESS,
  UPDATE_CUSTOM_FIELD_SUCCESS
} from '../actions/customFields'

const initialState = {
  collection: [],
  errors: {}
}
const fieldByIdLens = id => lens.prop('collection').find(s => s.id === id)

export default function (state = initialState, action) {
  switch(action.type) {
  case GET_CUSTOM_FIELDS_SUCCESS:
    return {
      ...state,
      collection: action.collection
    }
  case ADD_CUSTOM_FIELD_SUCCESS:
    return {
      ...state,
      collection: [...state.collection, action.item]
    }
  case DELETE_CUSTOM_FIELD_SUCCESS:
    return {
      ...state,
      collection: state.collection.filter(s => s.id !== action.item.id)
    }
  case UPDATE_CUSTOM_FIELD_SUCCESS:
    return fieldByIdLens(action.item.id).update(state, item => ({ ...item, ...action.item }))
  default:
    return state
  }
}
