/* eslint camelcase: 0 */

import lens from 'lorgnette'
import moment from 'moment'
import { isUndefined, filterProps, isUID } from '../lib/utils'
import { convertBooleans } from '../lib/walkObject'

const DATE_FORMAT = 'DD-MM-YYYY'

export const blackoutConfigMapping = config => (
  {
    blackout_config: {
      name: config.name,
      blackout_days: config.blackouts
    }
  }
)

export const bulkEmployeeDateMapping = ({ attributes, items = [] }, format) => ({
  attributes,
  items: items.map(item => ({
    ...item,
    date_of_birth: item.date_of_birth ? moment(item.date_of_birth).format(format) : null,
    joined_at: item.joined_at ? moment(item.joined_at).format(format) : null
  }))
})

export const demandMapping = demand =>
  demand.type === 'TimeOff' ?
    {
      dates: demand.dates,
      demand_type_id: demand.demand_type.id,
      attachment_ids: demand.attachments.map(a => a.id),
      notes: demand.notes
    }
    :
    demand.type === 'Activity' ?
      {
        point_id: demand.point.id,
        client_id: demand.client.id,
        notes: demand.notes,
        delivery_date: demand.delivery_date,
        quantity: demand.quantity,
        commissioned_word_count: demand.commissioned_word_count,
        actual_word_count: demand.actual_word_count,
        attachment_ids: demand.attachments.map(a => a.id),
        values_attributes: valuesAttributesMapping(demand.values_attributes),
        is_draft: demand.is_draft
      }
      :
      {
        title: demand.title,
        details: demand.details,
        additional_info: demand.additional_info,
        values_attributes: valuesAttributesMapping(demand.values_attributes),
        cost_center_id: demand.cost_center.id,
        supplier_id: demand.supplier.id,
        line_items_attributes: demand.items,
        attachment_ids: demand.attachments.map(a => a.id),
        is_draft: demand.is_draft
      }

export const valuesAttributesMapping = valuesAttributes => (
  valuesAttributes
    .map(attr =>
      attr.value && attr.kind === 'dropdown' ?
        {
          ...attr,
          ...{ variant_id: (attr.variants.find(variant => variant.value === attr.value) || {}).id || attr.variant_id }
        }
        : attr))

export const supplierMapping = supplier => (
  lens
    .prop('address_attributes', {})
    .prop('country_code', {})
    .update(supplier, country_code => country_code.id)
)

export const calendarParamsMapping = (calendarParams, { filter = () => true, withActive = true, blackouts } = {}) => {
  if (isUndefined(calendarParams)) {
    return {}
  }

  const mapping = calendarParams.filter(filter).reduce((acc, param) => {
    const state = withActive && param.selected ? 'active' : param.demand_state_name
    const calendarDays = param.calendar_days.map(d => {
      d.date = moment(d.date, DATE_FORMAT).hour(12)
      return d
    })

    acc[state] = (acc[state] || []).concat(calendarDays)
    return acc
  }, {})

  if (blackouts) {
    mapping.blackout = blackouts.map(date => ({ date: moment(date, DATE_FORMAT), duration: 1 }))
  }

  return mapping
}

export const calendarSelectedParamsMapping = calendarParams => (
  calendarParamsMapping(calendarParams, { filter: param => param.selected, withActive: false })
)

export const teamMapping = team => ({
  name: team.name,
  team_demand_types_attributes: team.team_demand_type
})

export const employeeEmailConfigMapping = config => (
  {
    user: {
      user_email_configs_attributes: Object.keys(config).map(k => (
        {
          id: config[k].id,
          enabled: config[k].enabled
        }))
    }
  }
)

export const employeeEmailDigestConfigMapping = e => (
  {
    user: {
      id: e.id,
      email_digest: e.email_digest
    }
  }
)

export const employeeMapping = e => (
  {
    user: {
      first_name: e.first_name,
      last_name: e.last_name,
      job_title: e.job_title,
      joined_at: e.joined_at,
      blackout_config_id: e.blackout_config_id,
      config_id: e.config_id,
      email: e.email,
      personal_email_address: e.personal_email_address,
      phone_number: e.phone_number,
      date_of_birth: e.date_of_birth,
      notes: e.notes,
      email_digest: e.email_digest,
      public_notes: e.public_notes,
      address_attributes: {
        id: e.address.id,
        postal_code: e.address.postal_code,
        address1: e.address.address1,
        address2: e.address.address2,
        city: e.address.city,
        state: e.address.state,
        country_code: e.address.country_code
      },
      values_attributes: e.values_attributes
        .map(attr =>
          attr.value && attr.kind === 'dropdown' ?
            {
              ...attr,
              ...{ variant_id: (attr.variants.find(variant => variant.value === attr.value) || {}).id || attr.variant_id }
            }
            : attr),
      delegated_permissions: e.delegated_permissions.length > 0 ?
                             e.delegated_permissions : ['']
    }
  }
)

export const employeesBulkMapping = users => (
  users.map(u => ({
    first_name: u.first_name,
    last_name: u.last_name,
    job_title: u.job_title,
    joined_at: u.joined_at,
    email: u.email,
    personal_email_address: u.personal_email_address,
    phone_number: u.phone_number,
    holiday_days_count: u.holiday_days_count,
    date_of_birth: u.date_of_birth,
    notes: u.notes,
    public_notes: u.public_notes
  })))

export const companySettingsMapping = company => {
  return convertBooleans(filterProps(company, { blacklist: ['logo_attributes', 'alt_logo_attributes'] }))
}

export const companySettingsErrorsMapping = errors => {
  const keysMapping = {
    address: 'address_attributes'
  }

  const keys = Object.keys(keysMapping)

  return Object.keys(errors).reduce((res, oldKey) => {
    const keyMatch = keys.find(k => oldKey.replace(k, keysMapping[k]) !== oldKey)
    const newKey = keyMatch ? oldKey.replace(keyMatch, keysMapping[keyMatch]) : oldKey

    res[newKey] = errors[oldKey]
    return res
  }, {})
}

export const unitsMapping = units => ({
  company_config: {
    unit_time_offs_enabled: units.unit_time_offs_enabled ? 1 : 0,
    unit_purchases_enabled: units.unit_purchases_enabled ? 1 : 0,
    unit_expenses_enabled: units.unit_expenses_enabled ? 1 : 0,
    unit_reports_enabled: units.unit_reports_enabled ? 1 : 0,
    unit_two_factor_purchase_enabled: units.unit_two_factor_purchase_enabled ? 1 : 0,
    unit_two_factor_expense_enabled: units.unit_two_factor_expense_enabled ? 1 : 0,
    unit_two_factor_purchase_threshold: +units.unit_two_factor_purchase_threshold || 0,
    unit_two_factor_expense_threshold: +units.unit_two_factor_expense_threshold || 0,
    unit_auto_approval_purchase_enabled: units.unit_auto_approval_purchase_enabled ? 1 : 0,
    unit_auto_approval_expense_enabled: units.unit_auto_approval_expense_enabled ? 1 : 0,
    slackify: units.slackify ? 1 : 0,
    allow_multiple_accounts: units.allow_multiple_accounts ? 1 : 0,
    zapierify: units.zapierify ? 1 : 0,
    teammates_can_see_time_offs: units.teammates_can_see_time_offs ? 1 : 0,
    managers_create_requests_for_subordinates: units.managers_create_requests_for_subordinates ? 1 : 0,
    allow_manager_to_add_extra_holidays: units.allow_manager_to_add_extra_holidays ? 1 : 0,
    put_users_suppliers_to_master_list: units.put_users_suppliers_to_master_list ? 1 : 0
  }
})

export const demandTypeMapping = data => ({
  demand_type: {
    limited: data.limited ? 1 : 0,
    enabled: data.enabled ? 1 : 0,
    type: data.type,
    name: data.name
  }
})

export const demandStateMapping = data => ({
  demand_state: {
    name: data.name,
    caption: data.caption,
    description: data.description,
    balancing: data.balancing ? 1 : 0,
    color: data.color,
    demand_kind: data.demand_kind,
    order_index: data.order_index
  }
})

export const userConfigMapping = data => ({
  user_config: {
    name: data.name,
    time_offs_enable_maximum_days_per_year: data.time_offs_enable_maximum_days_per_year,
    time_offs_maximum_days_per_year: data.time_offs_maximum_days_per_year,
    user_may_request_overdraft_days: data.user_may_request_overdraft_days,
    time_offs_expiration_date_rule: data.time_offs_expiration_date_rule,
    time_offs_expiration_date: data.time_offs_expiration_date,
    accrual_method: data.accrual_method,
    holiday_days_count_end_year_action: data.holiday_days_count_end_year_action
  }
})

export const outlayPrintableMapping = data => ({
  company: {
    email: data.email,
    config_attributes: {
      demand_order_footer: data.demand_order_footer,
      demand_order_header: data.demand_order_header,
      id: data.id,
      identifier_approval: data.identifier_approval ? 1 : 0,
      part_deliver_po: data.part_deliver_po ? 1 : 0,
      tax: data.tax,
      users_can_add_supplier: data.users_can_add_supplier ? 1 : 0
    }
  }
})

export const localesMapping = data => {
  const locales = { id: {} }
  data.forEach(({ id, name, value }) => {
    locales.id[id] = { name, value }
  })
  return { locales }
}

export const customFieldMapping = (data, type = 'demand') => ({
  [`field_${type}_option`]: {
    title: data.title,
    kind: data.kind,
    demand_kind: data.demand_kind,
    required: data.required ? 1 : 0,
    dynamic_variants: data.kind === 'dropdown'
      ? data.variants.map(v => v.value)
      : null
  }
})

export const virtualsMapping = config => ({
  virtual_config: {
    model_type: config.type,
    available: config.available ? 1 : 0,
    kind: config.kind,
    items_attributes: config.items.map(item => ({
      _destroy: item._destroy,
      title: item.title,
      lock_title: item.lock_title,
      cost: item.cost,
      lock_cost: item.lock_cost,
      count: item.count,
      lock_count: item.lock_count,
      tax: item.tax,
      lock_tax: item.lock_tax,
      id: isUID(item.id) ? null : item.id
    }))
  }
})

export const costCentersMapping = data => ({
  cost_center: {
    name: data.name,
    allow_budgeting: data.allow_budgeting ? 1 : 0,
    budget: data.allow_budgeting ? data.budget : 0,
    currency_code: data.currency_code,
    temp: data.temp ? 1 : 0
  }
})

export const suppliersMapping = data => ({
  supplier: {
    name: data.name,
    email: data.email,
    contact_email: data.contact_email,
    phone_number: data.phone_number,
    temp: data.temp ? 1 : 0,
    budget: data.allow_budgeting ? data.budget : 0,
    address_attributes: data.address
  }
})

export const categoriesMapping = data => ({
  category: {
    name: data.name
  }
})

export const pointsMapping = data => ({
  point: {
    name: data.name,
    category_id: data.category_id,
    point_count: data.point_count
  }
})

export const clientsMapping = data => ({
  client: {
    name: data.name,
    point_count: data.point_count,
    temp: data.temp ? 1 : 0,
    accruable: data.accruable ? 1 : 0,
    accrual_config_attributes: {
      ...data.accrual_config,
      name: `Client '${data.name}' config`
    }
  }
})

export const rolesMapping = data => ({
  financier_user_ids: data.financier,
  buyer_user_ids: data.buyer,
  admin_user_ids: data.admin,
  payer_user_ids: data.payer,
  finance_payer_user_ids: data.finance_payer
})

export const costCentersBulkMapping = items => (
  items.map(i => ({
    name: i.name,
    currency_code: i.currency_code,
    allow_budgeting: i.allow_budgeting ? 1 : 0,
    budget: i.budget
  })))

export const suppliersBulkMapping = items => (
  items.map(i => ({
    name: i.name,
    email: i.email,
    contact_email: i.contact_email,
    address1: i.address1,
    address2: i.address2,
    country_code: i.country_code,
    city: i.city,
    state: i.state,
    postal_code: i.postal_code,
    phone_number: i.phone_number
  })))

export const activitiesBulkMapping = items => (
  items.map(i => ({
    description: i.description,
    quantity: i.quantity,
    client_name: i.client_name,
    commissioned_word_count: i.commissioned_word_count,
    actual_word_count: i.actual_word_count,
    delivery_date: i.delivery_date,
    employee_email: i.employee_email,
    point_name: i.point_name
  })))

export const pointsBulkMapping = items => (
  items.map(i => ({
    name: i.name,
    point_count: i.point_count
  })))

export const clientsBulkMapping = items => (
  items.map(i => ({
    name: i.name,
    point_count: i.point_count
  })))

export const handbooksSettingsMapping = data => ({
  company: {
    config_attributes: {
      handbook_kind: data.handbook_kind.id,
      id: data.id,
      handbook_url: data.handbook_url,
      handbook_upload_attributes: { id: data.uploaded.id }
    }
  }
})

export const handbookMapping = data => ({
  page: {
    id: data.id,
    title: data.title.toString(),
    folder_name: data.folder_name,
    body: data.body.toString(),
    url: data.url ? data.url.toString() : null,
    company_id: data.company_id
  }
})
