import { serialize, isNumeric, isPrimitive } from '../lib/utils'

const isString = {
  phone_number: true,
  title: true,
  name: true
}

const isResponseFail = response => [404, 500].includes(response.status)

export const isQueryStringPresent = path => {
  let queryString = path.substring(path.indexOf('?') + 1)
  return queryString !== path
}

const defaultHeaders = (method) => {
  const headers = {
    'Content-type': 'application/json; charset=UTF-8'
  }

  if (method !== 'GET') {
    headers['Accept'] = 'application/json'
  }

  return headers
}

const withSortedQuery = (url) => {
  let newUrl = url
  try {
    newUrl = new URL(url)
    newUrl.searchParams.sort()

    return newUrl.toString()
  } catch (error) {
    return url
  }
}

export const apiCall = (path, options) => (
  fetch(withSortedQuery(path), {
    ...options,
    credentials: 'include',
    headers: defaultHeaders(options.method)
  })
    .then(r => (isResponseFail(r) ? redirect(`/${r.status}?from=${r.url}`) : r))
    .then(r => r.text())
    .then(r => parseResponse(r)))

export const parseResponse = (r) => JSON.parse(r, (k, v) => isPrimitive(v) && !isString[k] && isNumeric(v) ? parseFloat(v) : v)

export const post = (path, data) => apiCall(path, {
  method: 'POST',
  body: JSON.stringify(data)
})

export const get = path => apiCall(path, {
  method: 'GET',
  mode: 'no-cors'
})

export const del = path => apiCall(path, {
  method: 'DELETE'
})

export const put = (path, data) => apiCall(path, {
  method: 'PUT',
  body: JSON.stringify(data)
})

export const upload = ({ path, file, method = 'POST', name = 'file' }) => {
  const data = new FormData()
  data.append(name, file)

  return fetch(path, {
    method: method,
    credentials: 'include',
    body: data
  })
    .then(r => r.json())
}

const interpolateUrl = (url, params) => {
  const result = []
  const newParams = { ...params }

  url.split('/:').forEach((segment, i) => {
    if (i === 0) {
      return result.push(segment)
    }

    let segmentMatch = segment.match(/(\w+)(?:[?*])?(.*)/)
    let key = segmentMatch[1]

    if (typeof params[key] !== 'undefined') {
      result.push('/' + params[key])
      delete newParams[key]
    } else {
      result.push('/:' + key)
    }

    return result.push(segmentMatch[2] || '')
  })

  return [result.join(''), newParams]
}

export const makeUrl = (host, path, params) => {
  let url = host + path
  let queryString
  let delimiter = isQueryStringPresent(path) ? '&' : '?'

  switch (typeof params) {
  case 'string':
    queryString = params
    break
  case 'object':
    let [newUrl, newParams] = interpolateUrl(url, params)
    url = newUrl
    queryString = serialize(newParams)
    break
  default:
    queryString = ''
  }

  return url + (queryString === '' ? '' : delimiter + queryString)
}

export const redirect = path =>
  global.location = path

export const redirectStepBack = () =>
  global.location = global.location.href.split('/').slice(0, -1).join('/')
