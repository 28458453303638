/* eslint no-undefined: 0 */
/* eslint no-process-env: 0 */
/* eslint no-console: 0 */

import { lens } from 'lorgnette'

if (process.env.NODE_ENV) {
  global.tap = v => {
    console.info(v)
    return v
  }
}

export const createAction = (type, ...argNames) => (...args) => {
  let action = { type }
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index]
  })
  return action
}

export const actionThunk = action => payload => () => action(payload)

export const registerAction = (namespace, appName, actionName, action) => {
  namespace.Components = lens
    .prop('Actions', {})
    .prop(appName, {})
    .prop(actionName, {})
    .set(namespace.Components || {}, action)
}

export const getParameterByName = n => {
  let result = null

  const url = global.location.href
  const name = n.replace(/[\[\]]/g, '\\$&')

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)

  if (!results) {
    result = null
  } else if (!results[2]) {
    result = ''
  } else {
    result = decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  return result
}

export const getDocHeight = () => {
  const D = document
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight
  )
}

export const debounce = (func, wait, immediate) => {
  let timeout

  return (...args) => {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, args)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, args)
    }
  }
}

export const throttle = (callback, limit) => {
  let wait = false

  return (...args) => {
    if (!wait) {
      callback.apply(this, args)
      wait = true
      setTimeout(() => {
        wait = false
      }, limit)
    }
  }
}

export const listenScrollBottomDependingElement = (dependingElement, callback, wait = 700) => {

  listenScrollBottom(() => {
    if (dependingElement.clientHeight === 0) {
      return
    }

    callback()
  }, wait)
}

export const listenScrollBottom = (cb, wait = 700) => {
  const func = debounce(cb, wait)

  global.addEventListener('scroll', () => {
    const { innerHeight, pageYOffset } = global
    const docHeight = getDocHeight()

    if (innerHeight + pageYOffset >= docHeight - 50) {
      func()
    }
  })
}

export const listenScrollBottomEl = (el, cb, wait = 700) => {
  const func = debounce(cb, wait)

  let prevTop = 0

  el.addEventListener('scroll', () => {
    const { scrollTop, clientHeight, scrollHeight } = el

    if ((prevTop !== scrollTop) && (scrollTop + clientHeight >= scrollHeight - 50)) {
      prevTop = scrollTop
      func()
    }
  })
}

export const startsWith = (str, searchString, position) => {
  const pos = position || 0
  return str.lastIndexOf(searchString, pos) === pos
}

export const arrayIncludes = (a, obj, comparator = null) => {
  const comp = comparator || ((v1, v2) => v1 === v2)

  for (let i = a.length - 1; i > -1; i = i - 1) {
    if (comp(a[i], obj)) {
      return true
    }
  }

  return false
}

export const chunk = (a, n) => {
  if (n < 2) {
    return [a]
  }

  const len = a.length
  const out = []

  let i = 0
  let size

  if (len % n === 0) {
    size = Math.floor(len / n)
    while (i < len) {
      out.push(a.slice(i, (i = i + size)))
    }
  } else {
    while (i < len) {
      size = Math.ceil((len - i) / n--)
      out.push(a.slice(i, (i = i + size)))
    }
  }

  return out
}

export const isEquivalent = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i = i + 1) {
    let propName = aProps[i]

    if (a[propName] !== b[propName]) {
      return false
    }
  }

  return true
}

export const isString = v => typeof v === 'string' || v instanceof String

export const isArray = v => Object.prototype.toString.call(v) === '[object Array]'

export const isDefined = v => typeof v !== 'undefined'

export const isObject = v => v !== null && !isArray(v) && typeof v === 'object'

export const isBoolean = v => typeof v === 'boolean'

export const isEmptyObject = o => Object.keys(o).length === 0

export const isEmpty = v =>
  v === null ||
  v === undefined ||
  (v.hasOwnProperty('length') && v.length === 0) ||
  (v.constructor === Object && Object.keys(v).length === 0)

export const isUndefined = v => !isDefined(v)

export const filterProps = (obj, { blacklist }) => (
  Object.keys(obj)
    .filter(k => !blacklist.includes(k))
    .reduce((c, k) => {
      c[k] = obj[k]
      return c
    }, {})
)

export const isServer = () => typeof window === 'undefined' || !window.document

export const scrollTo = (elem, { top, left }) => {
  if (!isServer()) {
    if (isDefined(top)) {
      elem.scrollTop = top
    }

    if (isDefined(left)) {
      elem.scrollLeft = left
    }
  }
}

export const scrollTop = elem => {
  scrollTo(elem, { top: 0 })
}

const hasScrollbar = element =>
  element.scrollHeight > element.clientHeight

export const scrollToError = () => {
  const el = document.querySelector('.error-scroll') || document

  setTimeout(() => {

    const isWindow = !hasScrollbar(el)
    const firstInvalid = el.querySelector('.input-label_validation-error')
    if (firstInvalid) {
      const y = firstInvalid.offsetTop
      isWindow
      ? global.scrollTo(0, y)
      : scrollTo(el, { top: y - el.clientHeight / 2 })
    }

  }, 200)
}

export const normalizeLocation = (location, basename) => {
  const path = basename === '/' ? location : location.replace(basename, '')
  return startsWith(path, '/') ? path : '/' + path
}

export const isInt = n => Number(n) === n && n % 1 === 0

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n)

export const safeParseFloat = n => (isNumeric(n) ? parseFloat(n) : 0)

export const serialize = (obj, prefix) => {
  const str = []

  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      // Ignore integer keys, it means we were provided array as a value
      // and Rails takes arrays like foo: [bar, baz] as foo[]=bar&foo[]=baz
      let prop = isInt(parseInt(p, 10)) ? '' : p

      let k = prefix ? prefix + '[' + prop + ']' : p
      let v = obj[p]

      str.push(
        v !== null && typeof v === 'object' ? serialize(v, k) : k + '=' + encodeURIComponent(v)
      )
    }
  }

  return str.join('&')
}

export const deserialize = query => {
  const setValue = (root, path, value) => {
    if (path.length > 1) {
      const dir = path.shift()
      if (isUndefined(root[dir])) {
        root[dir] = path[0] === '' ? [] : {}
      }

      setValue(root[dir], path, value)
    } else if (root instanceof Array) {
      root.push(value)
    } else {
      root[path] = value
    }
  }

  const nvp = query.toString().replace(/^\?/, '').split('&')
  const data = {}

  for (let i = 0; i < nvp.length; i = i + 1) {
    const pair = nvp[i].split('=')
    const name = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1])

    let path = name.match(/(^[^\[]+)(\[.*\]$)?/)
    const first = path[1]

    if (path[2]) {
      // case of 'array[level1]' || 'array[level1][level2]'
      path = path[2].match(/(?=\[(.*)\]$)/)[1].split('][')
    } else {
      // case of 'name'
      path = []
    }

    path.unshift(first)

    setValue(data, path, value)
  }

  return data
}

export const replaceString = (str, re, fn) => {
  let curCharStart = 0
  let curCharLen = 0
  const result = str.split(re)

  // Apply fn to all odd elements
  for (let i = 1, length = result.length; i < length; i = i + 2) {
    curCharLen = result[i].length
    curCharStart = curCharStart + result[i - 1].length
    result[i] = fn(result[i], i, curCharStart)
    curCharStart = curCharStart + curCharLen
  }

  return result
}

export const fullName = ({ name, first_name = '', last_name = '', email, deleted }) => {
  if (deleted) {
    return 'Deleted User'
  }
  if (!!name) {
    return name
  }
  if (!!first_name || !!last_name) {
    return `${first_name} ${last_name}`
  }
  return email || ''
}

export const removeByIndex = (arr, index) => [...arr.slice(0, index), ...arr.slice(index + 1)]

export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

export const errorMapping = error => ({
  message: error.message,
  type: error.type,
  values: { path: error.path }
})

export const errorsMapping = errors =>
  Object.keys(errors).reduce((acc, key) => {
    const newError = [...errors[key]].map(v => ({ message: v }))
    acc[key] = newError
    return acc
  }, {})

export const validate = (schema, model) =>
  schema.validate(model, { strict: false, abortEarly: false }).catch(err => {
    const errors = err.inner.reduce((list, error) => {
      if (!list[error.path]) {
        list[error.path] = []
      }

      list[error.path] = list[error.path].concat(errorMapping(error))

      return list
    }, {})

    throw errors
  })

export const later = f => setTimeout(f, 0)

export const pick = (keys, obj) => keys.reduce((a, e) => ({ ...a, [e]: obj[e] }), {})

export const flatten = arr =>
  arr.reduce(
    (flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  )

export const memoize = func => {
  const memo = {}

  return (...args) => {
    if (args in memo) {
      return memo[args]
    }

    return (memo[args] = func.apply(this, args))
  }
}

export const humanize = str =>
  str
    .replace(/_/g, ' ')
    .trim()
    .replace(/\b[A-Z][a-z]+\b/g, word => word.toLowerCase())
    .replace(/^[a-z]/g, first => first.toUpperCase())

const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig

export const markup = (str = '') => `${str}`
  .replace(URL_REGEX, `<a href='$1'>$1</a>`)
  .replace(/(^|\s|[+_*])-(.+?)-/g, '$1<del>$2</del>')
  .replace(/(^|\s|[+_>])\*(.+?)\*/g, '$1<strong>$2</strong>')
  .replace(/(^|\s|[+>])_(.+?)_/g, '$1<i>$2</i>')
  .replace(/(^|\s|>)\+(.+?)\+/g, '$1<u>$2</u>')
  .replace(/\r?\n/g, '<br>')

export const getSearchString = () => global.location.search

export const transactionTypeToDemands = name =>
  ({
    TimeOff: 'time_off',
    Purchase: 'purchases',
    Expense: 'expenses',
    Activity: 'activities'
  }[name])

export const last = arr => arr[arr.length - 1]

export const invertObjectKeys = obj =>
  Object.keys(obj).reduce((acc, k) => {
    acc[obj[k]] = k
    return acc
  }, {})

export const unitsEnabled = (units, features) =>
  Object.keys(features).some(feature => arrayIncludes(units, feature))

export const capitalize = string => string && string.charAt(0).toUpperCase() + string.slice(1)

export const isCanvasBlank = canvas => {
  const blank = document.createElement('canvas')

  blank.width = canvas.width
  blank.height = canvas.height

  return canvas.toDataURL() === blank.toDataURL()
}

export const copyToClipboard = (from, onSuccess, onError, onComplete) => {
  from.select()

  try {
    const successful = global.document.execCommand('copy')
    if (successful) {
      onSuccess()
    } else {
      onError()
    }
  }
  catch (e) {
    onError()
  }
  finally {
    onComplete()
  }
}

export const joinNotEmpty = (...array) => array.filter(v => !!v).join(', ')

export const getRelativeMousePosition = e => {
  const rect = e.target.getBoundingClientRect()
  const x = e.pageX - rect.left
  const y = e.pageY - rect.top

  return [x, y]
}

export const deepEqual = (o1, o2) => JSON.stringify(o1) === JSON.stringify(o2)

export const isPrimitive = v => Object(v) !== v

export const allValuesOf = (obj = {}) =>
  Object.keys(obj)
    .reduce((p, c) =>
        isPrimitive(obj[c])
        ? [...p, obj[c]]
        : [...p, ...allValuesOf(obj[c])],
      [])

export const makeUID = () => `UID_${global.performance.now()}`.replace('.', '')

export const isUID = id => `${id}`.startsWith('UID_')

export const flattenObject = (obj = {}, flatten = {}) => {
  Object.keys(obj).forEach(k => {
    if (isObject(obj[k])) {
      flattenObject(obj[k], flatten)
    } else {
      flatten[k] = obj[k]
    }
  })
  return flatten
}

export const getParams = () =>
  global && global.location && global.location.search
  ? deserialize(global.location.search)
  : {}


export const setParams = obj => {
  const params = { ...getParams(), ...obj }
  const newParams = []

  Object.keys(params).forEach(k => {
    if (params[k] !== undefined) {
      newParams.push(`${k}=${encodeURIComponent(params[k])}`)
    }
  })
  const search = '?' + newParams.join('&')
  history.pushState({}, null, global.location.pathname + search)
}
