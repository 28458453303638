import lens from 'lorgnette'
import {
  UPDATE_TEAM,
  UPDATE_TEAM_FIELDS,
  UPDATE_TEAM_ERRORS,
  RESET_TEAM_ERRORS,
  UPDATE_STAFF,
  RESET_TEAM
} from '../actions/team'

const initialState = {
  team_demand_type: [],
  members: [],
  managers: [],
  viewers: [],
  errors: {},
  staff: {
    members: [],
    managers: [],
    viewers: []
  }
}

const staffLens = staffType => lens.prop('staff').prop(staffType)

export default function team(state = initialState, action) {
  switch (action.type) {
  case UPDATE_TEAM:
  case UPDATE_TEAM_FIELDS:
    return { ...state, ...action.data }
  case UPDATE_TEAM_ERRORS:
    return { ...state, errors: action.errors }
  case RESET_TEAM_ERRORS:
    return { ...state, errors: {} }
  case UPDATE_STAFF:
    return staffLens(action.staffType).set(state, action.data)
  case RESET_TEAM:
    return initialState
  default:
    return state
  }
}
