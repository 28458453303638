import { createAction } from '../lib/utils'

export const ADD_BLACKOUT_CONFIG_REQUEST = 'ADD_BLACKOUT_CONFIG_REQUEST'
export const ADD_BLACKOUT_CONFIG_SUCCESS = 'ADD_BLACKOUT_CONFIG_SUCCESS'
export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CONFIG_REQUEST = 'ADD_CONFIG_REQUEST'
export const ADD_CONFIG_SUCCESS = 'ADD_CONFIG_SUCCESS'
export const DELETE_BLACKOUT_CONFIG_REQUEST = 'DELETE_BLACKOUT_CONFIG_REQUEST'
export const DELETE_BLACKOUT_CONFIG_SUCCESS = 'DELETE_BLACKOUT_CONFIG_SUCCESS'
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CONFIG_REQUEST = 'DELETE_CONFIG_REQUEST'
export const DELETE_CONFIG_SUCCESS = 'DELETE_CONFIG_SUCCESS'
export const GET_TIMEOFFS_SETTINGS_REQUEST = 'GET_TIMEOFFS_SETTINGS_REQUEST'
export const GET_TIMEOFFS_SETTINGS_SUCCESS = 'GET_TIMEOFFS_SETTINGS_SUCCESS'
export const UPDATE_BLACKOUT_CONFIG_REQUEST = 'UPDATE_BLACKOUT_CONFIG_REQUEST'
export const UPDATE_BLACKOUT_CONFIG_SUCCESS = 'UPDATE_BLACKOUT_CONFIG_SUCCESS'
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CONFIG_REQUEST = 'UPDATE_CONFIG_REQUEST'
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS'

export const addBlackoutConfigRequest = createAction(ADD_BLACKOUT_CONFIG_REQUEST, 'blackoutConfig', 'onSuccess')
export const addBlackoutConfigSuccess = createAction(ADD_BLACKOUT_CONFIG_SUCCESS, 'item')
export const addCategoryRequest = createAction(ADD_CATEGORY_REQUEST, 'category')
export const addCategorySuccess = createAction(ADD_CATEGORY_SUCCESS, 'item')
export const addConfigRequest = createAction(ADD_CONFIG_REQUEST, 'config', 'onSuccess')
export const addConfigSuccess = createAction(ADD_CONFIG_SUCCESS, 'item')
export const deleteBlackoutConfigRequest = createAction(DELETE_BLACKOUT_CONFIG_REQUEST, 'blackoutConfig')
export const deleteBlackoutConfigSuccess = createAction(DELETE_BLACKOUT_CONFIG_SUCCESS, 'item')
export const deleteCategoryRequest = createAction(DELETE_CATEGORY_REQUEST, 'category')
export const deleteCategorySuccess = createAction(DELETE_CATEGORY_SUCCESS, 'item')
export const deleteConfigRequest = createAction(DELETE_CONFIG_REQUEST, 'config')
export const deleteConfigSuccess = createAction(DELETE_CONFIG_SUCCESS, 'item')
export const getTimeoffsSettingsRequest = createAction(GET_TIMEOFFS_SETTINGS_REQUEST)
export const getTimeoffsSettingsSuccess = createAction(GET_TIMEOFFS_SETTINGS_SUCCESS, 'data')
export const updateBlackoutConfigRequest = createAction(UPDATE_BLACKOUT_CONFIG_REQUEST, 'blackoutConfig', 'onSuccess')
export const updateBlackoutConfigSuccess = createAction(UPDATE_BLACKOUT_CONFIG_SUCCESS, 'item')
export const updateCategoryRequest = createAction(UPDATE_CATEGORY_REQUEST, 'category')
export const updateConfigRequest = createAction(UPDATE_CONFIG_REQUEST, 'config', 'onSuccess')
export const updateCategorySuccess = createAction(UPDATE_CATEGORY_SUCCESS, 'item')
export const updateConfigSuccess = createAction(UPDATE_CONFIG_SUCCESS, 'item')
