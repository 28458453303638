import { takeEvery } from 'redux-saga'
import { put, select, call, fork } from 'redux-saga/effects'
import routes from '../api/routes'
import { makeUrl, get, del, put as apiPut, post } from '../api/utils'
import { allErrors, getHost, proceed, showFlash } from './utils'
import {
  ADD_CUSTOM_FIELD_REQUEST,
  GET_CUSTOM_FIELDS_REQUEST,
  DELETE_CUSTOM_FIELD_REQUEST,
  UPDATE_CUSTOM_FIELD_REQUEST,
  addCustomFieldSuccess,
  getCustomFieldsSuccess,
  deleteCustomFieldSuccess,
  updateCustomFieldSuccess
} from '../actions/customFields'
import { customFieldMapping } from '../api/mappings'

export function* getFields({ demand_kind }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.settingsCustomFields, { demand_kind: demand_kind })
  const { collection } = yield call(get, url)

  if (collection) {
    yield put(getCustomFieldsSuccess(collection))
  }
}

export function* updateField({ field }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.settingsCustomField, { id: field.id })
  const data = customFieldMapping(field)
  const { errors, item } = yield call(apiPut, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Field updated')
    yield put(updateCustomFieldSuccess(item))
  }
}

export function* addField({ field }) {
  const host = yield select(getHost)
  const url = yield call(makeUrl, host, routes.settingsCustomFields)
  const data = customFieldMapping(field)
  const { item, errors } = yield call(post, url, data)
  if (errors) {
    yield fork(showFlash, 'error', allErrors(errors))
  } else {
    yield fork(showFlash, 'success', 'Field added')
    yield put(addCustomFieldSuccess(item))
  }
}

export function* deleteField({ field }) {
  const deleteConfirmed = yield call(proceed)
  if (deleteConfirmed) {
    const host = yield select(getHost)
    const url = yield call(makeUrl, host, routes.settingsCustomField, { id: field.id })
    yield call(del, url)
    yield put(deleteCustomFieldSuccess(field))
    yield fork(showFlash, 'success', 'Field deleted')
  }
}

export function* watchCustomFieldsUpdates() {
  yield [
    takeEvery(GET_CUSTOM_FIELDS_REQUEST, getFields),
    takeEvery(ADD_CUSTOM_FIELD_REQUEST, addField),
    takeEvery(DELETE_CUSTOM_FIELD_REQUEST, deleteField),
    takeEvery(UPDATE_CUSTOM_FIELD_REQUEST, updateField)
  ]
}
